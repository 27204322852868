import moment from "moment";

const ISO_8601_DATE_TIME_FORMAT = 'YYYY-MM-DD[T]HH:mm:ssZ';
const DATETIME_DISPLAY_FORMAT = "Do Mo YYYY, H:mm:ss";
const DATE_DISPLAY_FORMAT = "dddd, Do Mo YYYY";

/**
 * @param date {string} YYYY-MM-DD
 * @param time {string} HH:mm:ss
 * @returns {string|null} ISO-8601 date format or null
 */
function isoFromDateTime(date, time) {
    if (date !== null && date !== undefined && time !== null && time !== undefined) {
        return moment(date + ' ' + time, moment.HTML5_FMT.DATE + ' ' + moment.HTML5_FMT.TIME_SECONDS).format(ISO_8601_DATE_TIME_FORMAT);
    } else if (date !== null && date !== undefined) {
        return moment(date, moment.HTML5_FMT.DATE).format(ISO_8601_DATE_TIME_FORMAT);
    } else {
        return null;
    }
}

export {isoFromDateTime, ISO_8601_DATE_TIME_FORMAT, DATETIME_DISPLAY_FORMAT, DATE_DISPLAY_FORMAT};
