const barcodeReaderQueueModule = {
    namespaced: true,
    persistent: false,
    state: {
        queue: []
    },
    actions: {
        push({commit, getters}, code) {
            if (!getters.empty && code === getters.last.code) {
                commit('increaseLast');
            } else {
                commit('push', code);
            }
        },
        pop({commit}) {
            commit('pop');
        },
        clear({commit}) {
            commit('clear');
        }
    },
    mutations: {
        push(state, code) {
            state.queue.push({
                code,
                quantity: 1
            });
        },
        pop(state) {
            state.queue.shift();
        },
        clear(state) {
            state.queue = [];
        },
        increaseLast(state) {
            state.queue[state.queue.length - 1].quantity++;
        }
    },
    getters: {
        front: state => state.queue[0],
        last: state => state.queue[state.queue.length - 1],
        list: state => state.queue,
        empty: state => state.queue.length === 0
    }
};

export default barcodeReaderQueueModule;
