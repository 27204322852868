<template>
  <div>
    <v-btn
      :loading="loading"
      type="submit"
      color="accent"
      class="ma-2"
      @click="$emit('assign')"
    >
      <v-icon
        class="mr-2"
      >
        $assignTask
      </v-icon>
      {{ $t('tasks.assign.self') }}
    </v-btn>
    <div class="caption text--disabled ml-3 mb-2">
      {{ $t('tasks.assign.useReader') }}
    </div>
  </div>
</template>

<script>
    export default {
        name: "TaskAssignButton",
        props: {
            loading: {
                type: Boolean,
                default: false
            }
        }
    };
</script>

<style scoped>

</style>
