<template>
  <v-card
    class="ma-1"
  >
    <v-container>
      <v-icon class="mb-1">
        {{ actionIcons.approve }}
      </v-icon>
      <span class="text-subtitle-1 ml-2">
        {{ $t('tasks.approve.name') }}
      </span>
      <v-divider
        class="my-2"
      />
      <v-layout wrap>
        <v-flex
          xs12
        >
          <v-subheader>
            {{ $t('tasks.externalOrder.createStockPicking') }}
          </v-subheader>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent
          >
            <FormFields
              :form="formPicking"
              :render="formPickingRender"
              lang-path="tasks.stockPicking."
              :md-sizing="4"
            />
            <v-divider class="my-3" />
            <FormFields
              :form="form"
              :render="formRender"
              lang-path="tasks."
              :md-sizing="4"
            />
          </v-form>
        </v-flex>
      </v-layout>
      <div>
        <v-btn
          :text="!valid"
          :loading="loading"
          type="submit"
          color="accent"
          @click="approve()"
        >
          <v-icon
            class="mr-2"
          >
            $approve
          </v-icon>
          {{ $t('tasks.approve.name') }}
        </v-btn>
      </div>
    </v-container>
  </v-card>
</template>

<script>
    import {BuyerAPI} from "@/api/BuyerAPI";
    import FormFields from "@/app/components/form/FormFields.component";
    import {
        ExternalOrderApproveForm, ExternalOrderApprovePickingForm,
        ExternalOrderApprovePickingRender, ExternalOrderApproveRender
    } from "@/app/tasks/externalOrder/definitions/externalOrderApprove.form";
    import {actionIcons} from "@/enum/icons";
    import {clearFormErrors, setFormErrors} from "@/utils/form";
    import {TaskExternalOrderAPI} from "@/api/TaskExternalOrderAPI";
    import {taskNames, taskTypes} from "@/enum/task_type";
    import {populate} from "@/utils/object";
    import {TaskShippingType} from "@/enum/task_shipping_type";
    import {StockAPI} from "@/api/StockAPI";
    import {locationLabel} from "@/utils/string";

    export default {
        name: "ExternalOrderApproveCard",
        components: {FormFields},
        props: {
            taskId: {
                type: Number,
                default: undefined
            },
            items: {
                type: Array,
                default: () => ([])
            },
            defaultDataPicking: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            form: new ExternalOrderApproveForm,
            formPicking: new ExternalOrderApprovePickingForm,
            formRender: new ExternalOrderApproveRender,
            formPickingRender: new ExternalOrderApprovePickingRender,
            valid: true,
            loading: false,
            actionIcons: actionIcons,
        }),
        watch: {
            'formPicking.stock_id': function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    this.loadSubStocks();
                }
            },
            'formPicking.buyer_id': function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    this.loadBuyerDeliveryAddresses();
                }
            },
            'formPicking.subordinate_stock_id': function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    this.loadLocations();
                }
            },
            'formPicking.shipping_type': function (newValue) {
                if (newValue === TaskShippingType.COURIER) {
                    this.formPickingRender.destination_stock_location_id.readonly = false;
                } else {
                    this.formPicking.destination_stock_location_id = null;
                    this.formPickingRender.destination_stock_location_id.readonly = true;
                    // TODO trigger required
                    delete this.formPickingRender.destination_stock_location_id.errors;
                }
            }
        },
        created: function () {
            populate(this.formPicking, this.defaultDataPicking);
        },
        mounted: function () {
            clearFormErrors(this);
        },
        methods: {
            loadSubStocks: function () {
                this.$set(this.formPickingRender.subordinate_stock_id, 'loading', true);
                StockAPI.getAllSubstockPages(this.formPicking.stock_id)
                    .then(response => {
                        this.formPickingRender.subordinate_stock_id.autocomplete.items = response.data.items.map(el => ({
                            text: el.name,
                            value: el.id
                        }));
                    }).finally(() => {
                        this.$set(this.formPickingRender.subordinate_stock_id, 'loading', false);
                    });
            },
            loadLocations: function () {
                this.$set(this.formPickingRender.destination_stock_location_id, 'loading', true);
                StockAPI.getAllSubstockAvailableLocationsAllPages(this.formPicking.stock_id, this.formPicking.subordinate_stock_id)
                    .then(response => {
                        this.formPickingRender.destination_stock_location_id.autocomplete.items = response.data.items.map(el => ({
                            text: locationLabel(el),
                            value: el.id
                        }));
                    }).finally(() => {
                        this.$set(this.formPickingRender.destination_stock_location_id, 'loading', false);
                    });
            },
            loadBuyerDeliveryAddresses: function () {
                this.$set(this.formPickingRender.buyer_delivery_address_id, 'loading', true);
                BuyerAPI.getAllAddresses(this.formPicking.buyer_id)
                    .then(response => {
                        this.formPickingRender.buyer_delivery_address_id.autocomplete.items = response.data.map(({address}) => ({
                            text: this.$options.filters.addressInline(address),
                            value: address.address_id
                        }));
                    }).finally(() => {
                        this.$set(this.formPickingRender.buyer_delivery_address_id, 'loading', false);
                    });
            },
            approve: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                this.form.hours = this.$store.getters['time/currentHours'](Date.now());
                this.form.stock_picking = this.formPicking;
                this.form.stock_picking.items = this.items.map(item => {
                    return {
                        product_instance_id: item.selectedInstance,
                        quantity: item.quantity,
                        sell_price_per_unit: item.sell_price_per_unit,
                        price_vat: item.price_vat
                    };
                });
                TaskExternalOrderAPI.approve(this.taskId, this.form)
                    .then(() => {
                        this.$store.dispatch('time/stop');
                        this.advancedSnack({
                            text: 'tasks.approve.done',
                            params: [
                                this.taskId,
                                this.$t(taskNames[taskTypes.EXTERNAL_ORDER])
                            ]
                        });
                        this.$router.push('/');
                    }).catch(errors => {
                        setFormErrors.call(this, errors, ['formRender', 'formPickingRender']);
                    }).finally(() => {
                        this.loading = false;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
