<template>
  <div
    class="ma-1 pb-1"
  >
    <BarcodePrintDialog
      :show.sync="print"
      :print-type.sync="printType"
      :print-just-one-per-item.sync="printJustOnePerItem"
      :title="$t('products.instances.barcodes.batchPrint.label')"
      :confirm-text="$t('stocks.locations.printBarcodeMany')"
      batch-print
      @print-barcode="batchPrint"
    />
    <v-card-title
      v-show="!isSingle"
      class="subtitle-1 pr-9 py-0 normalBreak"
    >
      <div class="d-flex flex-column">
        <span>
          {{ $t('tasks.' + taskLangPath + cardType) + ' ' + headerDetailText }}
          <v-tooltip
            v-if="isAllowedToChangeTarget"
            bottom
          >
            <template #activator="{ on }">
              <v-btn
                icon
                small
                v-on="on"
                @click="changeTargetDialog = true"
              >
                <v-icon>
                  $itemChangeTargetLocation
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('tasks.itemsCard.changeTargetLocation') }}</span>
          </v-tooltip>
        </span>
        <v-btn
          v-if="allowBatchPrint && items.length !== 0"
          :loading="printing"
          color="secondary"
          class="mb-2"
          @click="print = true"
        >
          <v-icon
            small
            class="mr-2"
          >
            $printItem
          </v-icon>
          {{ $t('products.instances.barcodes.printMany') }}
        </v-btn>
      </div>
      <v-bottom-sheet
        v-if="allowLocationBottomSheet"
        v-model="showSubstockLocationInfo"
      >
        <template #activator="{ on }">
          <v-icon
            class="ml-1"
            v-on="on"
          >
            $hint
          </v-icon>
        </template>
        <v-sheet>
          <v-layout
            align-center
            column
            wrap
            class="py-3"
          >
            <v-flex
              class="text-caption"
            >
              {{ $t('tasks.itemsCard.substockLocations') + ':' }}
            </v-flex>
            <v-flex
              class="mx-2"
            >
              <v-chip
                v-for="(location, index) of locationBottomSheetLocations"
                :key="index"
                label
                outlined
              >
                {{ location | locationLabel }}
              </v-chip>
            </v-flex>
          </v-layout>
        </v-sheet>
      </v-bottom-sheet>
    </v-card-title>
    <template
      v-if="isMultipleLocations"
    >
      <transition-group
        name="card-list"
        mode="out-in"
      >
        <v-card
          v-show="showDummyCard"
          key="dummy"
          class="card-list-item ma-1 mb-3 pa-2 elevation-8 overflow-hidden"
          :color="cardType === TaskItemsCardType.MOVED ? activeCardColor : ''"
        >
          <div class="overline pa-2">
            <TaskItemsCardHeader
              :location-id="activeLocationId"
              :allowed-instances="allowedInstances[activeLocationId]"
              :allowed-locations-loaded="allowedLocationsLoaded"
              :card-type="cardType"
              :num-of-instances="numOfItemsInTask"
            />
          </div>
        </v-card>
        <v-lazy
          v-for="locationId of multipleLocations"
          :key="locationId"
          transition="slide-y-reverse-transition"
          min-height="72px"
        >
          <v-card
            :key="locationId"
            class="card-list-item overflow-hidden"
            :class="{
              'ma-3 pa-3 elevation-1 text--disabled': !highlightActiveCard(locationId),
              'ma-1 mb-3 pa-2 elevation-8': highlightActiveCard(locationId)
            }"
            :color="highlightActiveCard(locationId) ? activeCardColor : ''"
          >
            <div class="overline pa-2">
              <TaskItemsCardHeader
                :location-id="locationId"
                :allowed-instances="allowedInstances[locationId]"
                :allowed-locations-loaded="allowedLocationsLoaded"
                :card-type="cardType"
                :num-of-instances="numOfItemsInTask"
              />
            </div>
            <div
              class="d-flex"
              :class="{ 'flex-column': $vuetify.breakpoint.mdAndDown }"
            >
              <div
                v-for="(itemGroup, index) of itemGroups"
                :key="'ig' + index"
                :style="$vuetify.breakpoint.mdAndUp ? 'width: ' + (100 / itemGroups.length) + '%' : ''"
                :class="{ 'mb-2': $vuetify.breakpoint.mdAndDown }"
              >
                <transition-group
                  name="chip-list"
                  mode="out-in"
                >
                  <v-lazy
                    v-for="(item, idx) of items.filter(itm => itemGroup.filter(itm) && itemQuantityOnLocation(itm, locationId))"
                    :key="item.id"
                    transition="slide-y-reverse-transition"
                    min-height="32px"
                    class="chip-list-item d-block"
                  >
                    <div>
                      <div
                        v-if="itemGroup.label && idx === 0"
                        class="text-caption"
                      >
                        {{ itemGroup.label }}:
                      </div>
                      <TaskItemsCardItem
                        :item="item"
                        :active-location-id="activeLocationId"
                        :active-location-side="activeLocationSide"
                        :card-type="cardType"
                        :quantity-current="itemQuantityOnLocation"
                        :prices="prices"
                        :location-id="locationId"
                        :task-info="taskInfo"
                        :inventory-empty="inventoryEmpty"
                        @back="onClickBack"
                        @remove="onClickRemove"
                      />
                    </div>
                  </v-lazy>
                </transition-group>
              </div>
            </div>
          </v-card>
        </v-lazy>
        <v-card
          v-show="showNothingCard"
          key="empty"
          outlined
          class="card-list-item mx-1 pa-2 pt-3 text--disabled overflow-hidden"
        >
          <v-icon class="mb-1 mx-2">
            $nothing
          </v-icon>
          {{ $t('base.nothing') }}
        </v-card>
      </transition-group>
    </template>
    <template
      v-else
    >
      <v-card
        class="mx-1 pa-2 pt-3"
        :class="{
          'ma-1 mb-3 pa-2 elevation-8': highlightSingleLocation
        }"
        :color="highlightSingleLocation ? activeCardColor : ''"
        :outlined="items.length === 0"
      >
        <div
          v-if="items.length > 0"
          class="d-flex"
          :class="{ 'flex-column': $vuetify.breakpoint.mdAndDown }"
        >
          <div
            v-for="(itemGroup, index) of itemGroups"
            :key="'ig' + index"
            :style="$vuetify.breakpoint.mdAndUp ? 'width: ' + (100 / itemGroups.length) + '%' : ''"
            :class="{ 'mb-2': $vuetify.breakpoint.mdAndDown }"
          >
            <transition-group
              name="chip-list"
              mode="out-in"
            >
              <v-lazy
                v-for="(item, idx) of items.filter(itm => itemGroup.filter(itm))"
                :key="item.id"
                transition="slide-y-reverse-transition"
                min-height="32px"
                class="chip-list-item d-block"
              >
                <div>
                  <div
                    v-if="itemGroup.label && idx === 0"
                    class="text-caption"
                  >
                    {{ itemGroup.label }}:
                  </div>
                  <TaskItemsCardItem
                    :item="item"
                    :active-location-id="activeLocationId"
                    :active-location-side="activeLocationSide"
                    :card-type="cardType"
                    :quantity-current="quantityCurrent"
                    :prices="prices"
                    :location-id="cardType === TaskItemsCardType.MOVED
                      ? (destinationLocation ? destinationLocation.id : taskInfo.details.target_location_id)
                      : null"
                    :task-info="taskInfo"
                    :inventory-empty="inventoryEmpty"
                    @back="onClickBack"
                    @remove="onClickRemove"
                  />
                </div>
              </v-lazy>
            </transition-group>
          </div>
        </div>
        <span
          v-else
          class="text--disabled"
        >
          <v-icon class="mb-1 mx-2">
            $nothing
          </v-icon>
          {{ $t('base.nothing') }}
        </span>
      </v-card>
    </template>
    <v-btn
      v-if="showMoveAllButton"
      color="accent darken-2"
      class="ml-3 my-2 moveAllButton"
      outlined
      @click="$emit('moveAll')"
    >
      {{ $t('tasks.itemsCard.moveEverything') }}{{ LocationCache[activeLocationId] | locationLabel }}
    </v-btn>
    <v-dialog
      v-model="changeTargetDialog"
      width="450"
    >
      <v-card>
        <v-card-title>
          {{ $t('tasks.itemsCard.changeTargetLocation') }}
        </v-card-title>
        <v-card-text>
          <StockPickingTargetPicker
            v-if="canActuallyChangeTarget"
            :api="api"
            :task-info="taskInfo"
          />
          <v-alert
            v-else
            type="warning"
          >
            {{ $t('tasks.itemsCard.cannotChangeTargetLocation') }}
          </v-alert>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
    import {ReactiveLocationCacheMixin} from "@/app/mixins/ReactiveLocationCacheMixin";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import TaskItemsCardHeader from "@/app/tasks/components/taskItemsCard/TaskItemsCardHeader";
    import StockPickingTargetPicker from "@/app/tasks/stockPicking/components/StockPickingTargetPicker.component";
    import {TaskMoveProductsType} from "@/enum/task_move_products_type";
    import {EventBus} from "@/service/EventBus";
    import {StockAPI} from "@/api/StockAPI";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";
    import {activeCardColor} from "@/styles/theme";
    import {locationLabel} from "@/utils/string";
    import {has} from "@/utils/object";
    import TaskItemsCardItem from "@/app/tasks/components/taskItemsCard/TaskItemsCardItem.component";
    import {taskTypes} from "@/enum/task_type";
    import {TaskShippingType} from "@/enum/task_shipping_type";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import {TaskTypeMixin} from "@/app/mixins/TaskTypeMixin";
    import {ProductAPI} from "@/api/ProductAPI";
    import * as Export from "@/service/Export";
    import BarcodePrintDialog from "@/app/components/BarcodePrintDialog.component";
    import {PrintType} from "@/enum/print_type";
    import {TaskItemsStrictMode} from "@/enum/task_items_strict_mode";

    export default {
        name: "TaskItemsCard",
        components: {TaskItemsCardHeader, StockPickingTargetPicker, TaskItemsCardItem, BarcodePrintDialog},
        mixins: [EventsListenerMixin, TaskTypeMixin, TaskStateMixin, ReactiveLocationCacheMixin],
        props: {
            api: {
                type: Object,
                default: () => ({})
            },
            activeLocationId: {
                type: Number,
                default: -1
            },
            activeLocationSide: {
                type: String,
                default: null
            },
            cardType: {
                type: Number,
                default: 0
            },
            isSingle: {
                type: Boolean,
                default: false
            },
            items: {
                type: Array,
                default: () => []
            },
            numOfItemsInTask: {
                type: Number,
                default: null
            },
            itemsUpdateEmit: {
                type: String,
                default: ''
            },
            prices: {
                type: Object,
                default: () => ({})
            },
            taskInfo: {
                type: Object,
                default: () => ({})
            },
            taskLangPath: {
                type: String,
                default: ''
            },
            isDestinationScanned: {
                type: Boolean,
                default: false
            },
            highlightSingleLocation: {
                type: Boolean,
                default: false
            },
            inventoryEmpty: {
                type: Boolean,
                default: true
            },
            allowedInstances: {
                type: Object,
                default: () => ({})
            },
            allowedLocationsLoaded: {
                type: Boolean,
                default: false
            },
            allowBatchPrint: {
                type: Boolean,
                default: false
            },
            itemGroups: {
                type: Array,
                default: () => ([
                    {
                        filter: () => true,
                        label: null
                    }
                ])
            }
        },
        data: () => ({
            triggerLocations: 0,
            showSubstockLocationInfo: false,
            sourceLocations: [],
            TaskItemsCardType: TaskItemsCardType,
            changeTargetDialog: false,
            locationsCached: false,
            activeCardColor: activeCardColor,
            barcodesLoaded: false,
            print: false,
            printType: PrintType.ZPLX,
            printJustOnePerItem: true,
            printing: false
        }),
        computed: {
            events: function () {
                return {
                    'taskItems-refreshQuantities': this.fetchLocations,
                    'taskItems-quantitiesLoaded': this.setSourceLocations,
                    'task-targetLocation-picked': () => this.changeTargetDialog = false,
                    'check-items-in-inventory': this.checkItemsInInventory
                };
            },
            stockId: function () {
                return (has(this.taskInfo.details, 'subordinate_stock') && this.taskInfo.details.subordinate_stock.stock_id)
                    || (has(this.taskInfo.details, 'source_subordinate_stock') && this.taskInfo.details.source_subordinate_stock.stock_id)
                    || this.taskInfo.details.stock.id;
            },
            movementType: function () {
                return this.taskInfo.movementType;
            },
            headerDetailText: function () {
                const pieces = this.items.reduce((acc, curr) => acc + this.quantityCurrent(curr), 0);
                switch (this.cardType) {
                case TaskItemsCardType.TO_MOVE:
                    if (this.movementType === TaskMoveProductsType.COLLAPSE
                        || this.movementType === TaskMoveProductsType.MANYTOMANY
                        || this.isType(taskTypes.STOCK_LOADING)) {
                        return '(' + pieces + ')'; // write nothing else
                    } else {
                        return '(' + pieces + ' ' + this.$t('tasks.itemsCard.from') + ' ' + locationLabel(this.taskInfo.details.source_location) + ')';
                    }
                case TaskItemsCardType.IN_INVENTORY:
                    if (this.isAnyOfTypes([taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET]) && this.taskInfo.details.shipping_type === TaskShippingType.PERSONAL_COLLECTION) {
                        return this.headerDetailTextMoved(pieces);
                    } else {
                        return '(' + pieces + ')';
                    }
                case TaskItemsCardType.MOVED:
                    return this.headerDetailTextMoved(pieces);
                case TaskItemsCardType.PRESENT:
                    return '';
                case TaskItemsCardType.ASSIGNMENT:
                    return '(' + pieces + ')';
                default:
                    return '';
                }
            },
            isAllowedToChangeTarget: function () {
                return this.isAnyOfTypes([taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET])
                    && this.cardType === TaskItemsCardType.MOVED
                    && (this.isCreated || this.isInProgress);
            },
            canActuallyChangeTarget: function () {
                return this.isAllowedToChangeTarget
                    && (this.items.length === 0);
            },
            isMultipleLocations: function () {
                switch (this.cardType) {
                case TaskItemsCardType.MOVED:
                    return [TaskMoveProductsType.DISTRIBUTE, TaskMoveProductsType.MANYTOMANY].includes(this.movementType);
                case TaskItemsCardType.PRESENT:
                    return true;
                case TaskItemsCardType.TO_MOVE:
                    if (this.isType(taskTypes.STOCK_LOADING)) {
                        return false;
                    } else {
                        return [TaskMoveProductsType.COLLAPSE, TaskMoveProductsType.MANYTOMANY].includes(this.movementType);
                    }
                default:
                    return false;
                }
            },
            multipleLocations: function () {
                this.triggerLocations;
                let locations;
                if (this.locationsCached) {
                    const pickFromMobileLocation = this.$store.getters['userConfig/pickFromMobileLocation'];
                    locations = this.sourceLocations.filter(locId => this.LocationCache[locId] ?
                        (!this.LocationCache[locId].is_expedition && (
                            pickFromMobileLocation ? true : !this.LocationCache[locId].is_mobile)
                        )
                        : false);
                } else {
                    locations = this.sourceLocations;
                }
                if (this.sourceLocations.includes(this.activeLocationId) && !locations.includes(this.activeLocationId)) {
                    locations.push(this.activeLocationId);
                }
                if (this.cardType === TaskItemsCardType.PRESENT) {
                    if (this.taskInfo.details.stock_locations !== null) {
                        locations = this.taskInfo.details.stock_locations.map(loc => loc.id);
                    } else {
                        locations = [...new Set(this.items.map(item => item.stock_location_id))];
                    }
                }
                if (this.cardType === TaskItemsCardType.MOVED) {
                    locations = [...new Set(this.items.flatMap(item =>
                        item.destination_locations !== undefined
                            ? item.destination_locations.map(loc => this.isType(taskTypes.STOCK_LOADING) ? loc.location_id : loc.stock_location_id)
                            : [item.location_id || item.stock_location_id || item.destination_location.stock_location_id]
                    ))]
                        .filter(id => !this.otherSideLocationIds.includes(id))
                        .sort((a, b) => a - b);
                }
                const position = locations.indexOf(this.activeLocationId);
                if (position > 0) {
                    locations.splice(position, 1);
                    locations.unshift(this.activeLocationId);
                }
                return locations;
            },
            otherSideLocationIds: function () {
                if (this.cardType === TaskItemsCardType.TO_MOVE) {
                    if (this.isDestinationScanned) {
                        return [this.activeLocationId];
                    }
                    const id = this.taskInfo.details.target_location_id
                        || (this.taskInfo.details.destination_location && this.taskInfo.details.destination_location.id)
                        || false;
                    return id ? [id] : [];
                }
                if (this.cardType === TaskItemsCardType.MOVED) {
                    if (!this.isDestinationScanned
                        && !(this.isType(taskTypes.MOVE_PRODUCTS) && this.taskInfo.details.transfer_mode === TaskItemsStrictMode.FREE)) {
                        return [this.activeLocationId];
                    }
                    const sourceLocation = this.taskInfo.details.source_location;
                    return (sourceLocation === undefined || sourceLocation === null) ? [] : [this.taskInfo.details.source_location.id];
                }
                return [];
            },
            destinationLocation: function () { // may be undefined
                return this.taskInfo.details.destination_location || this.taskInfo.details.target_location;
            },
            activeCardIsOnThisSide: function () {
                if (this.activeLocationSide !== null) {
                    return ((this.cardType === TaskItemsCardType.TO_MOVE && this.activeLocationSide === 'pick')
                        || (this.cardType === TaskItemsCardType.MOVED && this.activeLocationSide === 'put'));
                } else {
                    return true;
                }
            },
            showDummyCard: function () {
                return this.isMultipleLocations
                    && this.activeLocationId
                    && this.activeCardIsOnThisSide
                    && !this.multipleLocations.includes(this.activeLocationId)
                    && !this.otherSideLocationIds.includes(this.activeLocationId);
            },
            showNothingCard: function () {
                if (this.multipleLocations.length === 0 && !this.showDummyCard) {
                    switch (this.cardType) {
                    case TaskItemsCardType.TO_MOVE:
                        return [TaskMoveProductsType.COLLAPSE, TaskMoveProductsType.MANYTOMANY].includes(this.movementType);
                    case TaskItemsCardType.MOVED:
                        return [TaskMoveProductsType.DISTRIBUTE, TaskMoveProductsType.MANYTOMANY].includes(this.movementType);
                    default:
                        return false;
                    }
                }
                return false;
            },
            raiseBottomSheet: function () {
                return this.$store.getters['snackbar/isVisible']; // TODO unite these
            },
            allowLocationBottomSheet: function () {
                return false; // TODO show available locations in substock
            // return this.cardType === TaskItemsCardType.MOVED;
            },
            locationBottomSheetLocations: function () {
                return this.multipleLocations;
            },
            showMoveAllButton: function () {
                return this.cardType === TaskItemsCardType.IN_INVENTORY
                    && this.items.length !== 0
                    && this.isDestinationScanned
                    && this.items.filter(item => {
                        if (this.isAnyOfTypes([taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET])) {
                            return true;
                        }
                        return !item.allowedLocationIds || item.allowedLocationIds.includes(this.activeLocationId);
                    }).length;
            }
        },
        watch: {
            activeLocationId: function (newValue) {
                if (newValue && !this.LocationCache[newValue]) {
                    this.cacheLocation(StockAPI.getLocation(this.stockId, newValue), newValue);
                }
                if (this.sourceLocations.includes(this.activeLocationId) && !this.multipleLocations.includes(this.activeLocationId)) {
                    this.multipleLocations.unshift(newValue);
                } else {
                    this.triggerLocations++;
                }
            }
        },
        createdOrActivated: function () {
            this.barcodesLoaded = false;
            this.locationsCached = false;
            this.setSourceLocations();
            if (this.isMultipleLocations) {
                this.fetchLocations();
            }
        },
        methods: {
            fetchLocations: function () {
                const promises = [];
                this.multipleLocations.forEach(locationId => {
                    promises.push(this.cacheLocation(StockAPI.getLocation(this.stockId, locationId), locationId));
                });
                if (promises.length > 0) {
                    Promise.all(promises)
                        .then(() => {
                            this.locationsCached = true;
                            this.setSourceLocations(); // To order them by availability
                        }).catch(this.snack);
                }
            },
            setSourceLocations: function () {
                const sourceLocation = this.taskInfo.details.source_location;
                const itemsOnLocation = {};
                this.sourceLocations = [...new Set(
                    this.items
                        .filter(item => item.locations)
                        .flatMap(item =>
                            item.locations.map(loc => {
                                itemsOnLocation[loc.stock_location.id] = itemsOnLocation[loc.stock_location.id] + 1 || 1;
                                return loc.stock_location.id;
                            })
                        )
                )]
                    .filter(id => (sourceLocation === undefined || sourceLocation === null || sourceLocation.id === id))
                    .filter(id => !this.otherSideLocationIds.includes(id))
                    .sort((a, b) => {
                        const fallback = itemsOnLocation[b] - itemsOnLocation[a] || a - b;
                        if (this.locationsCached) {
                            return ((this.LocationCache[b].availability || 50) - (this.LocationCache[a].availability || 50)) || fallback;
                        }
                        return fallback;
                    });
                this.triggerLocations++;
            },
            quantityCurrent: function (item) {
                switch (this.cardType) {
                case TaskItemsCardType.TO_MOVE:
                    return item.quantity_to_move -
                        (
                            (this.isAnyOfTypes([taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET]) && this.taskInfo.details.shipping_type === TaskShippingType.PERSONAL_COLLECTION)
                                ? item.quantity_in_user_inventory
                                : (item.quantity_in_user_inventory + item.processed_quantity)
                        );
                case TaskItemsCardType.IN_INVENTORY:
                    return item.quantity_in_user_inventory;
                case TaskItemsCardType.MOVED:
                    return item.processed_quantity;
                case TaskItemsCardType.PRESENT:
                    return item.real_amount;
                case TaskItemsCardType.ASSIGNMENT:
                    if (this.isType(taskTypes.STOCK_TAKING)) {
                        return item.expected_amount;
                    }
                    if (this.isType(taskTypes.DELIVERY_ACCEPT)) {
                        return item.quantity;
                    }
                    return item.quantity_to_move;
                default:
                    return '';
                }
            },
            headerDetailTextMoved: function (pieces) {
                if ([TaskMoveProductsType.DISTRIBUTE, TaskMoveProductsType.MANYTOMANY].includes(this.movementType)) {
                    return this.$t('tasks.itemsCard.toAnywhere');
                } else {
                    return `${this.$t('tasks.itemsCard.to')} ${locationLabel(this.destinationLocation, '')} (${pieces})`;
                }
            },
            highlightActiveCard: function (locationId) {
                return this.activeCardIsOnThisSide && locationId === this.activeLocationId;
            },
            onClickBack: function (item, quantity, return_location, callback) {
                let promiseCallback = () => Promise.resolve();
                // TODO can be done locally and later synced with backend, however we would have to check if return is possible (user picked item from that source)
                if (this.cardType === TaskItemsCardType.IN_INVENTORY) {
                    promiseCallback = () => this.api.putToSource(this.taskInfo.taskId, item.id, return_location, quantity)
                        .then(() => {
                            EventBus.$emit(this.itemsUpdateEmit, callback);
                        }).catch(this.snack);
                } else if (this.cardType === TaskItemsCardType.MOVED) {
                    promiseCallback = () => this.api.pickUpFromDestination(this.taskInfo.taskId, item.id, return_location, quantity)
                        .then(() => {
                            EventBus.$emit(this.itemsUpdateEmit, callback);
                        }).catch(this.snack);
                }
                this.$emit('return-item', promiseCallback);
            },
            onClickRemove: function (item, locationId) {
                const index = this.items.findIndex(it => it.id === item.id);
                if (this.isType(taskTypes.STOCK_TAKING) && this.items[index].expected_amount > 0) {
                    /* eslint-disable-next-line vue/no-mutating-props */
                    this.items[index].real_amount = 0;
                    this.api.updateItem(this.taskInfo.taskId, item.id, 0);
                } else {
                    if (this.isType(taskTypes.STOCK_TAKING)) {
                        /* eslint-disable-next-line vue/no-mutating-props */
                        this.items.splice(index, 1);
                        this.api.deleteItem(this.taskInfo.taskId, item.id, locationId);
                    } else {
                        this.$emit('stock-loading-item-delete', item.id, locationId);
                    }
                }
            },
            itemQuantityOnLocation: function (item, locationId) {
                if (this.cardType === TaskItemsCardType.TO_MOVE) {
                    if (item.locations !== undefined) {
                        const loc = item.locations.find(location => location.stock_location.id === locationId);
                        if (loc !== undefined) {
                            return Math.min(loc.quantity, item.quantity_to_move);
                        }
                    }
                } else {
                    if (item.destination_locations !== undefined) {
                        const itemAtLocation = item.destination_locations.find(el => this.isType(taskTypes.STOCK_LOADING) ? el.location_id === locationId : el.stock_location_id === locationId);
                        if (itemAtLocation !== undefined) {
                            return this.isType(taskTypes.STOCK_LOADING) ? itemAtLocation.quantity : itemAtLocation.store_quantity;
                        } else {
                            return false;
                        }
                    } else {
                        return (item.location_id === locationId || item.stock_location_id === locationId) && (item.quantity || item.real_amount);
                    }
                }
            },
            batchPrint: function () {
                this.print = false;
                this.printing = true;
                const promises = [];
                if (!this.barcodesLoaded) {
                    this.items.map(item => {
                        promises.push(
                            ProductAPI.getAllInstanceBarcodes(item.instance.product.id, item.instance.id).then(response => {
                                item.barcodes = response.data;
                            })
                        );
                    });
                }
                Promise.all(promises).then(() => {
                    this.barcodesLoaded = true;
                    const codes = [];
                    this.items.forEach(item => {
                        if (item.barcodes.length) {
                            codes.push({
                                code: item.barcodes[0].code,
                                amount: this.printJustOnePerItem ? 1 : item.quantity_to_move
                            });
                        }
                    });
                    ProductAPI.printInstanceBarcodes(this.printType, codes)
                        .then(response => {
                            const fileExtension = this.printType;
                            Export.print(response.data.url, 'task_' + this.taskInfo.details.id + '_codes' + fileExtension, this.printType);
                        }).catch(this.snack)
                        .finally(() => {
                            this.printing = false;
                        });
                });
            },
            checkItemsInInventory: function (callback) {
                if (this.cardType === TaskItemsCardType.IN_INVENTORY && this.items && this.items.length !== 0) {
                    callback();
                }
            }
        }
    };
</script>

<style scoped lang="sass">
.listSwitcher
    z-index: 1
    right: 25px
    top: 13px

.normalBreak
    word-break: normal

.moveAllButton
    display: inline-block
    width: 95%
    white-space: normal
</style>
