<template>
  <v-alert
    outlined
    type="error"
    class="d-flex flex-column"
  >
    <div>
      {{ message }}
    </div>
    <div
      class="flex-wrap"
    >
      <template v-if="type === 'blocking_tasks'">
        <v-btn
          v-for="task of details"
          :key="task.id"
          color="error"
          text
          @click="$router.push(taskLinks[task.type] + '/' + task.id)"
        >
          #{{ task.id }} {{ $t(taskNames[task.type]) }}
          <v-icon class="ml-2">
            $openItem
          </v-icon>
        </v-btn>
      </template>
      <template v-if="type === 'items_at_location'">
        <v-btn
          v-for="item of details"
          :key="item.stock_location.id + '-' + item.product_instance.id"
          color="error"
          text
        >
          {{ item.quantity }}{{ item.product_instance | productMeasureLabel }}
          {{ item.product_instance | instanceLabel }}
          <v-icon class="ml-2">
            $openItem
          </v-icon>
        </v-btn>
      </template>
      <template v-if="type === 'missing_item'">
        <v-btn
          color="error"
          text
        >
          {{ details.quantity }}{{ details.product_instance | productMeasureLabel }}
          {{ details.product_instance | instanceLabel }}
          <v-icon class="ml-2">
            $openItem
          </v-icon>
        </v-btn>
      </template>
      <template v-if="type === 'location_not_allowed_for_substock'">
        <v-btn
          color="error"
          text
          @click="$router.push('/stocks/' + details.stock_id + '/substocks/' + details.id)"
        >
          {{ details.name }}
          <v-icon class="ml-2">
            $openItem
          </v-icon>
        </v-btn>
      </template>
    </div>
  </v-alert>
</template>

<script>
    import {taskLinks, taskNames} from "@/enum/task_type";

    export default {
        name: "LocationTransferConflict",
        props: {
            error: {
                type: Object,
                default: () => ({
                    type: null,
                    message: ''
                })
            }
        },
        data: () => ({
            taskLinks: taskLinks,
            taskNames: taskNames
        }),
        computed: {
            type: function () {
                return this.error.type;
            },
            message: function () {
                return this.error.message;
            },
            details: function () {
                return this.error[this.error.type];
            }
        }
    };
</script>

<style scoped>

</style>
