import Axios from "axios";
import {Crypto} from "@/utils/crypto";
import {Sentry} from "@/service/Sentry";
import qs from "qs";
import oauthurl from "@/enum/oauth_url";
import {config} from "@/config/config";
import {fetchUserConfig} from "@/service/store/UserConfigHelper";

const OAUTH_CLIENT_ID = config.OAUTH_CLIENT_ID;

const oAuthModule = {
    namespaced: true,
    devPersistent: true,
    state: {
        authenticated: false,
        accessToken: '',
        refreshToken: '',
        accessTokenExpireAt: 0,
        refreshTokenExpireAt: 0,
        scopes: [],
        userInfo: null
    },
    actions: {
        redirectToAuthUrl() {
            // Generate code verifier and code challenge
            const codeVerifier = Crypto.generateCodeVerifier();
            const codeChallenge = Crypto.createCodeChallenge(codeVerifier);

            // Store code verifier to session storage
            localStorage.setItem('_cv', codeVerifier);

            // Redirect user to OAuth server
            window.location.href = oauthurl.AUTHORIZE_URL +
                '?code_challenge=' + codeChallenge +
                '&client_id=' + OAUTH_CLIENT_ID +
                '&response_type=code&code_challenge_method=S256';
        },

        handleLoginRequest({commit, dispatch}, query) {
            return new Promise((resolve, reject) => {
                const code = query.code;
                const codeVerifier = localStorage.getItem('_cv');

                if (code === undefined || codeVerifier === null) {
                    reject();
                    return;
                }

                Axios.post(oauthurl.TOKEN_URL, qs.stringify({
                    grant_type: 'authorization_code',
                    redirect_uri: oauthurl.REDIRECT_URL,
                    client_id: OAUTH_CLIENT_ID,
                    code: code,
                    code_verifier: codeVerifier
                })).then(response => {
                    commit('login', response.data);
                    dispatch('fetchUserInfo').then(() => {
                        resolve();
                    });
                }).catch((error) => {
                    Sentry.captureException(error);
                    commit('logout');
                    reject();
                }).finally(() => {
                    // Remove code verifier from session
                    localStorage.removeItem('_cv');
                });
            });
        },

        logout({commit, dispatch}) {
            localStorage.removeItem('lastUser');
            dispatch('userConfig/clear', null, {root: true});
            commit('logout');
            window.location.href = oauthurl.LOGOUT_URL + '?redirect=' + encodeURIComponent(oauthurl.LOGOUT_RETURN_URL);
        },

        relogin({commit}) {
            localStorage.removeItem('lastUser');
            commit('logout');
            window.location.href = oauthurl.LOGOUT_URL + '?redirect=' + encodeURIComponent(oauthurl.RELOGIN_URL);
        },

        renewToken({commit, state, dispatch}, renewCallback) {
            if ((new Date().getTime() + 1000) >= state.refreshTokenExpireAt) {
                dispatch('redirectToAuthUrl');
            }
            return new Promise((resolve, reject) => {
                Axios.post(oauthurl.TOKEN_URL, qs.stringify({
                    grant_type: 'refresh_token',
                    refresh_token: state.refreshToken,
                    client_id: OAUTH_CLIENT_ID,
                })).then(response => {
                    commit('login', response.data);
                    renewCallback();
                    dispatch('fetchUserInfo').then(() => {
                        resolve();
                    });
                }).catch(() => {
                    dispatch('userConfig/clear', null, {root: true});
                    commit('logout');
                    reject();
                });
            }).catch(() => {
                dispatch('userConfig/clear', null, {root: true});
                commit('logout');
            });
        },

        fetchUserInfo({commit, state}) {
            return new Promise((resolve, reject) => {
                Axios.post(oauthurl.INTROSPECTION_URL, qs.stringify({
                    token: state.accessToken
                })).then(response => {
                    commit('fetchUserInfo', response.data);
                    localStorage.setItem('lastUser', state.userInfo.firstname + " " + state.userInfo.lastname);
                    Sentry.registerUser();
                    fetchUserConfig();
                    resolve();
                }).catch(() => {
                    commit('logout');
                    reject();
                });
            });
        },
    },

    mutations: {
        login(state, tokenInfo) {
            state.authenticated = true;
            state.accessToken = tokenInfo.access_token;
            state.refreshToken = tokenInfo.refresh_token;
            state.accessTokenExpireAt = (tokenInfo.expires_in * 1000) + new Date().getTime();
            state.refreshTokenExpireAt = (7 * 24 * 60 * 60 * 1000) + new Date().getTime();
            state.scopes = tokenInfo.scope.split(' ');
        },
        fetchUserInfo(state, userInfo) {
            state.userInfo = {
                firstname: userInfo.first_name,
                lastname: userInfo.last_name,
                username: userInfo.username,
                user_id: userInfo.user_id
            };
        },
        logout(state) {
            state.authenticated = false;
            state.accessToken = '';
            state.refreshToken = '';
            state.accessTokenExpireAt = 0;
            state.refreshTokenExpireAt = 0;
            state.userInfo = null;
            state.scopes = [];
        },
    },

    getters: {
        isAuthenticated: (state) => state.authenticated,
        isAccessExpired: (state) => {
            return () => (new Date().getTime() + 1000) >= state.accessTokenExpireAt;
        },

        getAccessToken: (state) => state.accessToken,
        getUserFullName: (state, getters) => getters.isAuthenticated && (state.userInfo !== null) ? (state.userInfo.firstname + " " + state.userInfo.lastname) : '',
        getUserId: (state) => state.userInfo !== null ? state.userInfo.user_id : null,

        hasScope: (state, getters) => {
            return (scope) => {
                return getters.isAuthenticated && state.scopes.includes(scope);
            };
        },
        hasOnlyScope: (state, getters) => {
            return (scope) => {
                return getters.hasScope(scope) && state.scopes.length === 1;
            };
        },
        getScopes: (state) => state.scopes
    }
};

export default oAuthModule;
