import {Env} from "@/service/Environment";
import {Store} from "@/service/store/Store";
import {Sentry} from "@/service/Sentry";
import {flatten} from "@/utils/object";
import moment from "moment";
import {PrintType} from "@/enum/print_type";
import {Electron} from "@/service/Electron";
import {ReportPrintTypes} from "@/enum/report_type";
import {ISO_8601_DATE_TIME_FORMAT} from "@/utils/datetime";

/**
 * @param href {string}
 * @param filename {string}
 */
function downloadFile(href, filename) {
    // In APK use system call
    try {
        window.Android.printUrls(JSON.stringify([href]));
    } catch (error) {
        // If not in APK, do a direct download
        const a = window.document.createElement('a');
        // a.href = href.replace('http://', 'https://');
        a.href = href;
        a.download = filename;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    Store.commit('snackbar/setVisible', false);
}

function escapeCSVEntry(csvEntry) {
    let escapedEntry = csvEntry + '';
    escapedEntry = escapedEntry.replace(/"/g, '""');
    if (escapedEntry.includes(';')) {
        escapedEntry = '"' + escapedEntry + '"';
    }
    return escapedEntry;
}

/**
 * @param data {Object[]}
 * @param headers {string[]} if none or '[]' provided, first data object keys will be used as headers
 * @param filename {string}
 */
function csv(data, headers = [], filename = 'export.csv') {
    let csv = '';
    if (headers.length === 0) {
        headers = Object.keys(flatten(data[0]));
    }
    csv += headers.map(escapeCSVEntry).join(';') + '\r\n';
    for (const item of data) {
        csv += Object.values(flatten(item))
                .map((value, index) => {
                    const headerName = headers[index] || '';
                    if (headerName.endsWith('id') || headerName.startsWith('id')) {
                        return value;
                    } else if (moment(value, ISO_8601_DATE_TIME_FORMAT, true).isValid()) {
                        return moment(value).format(ISO_8601_DATE_TIME_FORMAT);
                    } else {
                        return value;
                    }
                })
                .map(escapeCSVEntry)
                .join(';')
            + '\r\n';
    }
    downloadFile('data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv), filename);
}

/**
 * @param data {string} binary-streamed PDF
 * @param filename {string}
 */
function pdf(data, filename = 'report.pdf') {
    const file = new Blob([data], {type: 'application/pdf'});
    const fileURL = URL.createObjectURL(file);
    downloadFile(fileURL, filename);
}

/**
 * @param data {string} binary stream
 * @param content_type {string}
 * @param filename {string}
 */
function any(data, content_type, filename = 'downloaded_file') {
    const file = new Blob([data], {type: content_type});
    const fileURL = URL.createObjectURL(file);
    downloadFile(fileURL, filename);
}

/**
 * @param url {string}
 * @param filename {string}
 */
function url(url, filename = 'downloaded_file') {
    downloadFile(url, filename);
}

/**
 * In Electron, print using Electron config, in other environments, download the label.
 * @param url {string}
 * @param filename {string}
 * @param printType {PrintType} TODO drop this once all clients use Electron >=5
 */
function print(url, filename = 'print_file', printType) {

    if (Env.isElectron()) {

        const messageBus = window.require('electron').ipcRenderer;

        messageBus.invoke('public.version.get')
            .then(version => {
                window.console.info(`Detected ElectroWhale version ${version}`);
                if (version >= '5.0.0') {
                    if (version >= '6.0.0') {
                        Sentry.captureWarning(`Unsupported ElectroWhale version ${version} detected, update this domain ASAP!`);
                    }
                    messageBus.invoke('public.print.byMimeType', url)
                        .then(() => {
                            Store.commit('snackbar/set', {text: 'base.print.sent'});
                        })
                        .catch(err => {
                            // TODO print just 'err' once https://github.com/electron/electron/issues/24427 is resolved
                            const split = (err.toString()).split('public.print.byMimeType');
                            Store.commit('snackbar/set', {text: split[1].slice(3) || split[0], timeout: 0});
                        });
                } else {
                    throw new Error(`ElectroWhale version ${version} < 5.0.0 is legacy`);
                }
            })
            .catch(() => { // TODO Drop this once all envs use ElectroWhale >=5
                window.console.info('Legacy ElectroWhale detected');
                if (printType === PrintType.PDF) {
                    messageBus.send('system.printPdf', url);
                    // Fallback for old Electron. Drop this in the future
                    messageBus.send('system.printUrl', url, /* options: */ undefined, /* PDF: */ true);
                    Store.commit('snackbar/set', {text: 'base.print.sent'});
                } else if (printType === PrintType.ZPL) {
                    messageBus.send('system.printLabelZebra', url);
                    Store.commit('snackbar/set', {text: 'base.print.sent'});
                } else if (printType === PrintType.ESCP) {
                    messageBus.send('system.printLabelBrother', url);
                    Store.commit('snackbar/set', {text: 'base.print.sent'});
                } else if (printType === PrintType.ZPLX) {
                    messageBus.send('system.printLabelZebraZplx', url);
                    Store.commit('snackbar/set', {text: 'base.print.sent'});
                } else if (printType === PrintType.ZPLX2) {
                    messageBus.send('system.printLabelZebraZplx2', url);
                    Store.commit('snackbar/set', {text: 'base.print.sent'});
                } else {
                    downloadFile(url, filename);
                }
            });
    } else {
        downloadFile(url, filename);
    }
}

/**
 * @param api {Object}
 * @param taskId {number|string}
 * @param preferredMimeType {ReportType.*}
 * @param filePrefix {string}
 * @returns {Promise<*>}
 */
function report(api, taskId, preferredMimeType, filePrefix = 'report') {
    return new Promise((resolve, reject) => {
        let promise = Promise.resolve(preferredMimeType);
        if (Env.isElectron()) {
            promise = Electron.getConfiguredMimeType(preferredMimeType)
                .then(configuredMimeType => {
                    if (!configuredMimeType) {
                        Store.commit('snackbar/set', {
                            text: 'base.print.noConfigFor',
                            params: [ReportPrintTypes.map(rpt => rpt.mime).join(', ')]
                        });
                        resolve();
                    }
                    return configuredMimeType;
                }).catch(reject);
        }
        promise.then(selectedMimeType => {
            api.report(taskId, selectedMimeType.mime)
                .then(response => {
                    print(
                        response.data.url,
                        `${filePrefix}_${taskId}.${selectedMimeType.suffix}`,
                        selectedMimeType.print
                    );
                    resolve();
                })
                .catch(reject);
        }).catch(reject);
    });
}

export {csv, pdf, any, url, print, report};
