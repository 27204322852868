import {config} from "@/config/config";
import {has} from "@/utils/object";

/**
 * @param address {Object}
 * @returns {string}
 */
function addressInline(address) {
    let inline = '';
    const hiddenFields = ['address_id', 'default', 'privacy'];
    for (const item in address) {
        if (!hiddenFields.includes(item) && has(address, item) && address[item] !== null) {
            inline += address[item] + ', ';
        }
    }
    return inline === '' ? '' : inline.slice(0, -2);
}

/**
 * @param person {Object}
 * @returns {string}
 */
function personInLine(person) {
    let inline = '';
    const displayedFields = ['name', 'ico', 'dic', 'phone', 'email', 'website'];
    for (const field of displayedFields) {
        if (has(person, field) && person[field] !== null) {
            inline += person[field] + ', ';
        }
    }
    return inline === '' ? '' : inline.slice(0, -2);
}

/**
 * @param localisedTitle {string}
 * @returns {string}
 */
function tabTitle(localisedTitle) {
    return localisedTitle + ' | ' + config.DOMAIN_LABEL;
}

/**
 * @param location {{
 *     code: string,
 *     name: string
 * }}
 * @param placeholder
 */
function locationLabel(location, placeholder = '...') {
    // loading string from cache
    if (typeof location === 'string') {
        return location;
    }
    if (has(location, 'user')) {
        return location.user.first_name + ' ' + location.user.last_name;
    }
    if (location === null || location === undefined) {
        return placeholder;
    } else {
        if (location.code === location.name) {
            return location.code;
        } else {
            return location.code + ' (' + location.name + ')';
        }
    }
}

/**
 * @param text
 * @return {Promise}
 */
function copyToClipboard(text) {
    return new Promise((resolve, reject) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text).then(resolve).catch(reject);
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = text;

            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                document.execCommand('copy') ? resolve() : reject();
            } catch (err) {
                reject(err);
            } finally {
                document.body.removeChild(textArea);
            }
        }
    });
}

export {addressInline, personInLine, tabTitle, locationLabel, copyToClipboard};
