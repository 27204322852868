<template>
  <div>
    <template v-if="details && ready">
      <v-layout wrap>
        <v-flex
          md4
          xs12
        >
          <v-card
            class="ma-1"
          >
            <v-container>
              <div>
                <span class="text-caption">{{ $t('tasks.state.name') + ': ' }}</span>
                {{ details.state | taskState }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.priority.name') + ': ' }}</span>
                {{ details.priority | taskPriority }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.assigned_user') + ': ' }}</span>
                {{ details.assigned_user | fullName }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.time_spent') + ': ' }}</span>
                {{ details.time_entries | timeEntriesSumHumanize }}
              </div>
              <v-divider
                class="my-2"
              />
              <div>
                <span class="text-caption">{{ $t('tasks.stockTaking.stock_id') + ': ' }}</span>
                {{ details.stock.name }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.stockTaking.restrict_reference_id') + ': ' }}</span>
                {{ $t('tasks.stockTaking.restrictions.' + details.restrict_type) + ': ' }}
                <span v-if="isRestrictedToLocations">
                  {{ details.stock_locations.map(el => el.name).join(', ') }}
                </span>
                <span v-else>
                  {{ restrictItem !== null ? restrictItem.name : '-' }}
                </span>
              </div>
              <div v-show="details.description">
                <span class="text-caption">{{ $t('tasks.description') + ': ' }}</span>
                {{ details.description }}
              </div>
              <v-divider
                class="my-2"
              />
              <EntityHistory :changes="details" />
            </v-container>
          </v-card>
        </v-flex>
        <v-flex
          v-show="details.notes.length > 0"
          md4
          xs12
        >
          <Notes :notes="details.notes" />
        </v-flex>
        <v-flex
          md4
          xs12
        >
          <TaskAttachments
            :attachments="details.attachments"
          />
        </v-flex>
      </v-layout>
      <TaskItemsCard
        v-if="isChief"
        :api="API"
        :card-type="TaskItemsCardType.ASSIGNMENT"
        :is-single="false"
        :items="items"
        :task-info="taskInfo"
        task-lang-path="stockTaking."
      />
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>

<script>
    import EntityHistory from "@/app/components/EntityHistory.component";
    import Notes from "@/app/tasks/components/TaskNotes.component";
    import TaskAttachments from "@/app/tasks/components/TaskAttachments.component";
    import {TaskStockTakingRestriction as RestrictionType} from "@/enum/task_stock_taking_restriction";
    import {StockAPI} from "@/api/StockAPI";
    import {ProductAPI} from "@/api/ProductAPI";
    import {ManufacturerAPI} from "@/api/ManufacturerAPI";
    import TaskItemsCard from "@/app/tasks/components/taskItemsCard/TaskItemsCard.component";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";
    import {TaskStockTakingAPI as API} from "@/api/TaskStockTakingAPI";
    import {TaskFetchItemsMixin} from "@/app/mixins/TaskFetchItemsMixin";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {mapReduce} from "@/utils/array";

    export default {
        name: "StockTakingAssignment",
        components: {TaskAttachments, Notes, EntityHistory, TaskItemsCard},
        mixins: [TaskItemsCardType, TaskFetchItemsMixin, ACLMixin],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            restrictItem: null,
            ready: false,
            items: [],
            TaskItemsCardType: TaskItemsCardType,
            API: API
        }),
        computed: {
            details: function () {
                return this.taskInfo.details;
            },
            isNotRestricted: function () {
                return this.details.restrict_type === RestrictionType.NONE;
            },
            isRestrictedToLocations: function () {
                return this.details.restrict_type === RestrictionType.STOCK_LOCATION;
            }
        },
        createdOrActivated: function (lifeCycleHook) {
            if (!this.isNotRestricted && !this.isRestrictedToLocations) {
                switch (this.details.restrict_type) {
                case RestrictionType.SUB_STOCK:
                    this.loadSubStock();
                    break;
                case RestrictionType.PRODUCT:
                    this.loadProduct();
                    break;
                case RestrictionType.MANUFACTURER:
                    this.loadManufacturer();
                    break;
                case RestrictionType.STOCK_OWNER:
                    this.loadStockOwner();
                    break;
                }
            }
            if (lifeCycleHook === this.LifeCycleHook.CREATED) {
                this.fetchItems({initial: true}).then(() => {
                    this.items = this.items.filter(item => item.expected_amount !== 0);
                    this.items = mapReduce(this.items, ['product_instance_id'], 'expected_amount');
                    this.ready = true;
                }).catch(this.snack);
            } else {
                this.fetchItems().catch(this.snack);
            }
        },
        methods: {
            loadSubStock: function () {
                StockAPI.getSubstock(this.details.stock.id, this.details.restrict_reference_id)
                    .then(response => {
                        this.restrictItem = response.data;
                    });
            },
            loadProduct: function () {
                ProductAPI.get(this.details.restrict_reference_id)
                    .then(response => {
                        this.restrictItem = response.data;
                    });
            },
            loadManufacturer: function () {
                ManufacturerAPI.get(this.details.restrict_reference_id)
                    .then(response => {
                        this.restrictItem = response.data;
                    });
            },
            loadStockOwner: function () {
                StockAPI.getOwner(this.details.restrict_reference_id)
                    .then(response => {
                        this.restrictItem = response.data;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
