<template>
  <div>
    <x-toolbar />
    <v-card>
      <v-container>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="tasks.locationTransfer."
          />
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <TaskAttachmentUpload
              v-model="validAttachments"
              :files-to-attach="form.attachments"
            />
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-btn
              :text="!validAll"
              :loading="loading"
              type="submit"
              color="accent"
              @click="submit()"
            >
              <v-icon
                class="mr-2"
              >
                $taskLocationTransfer
              </v-icon>
              {{ $t('tasks.locationTransfer.create') }}
            </v-btn>
          </v-flex>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import TaskAttachmentUpload from "@/app/tasks/components/TaskAttachmentUpload.component";
    import {formMountedProcedure, setFormErrors} from "@/utils/form";
    import {
        LocationTransferCreateForm,
        LocationTransferCreateRender
    } from "@/app/tasks/locationTransfer/definitions/locationTransferCreate.form";
    import {TaskLocationTransferAPI} from "@/api/TaskLocationTransferAPI";
    import {taskLinks, taskTypes} from "@/enum/task_type";
    import {getIdFromLocation} from "@/utils/url";

    export default {
        name: "LocationTransferCreate",
        components: {TaskAttachmentUpload, FormFields},
        data: () => ({
            form: new LocationTransferCreateForm,
            formRender: new LocationTransferCreateRender,
            valid: true,
            validAttachments: true,
            loading: false
        }),
        computed: {
            validAll: function () {
                return this.valid && this.validAttachments;
            }
        },
        mounted: function () {
            formMountedProcedure(this);
        },
        methods: {
            submit: function () {
                if (!this.validAll) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                TaskLocationTransferAPI.create(this.form)
                    .then(response => {
                        this.snack('tasks.locationTransfer.createDone');
                        this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                        this.$router.push(taskLinks[taskTypes.LOCATION_TRANSFER] + '/' + getIdFromLocation(response));
                    })
                    .catch(setFormErrors.bind(this))
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
