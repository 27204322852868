<template>
  <div>
    <x-toolbar />
    <v-card>
      <v-container>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="tasks.stockPicking."
          />
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <ProductInstancesChips
              :items="currentSubstockItems"
              :loading="loadingSubstockItems"
              can-load-locations
            />
          </v-flex>
          <v-flex
            xs12
          >
            <TaskChooseItems
              :chosen-items.sync="chosenItems"
              :available-items="currentSubstockItems"
              title-path="tasks.stockPicking.itemsToPick"
              :loading="loading"
              :loading-items="loadingSubstockItems"
              items-from-stock-status
            >
              <template #item="{ item }">
                <v-layout>
                  <v-flex>
                    {{ item.instance | instanceLabel }}
                  </v-flex>
                  <v-flex class="text-right">
                    <v-tooltip left>
                      <template #activator="{ on }">
                        <span
                          v-on="on"
                        >
                          <span
                            v-if="item.blocked > 0 && $vuetify.breakpoint.smAndUp"
                            class="text--disabled"
                          >
                            ({{ item.quantity }} - {{ item.blocked }}) =
                          </span>
                          {{ item.quantity - item.blocked }}
                        </span>
                      </template>
                      {{ item.quantity }} {{ $t('tasks.itemPick.inStock') }}<br>
                      {{ item.blocked }} {{ $t('tasks.itemPick.inProgress') }}<br>
                      {{ item.quantity - item.blocked }} {{ $t('tasks.itemPick.available') }}
                    </v-tooltip>
                  </v-flex>
                </v-layout>
              </template>
            </TaskChooseItems>
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <TaskAttachmentUpload
              v-model="validAttachments"
              :files-to-attach="form.attachments"
            />
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-btn
              :text="!validAll"
              :loading="loading"
              type="submit"
              color="accent"
              @click="submit()"
            >
              <v-icon
                class="mr-2"
              >
                $taskStockPicking
              </v-icon>
              {{ $t('tasks.stockPicking.create') }}
            </v-btn>
          </v-flex>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {
        StockPickingCreateForm,
        StockPickingCreateRender
    } from "@/app/tasks/stockPicking/definitions/stockPickingCreate.form";
    import {formMountedProcedure, setFormErrors} from "@/utils/form";
    import formRules from "@/utils/formRules";
    import {TaskStockPickingAPI} from "@/api/TaskStockPickingAPI";
    import {StockAPI} from "@/api/StockAPI";
    import {TaskShippingType} from "@/enum/task_shipping_type";
    import TaskAttachmentUpload from "@/app/tasks/components/TaskAttachmentUpload.component";
    import {has} from "@/utils/object";
    import ProductInstancesChips from "@/app/products/instances/components/ProductInstancesChips.component";
    import {BuyerAPI} from "@/api/BuyerAPI";
    import {TaskFetchItemsMixin} from "@/app/mixins/TaskFetchItemsMixin";
    import {locationLabel} from "@/utils/string";
    import TaskChooseItems from "@/app/tasks/components/TaskChooseItems.component";
    import {taskLinks, taskTypes} from "@/enum/task_type";
    import {getIdFromLocation} from "@/utils/url";

    export default {
        name: "StockPickingCreate",
        components: {ProductInstancesChips, TaskAttachmentUpload, FormFields, TaskChooseItems},
        mixins: [TaskFetchItemsMixin],
        data: () => ({
            form: new StockPickingCreateForm(TaskShippingType.PERSONAL_COLLECTION),
            formRender: new StockPickingCreateRender(TaskShippingType.PERSONAL_COLLECTION),
            valid: true,
            validAttachments: true,
            loading: false,
            chosenItems: [{product_id: null, product_instance_id: null}],
            formRules: formRules,
            substockItems: {},
            loadingSubstockItems: false
        }),
        computed: {
            currentSubstockItems: function () {
                if (has(this.substockItems, this.form.subordinate_stock_id)) {
                    return this.substockItems[this.form.subordinate_stock_id];
                } else {
                    return [];
                }
            },
            validAll: function () {
                return this.valid && this.validAttachments;
            }
        },
        watch: {
            'form.stock_id': function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    this.loadSubStocks();
                }
            },
            'form.subordinate_stock_id': function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    this.loadSubstockItems();
                    this.loadLocations();
                }
            },
            'form.buyer_id': function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    this.loadBuyerDeliveryAddresses();
                }
            },
            'form.shipping_type': function (newValue) {
                if (newValue === TaskShippingType.COURIER) {
                    this.formRender.destination_stock_location_id.readonly = false;
                } else {
                    this.form.destination_stock_location_id = null;
                    this.formRender.destination_stock_location_id.readonly = true;
                    // TODO trigger required
                    delete this.formRender.destination_stock_location_id.errors;
                }
            }
        },
        mounted: function () {
            formMountedProcedure(this);
        },
        methods: {
            loadSubStocks: function () {
                this.$set(this.formRender.subordinate_stock_id, 'loading', true);
                StockAPI.getAllSubstockPages(this.form.stock_id)
                    .then(response => {
                        this.formRender.subordinate_stock_id.autocomplete.items = response.data.items.map(el => ({
                            text: el.name,
                            value: el.id
                        }));
                    }).finally(() => {
                        this.$set(this.formRender.subordinate_stock_id, 'loading', false);
                    });
            },
            loadLocations: function () {
                if (this.form.shipping_type === TaskShippingType.COURIER) {
                    this.$set(this.formRender.destination_stock_location_id, 'loading', true);
                    StockAPI.getAllSubstockAvailableLocationsAllPages(this.form.stock_id, this.form.subordinate_stock_id)
                        .then(response => {
                            this.formRender.destination_stock_location_id.autocomplete.items = response.data.items.map(el => ({
                                text: locationLabel(el),
                                value: el.id
                            }));
                        }).finally(() => {
                            this.$set(this.formRender.destination_stock_location_id, 'loading', false);
                        });
                }
            },
            loadBuyerDeliveryAddresses: function () {
                this.$set(this.formRender.buyer_delivery_address_id, 'loading', true);
                BuyerAPI.getAllAddresses(this.form.buyer_id)
                    .then(response => {
                        this.formRender.buyer_delivery_address_id.autocomplete.items = response.data.map(({address}) => ({
                            text: this.$options.filters.addressInline(address),
                            value: address.address_id
                        }));
                        const defaultAddress = response.data.find((address) => address.default);
                        if (defaultAddress !== undefined) {
                            this.form.buyer_delivery_address_id = defaultAddress.address.address_id;
                        }
                    }).finally(() => {
                        this.$set(this.formRender.buyer_delivery_address_id, 'loading', false);
                    });
            },
            loadSubstockItems: function () {
                const ssId = this.form.subordinate_stock_id;
                if (!has(this.substockItems, ssId)) {
                    this.$set(this.substockItems, ssId, []);
                }
                if (this.substockItems[ssId].length === 0) {
                    this.fetchSubstockItems(ssId);
                }
            },
            submit: function () {
                if (!this.validAll) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                const data = {...this.form};
                data.items = this.chosenItems
                    .filter(item => {
                        delete item.errors;
                        delete item.product_id;
                        return item.product_instance_id !== null;
                    });
                TaskStockPickingAPI.create(data)
                    .then(response => {
                        let newId;
                        if (response.status === 200) {
                            newId = response.data.id;
                            this.advancedSnack({
                                text: 'tasks.stockPicking.createDoneAdded',
                                params: [response.data.id]
                            });
                        } else {
                            newId = getIdFromLocation(response);
                            this.snack('tasks.stockPicking.createDone');
                        }
                        this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                        this.$router.push(taskLinks[taskTypes.STOCK_PICKING] + '/' + newId);
                    }).catch(setFormErrors.bind(this))
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    };
// TODO only allow to select quantity - blocked
// TODO create component for shared funcionality with MoveProductsCreate and SubstockTransferCreate
// TODO CRITICAL how to stock picking from small stock and expedition?
</script>

<style scoped>

</style>
