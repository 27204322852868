<template>
  <div>
    <x-toolbar />
    <v-card>
      <v-container>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="tasks.stockTaking."
          />
          <v-flex
            v-if="showLocationHelper"
            xs12
          >
            <v-divider
              class="my-3"
            />
            <div>
              <span class="text-caption">
                {{ $t('tasks.stockTaking.locationHelper.label') }}
              </span>
              <v-text-field
                v-model="locationHelperValue"
                outlined
                :hint="$t('tasks.stockTaking.locationHelper.hint')"
              />
            </div>
            <div>
              <v-chip
                v-for="item of locationHelperItems.slice(0, 20)"
                :key="item.value"
                class="ma-1"
                outlined
                label
                :value="false"
              >
                {{ item.text }}
              </v-chip>
              <v-chip
                v-if="locationHelperItems.length > 20"
                color="secondary"
                outlined
                label
              >
                +{{ locationHelperItems.length - 20 }}
              </v-chip>
            </div>
            <div>
              <v-btn
                color="secondary"
                class="my-2"
                @click="locationHelperSubmit"
              >
                {{ $t('tasks.stockTaking.locationHelper.set') }}
              </v-btn>
            </div>
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <TaskAttachmentUpload
              v-model="validAttachments"
              :files-to-attach="form.attachments"
            />
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-btn
              :text="!validAll"
              :loading="loading"
              type="submit"
              color="accent"
              @click="submit()"
            >
              <v-icon
                class="mr-2"
              >
                $taskStockTaking
              </v-icon>
              {{ $t('tasks.stockTaking.create') }}
            </v-btn>
          </v-flex>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import {
        StockTakingCreateForm,
        StockTakingCreateRender
    } from "@/app/tasks/stockTaking/definitions/stockTakingCreate.form";
    import {formMountedProcedure, setFormErrors} from "@/utils/form";
    import FormFields from "@/app/components/form/FormFields.component";
    import {StockAPI} from "@/api/StockAPI";
    import {TaskStockTakingRestriction as RestrictionType} from "@/enum/task_stock_taking_restriction";
    import {ProductAPI} from "@/api/ProductAPI";
    import {ManufacturerAPI} from "@/api/ManufacturerAPI";
    import {TaskStockTakingAPI} from "@/api/TaskStockTakingAPI";
    import {taskLinks, taskTypes} from "@/enum/task_type";
    import TaskAttachmentUpload from "@/app/tasks/components/TaskAttachmentUpload.component";
    import {locationLabel} from "@/utils/string";
    import {getIdFromLocation} from "@/utils/url";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";

    export default {
        name: "StockTakingCreate",
        components: {TaskAttachmentUpload, FormFields},
        mixins: [RouteParamsMapperMixin],
        data: () => ({
            type: taskTypes.STOCK_TAKING,
            form: new StockTakingCreateForm,
            formRender: new StockTakingCreateRender,
            valid: true,
            validAttachments: true,
            loading: false,
            locationHelperValue: '',
            restrict_reference_items: {
                [RestrictionType.SUB_STOCK]: [],
                [RestrictionType.PRODUCT]: [],
                [RestrictionType.MANUFACTURER]: [],
                [RestrictionType.STOCK_OWNER]: []
            },
            restrict_type_icon: {
                [RestrictionType.SUB_STOCK]: '$substock',
                [RestrictionType.PRODUCT]: '$product',
                [RestrictionType.MANUFACTURER]: '$manufacturer',
                [RestrictionType.STOCK_OWNER]: '$owners'
            },
        }),
        computed: {
            validAll: function () {
                return this.valid && this.validAttachments;
            },
            showLocationHelper: function () {
                return this.form.restrict_type === RestrictionType.STOCK_LOCATION;
            },
            locationHelperItems: function () {
                const items = this.formRender.stock_locations.autocomplete.items;
                if (this.showLocationHelper && this.locationHelperValue && items && items.length > 0) {
                    try {
                        return this.formRender.stock_locations.autocomplete.items.filter(item => item.text.match(this.locationHelperValue));
                    } catch (e) {
                        window.console.warn(e);
                        return [];
                    }
                } else {
                    return [];
                }
            }
        },
        watch: {
            'form.restrict_type': function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    if (newValue === RestrictionType.STOCK_LOCATION) {
                        this.formRender.stock_locations.show = true;
                        if (this.formRender.stock_locations.autocomplete.items.length === 0) {
                            this.loadLocations();
                        }
                    } else {
                        this.formRender.stock_locations.show = false;
                    }
                    if (newValue !== RestrictionType.NONE && newValue !== RestrictionType.STOCK_LOCATION) {
                        this.formRender.restrict_reference_id.show = true;
                        if (this.restrict_reference_items[newValue].length === 0) {
                            this.loadRestrictions();
                        } else {
                            this.$set(this.formRender.restrict_reference_id.autocomplete, 'items', this.restrict_reference_items[newValue]);
                        }
                    } else {
                        this.formRender.restrict_reference_id.show = false;
                    }
                    this.formRender.restrict_reference_id.icon = this.restrict_type_icon[newValue];
                }
            },
            'form.stock_id': function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    this.stockIdChanged();
                }
            }
        },
        createdOrActivated: function () {
            this.formRender.restrict_reference_id.show = false;

            if (this.productId !== undefined) {
                this.form.restrict_type = RestrictionType.PRODUCT;
                this.form.restrict_reference_id = this.productId;
                this.formRender.restrict_reference_id.show = true;
            }
        },
        mounted: function () {
            formMountedProcedure(this);
        },
        methods: {
            stockIdChanged: function () {
                if (this.form.restrict_type === RestrictionType.STOCK_LOCATION) {
                    this.loadLocations();
                }
                if (this.form.restrict_type === RestrictionType.SUB_STOCK) {
                    this.loadSubStock(this.form.restrict_type);
                }
            },
            loadLocations: function () {
                if (this.form.stock_id !== null) {
                    this.$set(this.formRender.stock_locations, 'loading', true);
                    StockAPI.getAllLocationsAllPages(this.form.stock_id)
                        .then(response => {
                            this.$set(this.formRender.stock_locations.autocomplete, 'items', response.data.items.map(el => ({
                                text: locationLabel(el),
                                value: el.id
                            })));
                        }).finally(() => {
                            this.$set(this.formRender.stock_locations, 'loading', false);
                        });
                }
            },
            loadRestrictions: function () {
                const type = this.form.restrict_type;
                switch (type) {
                case RestrictionType.SUB_STOCK:
                    this.loadSubStock(type);
                    break;
                case RestrictionType.PRODUCT:
                    this.loadProducts(type);
                    break;
                case RestrictionType.MANUFACTURER:
                    this.loadManufacturers(type);
                    break;
                case RestrictionType.STOCK_OWNER:
                    this.loadStockOwners(type);
                    break;
                }
            },
            loadSubStock: function (type) {
                if (this.form.stock_id !== null) {
                    this.$set(this.formRender.restrict_reference_id, 'loading', true);
                    StockAPI.getAllSubstockPages(this.form.stock_id)
                        .then(response => {
                            this.setRestrictionItems(type, response.data.items.map(el => ({
                                text: el.name,
                                value: el.id
                            })));
                        }).finally(() => {
                            this.$set(this.formRender.restrict_reference_id, 'loading', false);
                        });
                }
            },
            loadProducts: function (type) {
                this.$set(this.formRender.restrict_reference_id, 'loading', true);
                ProductAPI.getVisibleAllPages()
                    .then(response => {
                        this.setRestrictionItems(type, response.data.items.map(el => ({
                            ...el,
                            value: el.id
                        })));
                    }).finally(() => {
                        this.$set(this.formRender.restrict_reference_id, 'loading', false);
                    });
            },
            loadManufacturers: function (type) {
                this.$set(this.formRender.restrict_reference_id, 'loading', true);
                ManufacturerAPI.getAllPages()
                    .then(response => {
                        this.setRestrictionItems(type, response.data.items.map(el => ({
                            text: el.name,
                            value: el.id
                        })));
                    }).finally(() => {
                        this.$set(this.formRender.restrict_reference_id, 'loading', false);
                    });
            },
            loadStockOwners: function (type) {
                this.$set(this.formRender.restrict_reference_id, 'loading', true);
                StockAPI.getAllOwners()
                    .then(response => {
                        this.setRestrictionItems(type, response.data.map(el => ({
                            text: el.name,
                            value: el.id
                        })));
                    }).finally(() => {
                        this.$set(this.formRender.restrict_reference_id, 'loading', false);
                    });
            },
            setRestrictionItems: function (type, items) {
                this.restrict_reference_items[type] = items;
                if (this.form.restrict_type === type) {
                    this.$set(this.formRender.restrict_reference_id.autocomplete, 'items', items);
                } else {
                    this.$set(this.formRender.restrict_reference_id.autocomplete, 'items', this.restrict_reference_items[this.form.restrict_type]);
                }
            },
            locationHelperSubmit: function () {
                this.form.stock_locations = this.locationHelperItems.map(item => item.value);
            },
            submit: function () {
                if (!this.validAll) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                TaskStockTakingAPI.create(this.form)
                    .then(response => {
                        this.snack('tasks.stockTaking.createDone');
                        this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                        this.$router.push(taskLinks[taskTypes.STOCK_TAKING] + '/' + getIdFromLocation(response));
                    }).catch(setFormErrors.bind(this))
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
