import {EventBus} from "@/service/EventBus";
import {BarcodeAPI} from "@/api/BarcodeAPI";
import {CodeType} from "@/enum/code_type";
import {ProductAPI} from "@/api/ProductAPI";

export default function registerGlobalListeners() {

    const getVueFromElement = el => el.__vue__ || getVueFromElement(el.parentNode);

    window.onBarcodeRead = data => {
        // Send to active listeners
        EventBus.$emit('onBarcodeRead', data);

        // Set to focused input
        const el = document.activeElement;
        if (el.nodeName === 'INPUT') {
            const vueEl = getVueFromElement(el);
            if (vueEl.$options._componentTag === 'v-autocomplete') {
                vueEl.$emit('update:search-input', data);
            } else {
                vueEl.$emit('input', data);
            }
        }
    };

    /**
     * Allows to import images in batch
     * @param source {string} CSV in format: EAN;url\nEAN;url...
     */
    window.onImageImport = source => {
        source.split('\n')
            .map(item => item.split(';'))
            .forEach(([ean, url]) => {
                BarcodeAPI.decode(ean)
                    .then(response => {
                        const barcodeInfo = response.data;
                        if (barcodeInfo.type === CodeType.PRODUCT_INSTANCE) {
                            ProductAPI.createExternalImage(barcodeInfo.object_info.product.id, url, true);
                        }
                    });
            });
    };

    /**
     * Locally stores all product images which are using an external link.
     */
    window.onStoreAllImagesLocally = () => {
        ProductAPI.getAllPages().then(response => {
            response.data.items.forEach(product => {
                ProductAPI.getAllImages(product.id).then(response => {
                    response.data.forEach(image => {
                        if (image.type === 'external') {
                            ProductAPI.storeExternalImage(product.id, image.id);
                        }
                    });
                });
            });
        });
    };

    let reading = false;
    let readerCode = '';
    let timeout = null;
    document.addEventListener('keyup', function (e) {
        if (e.code === 'AltLeft') {
            if (!reading) {
                // This is probably barcode reader input, start reading
                reading = true;
                // Set timeout on first read to reset code after some time
                timeout = setTimeout(() => {
                    reading = false;
                    readerCode = '';
                }, 400);
            }
            readerCode += e.key;
        } else if (reading && e.code === 'Enter') {
            window.onBarcodeRead(readerCode);
            reading = false;
            readerCode = '';
            clearTimeout(timeout);
        }
    });
}
