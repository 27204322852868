<template>
  <v-container fluid>
    <v-row>
      <template v-if="!$vuetify.breakpoint.smAndDown">
        <v-col
          v-show="hasImage"
          cols="2"
          md="2"
        >
          <TaskItemDetailImages
            :product-id="product.id"
            lazy-load-trigger
            :product-name="product.name"
            just-one-image
            @has-image="val => {hasImage = val}"
          />
        </v-col>
        <v-divider
          v-show="hasImage"
          vertical
        />
      </template>
      <v-col>
        <v-layout
          align-center
          column
          wrap
          class="py-3"
          :class="{'raiseAboveToast': raiseBottomSheet}"
        >
          <v-flex class="title mx-4">
            {{ quantityCurrent(item, locationId) }}
            <span class="caption">{{ product | productMeasureLabel }}</span>&nbsp;
            <span class="font-weight-bold">{{ product.name }}</span> - {{ product.model }}
            <span class="caption">{{ item.instance | instanceTypeSpecialLabel }}</span>
          </v-flex>
          <span
            v-if="isAllowedToChangePrices"
            class="my-1"
          >
            <TaskItemDetailPrice
              :item="item"
              :prices="prices"
              :task-type="taskInfo.details.type"
            />
          </span>
          <span
            v-if="isAllowedToChangeQuantity"
            class="my-2"
          >
            <TaskItemDetailQuantity
              :item="item"
              :location-id="locationId"
              :task-type="taskInfo.details.type"
              :default-quantity="quantityCurrent(item, locationId)"
              :stock-loading-strict-mode="taskInfo.details.strict_mode || taskInfo.details.transfer_mode"
              :inventory-empty="inventoryEmpty"
              :source-location-id="activeLocationId"
              :task-shipping-type="taskInfo.details.shipping_type"
            />
          </span>
          <span
            v-if="isAllowedToReturn"
            class="my-1"
          >
            <TaskItemDetailReturn
              :item="item"
              :location-id="locationId"
              :card-type="cardType"
              :quantity-current="quantityCurrent"
              @back="(amount, locId, callback) => {$emit('back', item, amount, locId, callback)}"
            />
          </span>
          <span
            v-if="isAllowedToRemove"
            class="my-2"
          >
            <TaskItemDetailRemove
              :item="item"
              @remove="removedItem => {$emit('remove', removedItem, locationId)}"
            />
          </span>
          <span
            v-if="showPickedFrom"
            class="my-1"
          >
            <TaskItemDetailLocations
              :item="item"
              type="picked"
            />
          </span>
          <span
            v-if="showSourceLocations && (item.locations || canLoadLocations)"
            class="my-1"
          >
            <TaskItemDetailLocations
              :item="item"
              type="pick"
            />
          </span>
          <span
            v-if="showAlreadyPlacedAt"
            class="my-1"
          >
            <TaskItemDetailLocations
              :item="item"
              type="placed"
            />
          </span>
          <span
            v-if="showAllowedLocations"
            class="my-1"
          >
            <TaskItemDetailLocations
              :item="item"
              type="put"
              :card-type="cardType"
              :item-on-wrong-location="itemOnWrongLocation"
            />
          </span>
          <span>
            <v-flex class="mt-2">
              <v-btn
                text
                color="secondary"
                @click="showDetails = !showDetails"
              >
                <v-icon :class="{'upsideDown': showDetails}">
                  $showMore
                </v-icon>
                {{ $t('tasks.itemsCard.moreDetails') }}
              </v-btn>
            </v-flex>
            <v-flex>
              <v-expand-transition>
                <div
                  v-show="showDetails"
                  class="mt-2"
                >
                  <div>
                    <span class="text-caption">{{ $t('products.model') + ': ' }}</span>
                    {{ product.model }}
                  </div>
                  <div>
                    <span class="text-caption">{{ $t('products.manufacturer.label') + ': ' }}</span>
                    {{ product.manufacturer.name }}
                  </div>
                  <TaskItemDetailBarcodes
                    :product="product"
                    :instance="item.instance"
                  />
                  <TaskItemDetailImages
                    :product-id="product.id"
                    :lazy-load-trigger="imagesLoaded"
                    :product-name="product.name"
                  />
                </div>
              </v-expand-transition>
            </v-flex>
          </span>
        </v-layout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
    import {TaskItemsCardType} from '@/enum/task_items_card_type';
    import {taskTypes} from "@/enum/task_type";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import {TaskTypeMixin} from "@/app/mixins/TaskTypeMixin";
    import TaskItemDetailQuantity from "@/app/tasks/components/taskItemsCard/TaskItemDetailQuantity.component";
    import TaskItemDetailPrice from "@/app/tasks/components/taskItemsCard/TaskItemDetailPrice.component";
    import TaskItemDetailLocations from "@/app/tasks/components/taskItemsCard/TaskItemDetailLocations.component";
    import TaskItemDetailImages from "@/app/tasks/components/taskItemsCard/TaskItemDetailImages.component";
    import TaskItemDetailRemove from "@/app/tasks/components/taskItemsCard/TaskItemDetailRemove.component";
    import TaskItemDetailReturn from "@/app/tasks/components/taskItemsCard/TaskItemDetailReturn.component";
    import TaskItemDetailBarcodes from "@/app/tasks/components/taskItemsCard/TaskItemDetailBarcodes.component";
    import {TaskMoveProductsType} from "@/enum/task_move_products_type";
    import {ACLMixin} from "@/app/mixins/ACLMixin";

    export default {
        name: "TaskItemDetails",
        components: {
            TaskItemDetailReturn,
            TaskItemDetailRemove,
            TaskItemDetailImages,
            TaskItemDetailLocations,
            TaskItemDetailPrice,
            TaskItemDetailQuantity,
            TaskItemDetailBarcodes
        },
        mixins: [TaskStateMixin, TaskAssignMixin, TaskTypeMixin, ACLMixin],
        props: {
            item: {
                type: Object,
                default: () => ({})
            },
            cardType: {
                type: Number,
                default: 0
            },
            locationId: {
                type: Number,
                default: null
            },
            prices: {
                type: Object,
                default: () => ({})
            },
            quantityCurrent: {
                type: Function,
                default: () => () => {
                }
            },
            taskInfo: {
                type: Object,
                default: () => ({})
            },
            inventoryEmpty: {
                type: Boolean,
                default: true
            },
            canLoadLocations: {
                type: Boolean,
                default: false
            },
            itemOnWrongLocation: {
                type: Boolean,
                default: false
            },
            activeLocationId: {
                type: Number,
                default: null
            },
            activeLocationSide: {
                type: String,
                default: null
            }
        },
        data: () => ({
            imagesLoaded: false,
            TaskItemsCardType: TaskItemsCardType,
            showDetails: false,
            hasImage: false
        }),
        computed: {
            product: function () {
                return this.item.instance.product;
            },
            raiseBottomSheet: function () {
                return this.$store.getters['snackbar/isVisible'];
            },
            showSourceLocations: function () {
                return (this.cardType === TaskItemsCardType.TO_MOVE || this.cardType === TaskItemsCardType.ASSIGNMENT)
                    && !this.isType(taskTypes.STOCK_TAKING);
            },
            showAllowedLocations: function () {
                if (this.isType(taskTypes.MOVE_PRODUCTS)) {
                    return [TaskMoveProductsType.DISTRIBUTE, TaskMoveProductsType.MANYTOMANY].includes(this.taskInfo.movementType)
                        && (this.cardType === TaskItemsCardType.ASSIGNMENT
                            || this.cardType === TaskItemsCardType.IN_INVENTORY);
                }
                if (this.isType(taskTypes.STOCK_LOADING)) {
                    return this.cardType === TaskItemsCardType.ASSIGNMENT
                        || this.cardType === TaskItemsCardType.TO_MOVE
                        || this.cardType === TaskItemsCardType.IN_INVENTORY
                        || (this.cardType === TaskItemsCardType.MOVED && this.itemOnWrongLocation);
                }
                if (this.isAnyOfTypes([taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET])) {
                    return this.cardType === TaskItemsCardType.MOVED && this.itemOnWrongLocation;
                }
                return this.isType(taskTypes.SUBSTOCK_TRANSFER)
                    && (this.cardType === TaskItemsCardType.IN_INVENTORY || this.cardType === TaskItemsCardType.ASSIGNMENT);
            },
            showPickedFrom: function () {
                return this.isAnyOfTypes([taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET, taskTypes.MOVE_PRODUCTS, taskTypes.SUBSTOCK_TRANSFER])
                    && (this.cardType === TaskItemsCardType.IN_INVENTORY || this.cardType === TaskItemsCardType.MOVED);
            },
            showAlreadyPlacedAt: function () {
                return this.isAnyOfTypes([taskTypes.STOCK_LOADING, taskTypes.MOVE_PRODUCTS, taskTypes.SUBSTOCK_TRANSFER]);
            },
            isAllowedToChangePrices: function () {
                return (this.isWaitingForApproval || (this.isStorekeeper && this.isChief))
                    && (this.isAnyOfTypes([taskTypes.STOCK_LOADING, taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET]))
                    && this.prices[this.item.id] !== undefined
                    && this.cardType !== TaskItemsCardType.ASSIGNMENT;
            },
            isAllowedToChangeQuantity: function () {
                let isLocationSource = true;
                if (this.activeLocationSide === 'pick') {
                    isLocationSource = true;
                } else if (this.activeLocationSide === 'put') {
                    isLocationSource = false;
                } else {
                    if (this.isAnyOfTypes([taskTypes.MOVE_PRODUCTS, taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET, taskTypes.SUBSTOCK_TRANSFER])) {
                        const destinationLocation = this.taskInfo.details.destination_location || this.taskInfo.details.target_location;
                        const sourceLocation = this.taskInfo.details.source_location;
                        if (destinationLocation && destinationLocation.id === this.activeLocationId) {
                            isLocationSource = false;
                        } else if (sourceLocation && sourceLocation.id !== this.activeLocationId) {
                            isLocationSource = false;
                        } else if (!sourceLocation) {
                            const sourceLocIds = this.item.locations.map(loc => loc.stock_location.id);
                            sourceLocIds.push(...this.item.source_locations.map(loc => loc.stock_location_id));
                            if (!sourceLocIds.includes(this.activeLocationId)) {
                                isLocationSource = false;
                            }
                        }
                    }
                }
                return this.isInProgress
                    && this.assignedToCurrentUser
                    && (this.isAnyOfTypes([taskTypes.STOCK_LOADING, taskTypes.STOCK_TAKING])
                        || (this.isAnyOfTypes([taskTypes.MOVE_PRODUCTS, taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET, taskTypes.SUBSTOCK_TRANSFER])
                            && this.activeLocationId
                            && ((this.cardType === TaskItemsCardType.IN_INVENTORY && isLocationSource)
                                || (this.cardType === TaskItemsCardType.MOVED && !isLocationSource))))
                    && this.cardType !== TaskItemsCardType.ASSIGNMENT
                    && this.cardType !== TaskItemsCardType.TO_MOVE;
            },
            isAllowedToReturn: function () {
                // may be possible in STOCK_LOADING, STOCK_PICKING, STOCK_PICKING_SET, MOVE_PRODUCTS and SUBSTOCK_TRANSFER
                if (this.cardType === TaskItemsCardType.ASSIGNMENT) {
                    return false;
                }
                if (this.isType(taskTypes.STOCK_TAKING)) {
                    return false;
                } else if (this.isType(taskTypes.SUBSTOCK_TRANSFER)) {
                    if (this.cardType === TaskItemsCardType.IN_INVENTORY) {
                        if (!this.item.quantity_in_source_user_inventory > 0) {
                            return false; // TODO display message why (picked from destination)
                        }
                    }
                } else if (this.isType(taskTypes.STOCK_LOADING)) {
                    if (this.cardType === TaskItemsCardType.IN_INVENTORY) {
                        return false;
                    }
                }
                return this.isInProgress && this.assignedToCurrentUser && this.cardType !== TaskItemsCardType.TO_MOVE;
            },
            isAllowedToRemove: function () {
                return this.isInProgress
                    && this.assignedToCurrentUser
                    && this.isAnyOfTypes([taskTypes.STOCK_LOADING, taskTypes.STOCK_TAKING])
                    && this.cardType !== TaskItemsCardType.ASSIGNMENT
                    && this.cardType !== TaskItemsCardType.TO_MOVE;
            }
        },
        watch: {
            showDetails: function (value) {
                value && (this.imagesLoaded = true);
            }
        }
    };
</script>

<style scoped lang="sass">
.upsideDown
    transform: rotate(180deg)
</style>
