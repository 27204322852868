<template>
  <div>
    <x-toolbar
      :title="pageTitle"
      :tabs="hasTabs"
      :full-width="true"
      :extension="hasTabs"
    >
      <template
        v-if="hasTabs"
        #extension
      >
        <v-tabs
          v-model="tab"
          background-color="primary lighten-1"
          grow
        >
          <v-tabs-slider
            color="accent"
          />
          <v-tab
            v-for="item of tabItems"
            :key="item.label"
          >
            {{ $t('tasks.externalOrder.navigation.' + item.label) }}
          </v-tab>
        </v-tabs>
      </template>
    </x-toolbar>
    <v-alert
      v-if="hasCarrierErrors"
      type="error"
    >
      <span class="text-caption">
        {{ $t('tasks.preparePackage.shipments.errorMessages') + ': ' }}
      </span>
      {{ (details.carrier_api_error_messages || []).join(', ') }}
    </v-alert>
    <template v-if="details">
      <template v-if="assignable">
        <TaskAssignButton
          :loading="loading"
          @assign="assign"
        />
      </template>
      <template v-if="hasTabs">
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item of tabItems"
            :key="item.label"
            eager
          >
            <component
              :is="item.component"
              :task-info="taskInfo"
              :items="items"
              :item-instances="itemInstances"
            />
          </v-tab-item>
        </v-tabs-items>
      </template>
      <template v-else>
        <ExternalOrderAssignment
          :task-info="taskInfo"
        />
      </template>
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>


<script>
    import {BuyerAPI} from "@/api/BuyerAPI";
    import {ProductAPI} from "@/api/ProductAPI";
    import {TaskExternalOrderAPI as API} from "@/api/TaskExternalOrderAPI";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {TaskFetchItemsMixin} from "@/app/mixins/TaskFetchItemsMixin";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import TaskAssignButton from "@/app/tasks/components/TaskAssignButton.component";
    import {InstanceType} from "@/enum/instance_type";
    import {taskNames, taskTypes} from "@/enum/task_type";
    import {APIFilterOP, APIFilters} from "@/service/APIFilters";
    import {assign} from "@/utils/object";
    import {tabTitle} from "@/utils/string";
    import ExternalOrderAssignment from "@/app/tasks/externalOrder/components/ExternalOrderAssignment.component";
    import ExternalOrderOrder from "@/app/tasks/externalOrder/components/ExternalOrderOrder.component";
    import ExternalOrderOverview from "@/app/tasks/externalOrder/components/ExternalOrderOverview.component";
    import {TaskMoveProductsType} from "@/enum/task_move_products_type";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import {BarcodeListenerMixin} from "@/app/mixins/BarcodeListenerMixin";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";

    export default {
        name: "ExternalOrderShow",
        components: {TaskAssignButton, ExternalOrderAssignment},
        mixins: [
            ACLMixin,
            TaskStateMixin,
            TaskAssignMixin,
            TaskFetchItemsMixin,
            RouteParamsMapperMixin,
            BarcodeListenerMixin,
            EventsListenerMixin
        ],
        data: () => ({
            API: API,
            loading: false,
            type: taskTypes.EXTERNAL_ORDER,
            details: null,
            items: [],
            itemInstances: {},
            tab: null
        }),
        computed: {
            typeName: function () {
                return this.$t(taskNames[this.type]);
            },
            events: function () {
                return {
                    'onBarcodeRead': this.activateTab
                };
            },
            pageTitle: function () {
                const title = '#' + this.taskId + ' ' + this.typeName;
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            },
            taskInfo: function () {
                return {
                    details: this.details,
                    tab: this.tab,
                    taskId: this.taskId,
                    movementType: TaskMoveProductsType.COLLAPSE
                };
            },
            tabItems: function () {
                return [{
                    label: 'assignment',
                    component: ExternalOrderAssignment
                }]
                    .concat([{
                        label: 'order',
                        component: ExternalOrderOrder
                    }])
                    .concat(
                        (this.isChief && this.isOpenState)
                            ? [{
                                label: 'finish',
                                component: ExternalOrderOverview
                            }] : []); // TODO refactor these in all tasks
            },
            hasTabs: function () {
                return this.tabItems.length > 1;
            },
            hasCarrierErrors: function () {
                return this.details && this.details.carrier_api_error_messages && this.details.carrier_api_error_messages.length > 0;
            },
            /**
             * @override TaskAssignMixin
             */
            assignable: function () {
                // TODO allow assign of unassigned EO?
                return !this.assignedToAnybody && this.isAssignableState && this.isChief &&
                    !(this.details.stock_picking_task_ids && this.details.stock_picking_task_ids.length > 0);
            }
        },
        createdOrActivated: function (lifeCycleHook) {
            this.getTask(lifeCycleHook)
                .then(() => {
                    if (lifeCycleHook === this.LifeCycleHook.CREATED) {
                        this.fetchItems({initial: true})
                            .then(() => {
                                this.fetchInstances();
                            }).catch(this.snack);
                    } else {
                        this.fetchItems().catch(this.snack);
                    }
                }).catch(this.snack);
        },
        methods: {
            getTask: function (lifeCycleHook) {
                return API.get(this.taskId, true)
                    .then(response => {
                        if (lifeCycleHook === this.LifeCycleHook.CREATED) {
                            this.details = response.data;
                        } else {
                            assign(this.details, response.data);
                        }
                        BuyerAPI.get(this.details.buyer_id)
                            .then(response => {
                                this.$set(this.details, 'buyer', response.data);
                            });
                    }).catch(err => {
                        this.snack(err);
                        this.$router.push('/');
                    });
            },
            fetchInstances: function () {
                this.items.forEach(item => {
                    if (item.product_instance_id !== null) {
                        this.$set(this.itemInstances, item.product_id, item.product_instance_id);
                        item.selectedInstance = item.product_instance_id;
                    } else {
                        let promise;
                        const apiFilter = [];
                        let sort = null;
                        if (item.product_instance_type_id !== null) {
                            apiFilter.push({
                                [APIFilterOP.EQUALS]: {
                                    'type.instance_type_id': item.product_instance_type_id
                                }
                            });
                        } else if (item.product.can_have_batch) {
                            sort = {expire_date: 'ASC'};
                            promise = ProductAPI.getAllBatches(item.product_id, {sort: APIFilters.makeSort(sort)})
                                .then(response => {
                                    response.data.items = response.data.items.map(batch => ({
                                        product_id: item.product_id,
                                        id: batch.product_instance_id,
                                        type: InstanceType.BATCH,
                                        batch_code: batch.code,
                                        manufactured_date: batch.manufactured_date,
                                        expire_date: batch.expire_date
                                    }));
                                    return response;
                                });
                        } else if (item.product.can_have_serial_number) {
                            apiFilter.push({
                                [APIFilterOP.EQUALS]: {
                                    'type.name': InstanceType.SERIAL_NUMBER
                                }
                            });
                            // default endpoint sort is id: DESC, we want to take the oldest serials first
                            sort = {id: 'ASC'};
                        } else {
                            apiFilter.push({
                                [APIFilterOP.EQUALS]: {
                                    'type.name': InstanceType.NORMAL
                                }
                            });
                        }
                        if (!promise) {
                            const params = {filter: APIFilters.makeFilter(apiFilter)};
                            if (sort) {
                                params.sort = APIFilters.makeSort(sort);
                            }
                            promise = ProductAPI.getAllInstancesAllPages(item.product_id, params);
                        }
                        promise
                            .then(response => {
                                this.$set(this.itemInstances, item.product_id, response.data.items.map(el => ({
                                    text: this.$options.filters.instanceTypeLabel(el),
                                    value: el.id
                                })));
                                item.selectedInstance = response.data.items[0].id;
                            }).catch(this.snack);
                    }
                });
            }
        }
    };
</script>

<style scoped>

</style>
