<template>
  <div>
    <x-data-table
      :headers="tableHeaders"
      :items="items"
      :loading="loading"
    >
      <!-- TODO <template #item.selectedInstance="{ item }">
        <td
          v-if="assignedToCurrentUser && (item.product)"
        >
          <v-select
            v-model="item.selectedInstance"
            :items="itemInstances[item.product_id]"
          />
        </td>
        <td v-else>
          {{ $t('tasks.externalOrder.default') }}
        </td>
      </template> -->
    </x-data-table>
  </div>
</template>

<script>
    import {ProductAPI} from "@/api/ProductAPI";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import {TaskExternalOrderAPI as API} from "@/api/TaskExternalOrderAPI";
    import {createHeaders} from "@/utils/table";
    import {externalOrderTable} from "@/app/tasks/externalOrder/definitions/externalOrder.table";

    export default {
        name: "ExternalOrderOrder",
        mixins: [TaskStateMixin, TaskAssignMixin],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            },
            items: {
                type: Array,
                default: () => []
            },
            itemInstances: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            loading: false,
            prices: {},
            API: API
        }),
        computed: {
            tableHeaders: () => createHeaders(externalOrderTable, 'tasks.externalOrder.', false)
        },
        watch: {
            items: {
                handler: function () {
                    // this.loading = true;
                    const promises = [];
                    this.items
                        .filter(item => item.product_instance_type_id)
                        .forEach(item => {
                            promises.push(ProductAPI.getCustomInstanceType(item.product_instance_type_id)
                                .then(response => {
                                    this.$set(item, 'product_instance_type_name', response.data.name);
                                })
                            );
                        });
                    Promise.all(promises).catch(this.snack).finally(() => {
                        // this.loading = false;
                    });
                },
                immediate: true
            }
        }
    };
</script>

<style scoped>

</style>
