/**
 * Replaces ':key' in pathWithPlaceholders with values from parameters, returns the result
 * @param pathWithPlaceholders {string}
 * @param parameters {{
 *     key: value
 * }}
 * @returns {string}
 */
function buildPath(pathWithPlaceholders, parameters) {
    let routePath = pathWithPlaceholders.replace(/\([^/]+\)/g, '');
    const matches = routePath.match(/(:[a-zA-Z]+\/)|(:[a-zA-Z]+$)/g);
    if (matches !== null) {
        for (const match of matches) {
            const matchParam = match.replace(/\//, '').replace(/:/, '');
            routePath = routePath.replace(match, parameters[matchParam] + '/');
        }
    }
    return routePath;
}

/**
 * Returns path of direct parent, including real parameters
 * @param route
 * @returns {string}
 */
function routeBackTo(route) {
    if (typeof route.meta.getParent === 'function') {
        const parent = route.meta.getParent();
        if (parent !== '/') {
            return buildPath(parent.path, route.params);
        }
    }
    return '/';
}

/**
 * Accept Axios response object, returns id from the end of Locations string, or null if not present.
 * @param response
 * @returns {null|string}
 */
function getIdFromLocation(response) {
    if (response.headers && response.headers.location) {
        return response.headers.location.split('/').pop();
    } else {
        return null;
    }
}

export {buildPath, routeBackTo, getIdFromLocation};
