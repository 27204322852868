import i18n from "@/service/lang/i18n";

export default {

    /**
     * @return {boolean|string|VueI18n.LocaleMessages}
     */
    required: v => !!v || i18n.t('form.required'),

    /**
     * @param max
     * @returns {function (*=): (boolean|*|string | VueI18n.LocaleMessages)}
     */
    maxLen: max => v => (!v || (v && v.length <= max)) || i18n.t('form.maxLen', [max]),

    /**
     * @param min
     * @return {function(*=): (boolean|*|string | VueI18n.LocaleMessages)}
     */
    minLen: min => v => (!v || (v && v.length >= min)) || i18n.t('form.minLen', [min]),

    /**
     * @param pattern {RegExp}
     * @returns {function (*=): (*|string | VueI18n.LocaleMessages)}
     */
    pattern: pattern => v => (!v || pattern.test(v) || i18n.t('form.pattern')),

    /**
     * @return {boolean|string | VueI18n.LocaleMessages}
     */
    positiveInteger: v => Number.parseInt(v, 10) > 0 || i18n.t('form.positiveInteger'),

    /**
     * @param min {Number}
     * @param max {Number}
     * @returns {function (*=): (*|string | VueI18n.LocaleMessages)}
     */
    range: (min, max) => v => (Number.parseFloat(v) >= min && Number.parseFloat(v) <= max)
        || i18n.t('form.range', [min, max])
};
