<template>
  <div>
    <x-toolbar />
    <div
      class="d-flex justify-center"
    >
      <v-card
        :min-width="$vuetify.breakpoint.smAndUp ? 590 : 0"
      >
        <v-container>
          <v-list>
            <v-subheader class="text-uppercase">
              {{ $t('base.cache.contents') }}
            </v-subheader>
            <v-list-item
              v-for="entry of entries"
              :key="entry.path"
            >
              <v-list-item-icon
                v-show="$vuetify.breakpoint.smAndUp"
              >
                <v-icon :style="entry.path === 'allowedLocationIds' ? 'transform: rotate(90deg)' : ''">
                  {{ entry.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content
                :class="{'text--disabled': !counts[entry.path]}"
              >
                <v-list-item-title>
                  <span class="font-weight-bold mr-1">
                    {{ counts[entry.path] }}x
                  </span>
                  {{ $t('base.cache.' + entry.path) }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ sizes[entry.path] * counts[entry.path] | humanizeSize }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  :small="$vuetify.breakpoint.xsOnly"
                  :disabled="!counts[entry.path]"
                  @click="clear(entry.path)"
                >
                  <span v-show="$vuetify.breakpoint.smAndUp">
                    {{ $t('base.cache.clear') }}
                  </span>
                  <span v-show="$vuetify.breakpoint.xsOnly">
                    <v-icon>$cacheDelete</v-icon>
                  </span>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-btn
              block
              class="my-2"
              @click="clearAll"
            >
              {{ $t('base.cache.clearAll') }}
            </v-btn>
          </v-list>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
    import {CacheConfiguration} from "@/service/cache/CacheConfiguration";
    import {IndexedDB} from "@/service/cache/IndexedDB";

    export default {
        name: "CacheManager",
        data: () => ({
            counts: {},
            sizes: {}
        }),
        computed: {
            entries: function () {
                return Object.entries(CacheConfiguration).map(([key, value]) => ({
                    path: key,
                    icon: value.icon
                }));
            }
        },
        createdOrActivated: function () {
            for (const key of Object.keys(CacheConfiguration)) {
                IndexedDB.getCount(key).then(count => {
                    this.$set(this.counts, key, count);
                });
                IndexedDB.getRoughSizeOfOne(key).then(size => {
                    this.$set(this.sizes, key, size);
                });
            }
        },
        methods: {
            clear: function (path) {
                IndexedDB.clearAll(path);
                this.counts[path] = 0;
                this.sizes[path] = 0;
            },
            clearAll: function () {
                for (const key of Object.keys(CacheConfiguration)) {
                    this.clear(key);
                }
            }
        }
    };
</script>

<style scoped>

</style>
