<template>
  <div>
    <div>
      <v-dialog
        v-model="addBarcodeDialog"
        width="800"
      >
        <v-card>
          <v-card-title>
            {{ $t('products.instances.barcodes.create.title', [product.name]) }}
          </v-card-title>
          <ProductInstanceBarcodeCreateUpdateComponent
            class="pa-2"
            :product-id="product.id"
            :product-name="product.name"
            :instance-id="instance.id"
            :is-in-modal="true"
            @barcodeUpdated="barcodeUpdated"
          />
        </v-card>
      </v-dialog>
      <BarcodePrintDialog
        v-if="modalItem !== null"
        :show.sync="barcodeModal"
        :barcode-count.sync="barcodeCount"
        :print-type.sync="printType"
        :title="$t('products.instances.barcodes.printHowMuch', [modalItem.code])"
        :confirm-text="$t('products.instances.barcodes.printMany')"
        @print-barcode="reallyPrintBarcode"
      />
      <v-layout
        class="justify-center mt-1 ml-0"
        row
      >
        <span
          class="text-caption"
        >
          {{ $t('products.instances.barcodes.titleSimple') + ':' }}
        </span>
        <v-layout
          class="justify-center ml-1"
          column
        >
          <v-flex
            v-for="(item, key) in items"
            :key="key"
          >
            {{ getDescription(item) }} -
            <code>{{ item.code }}</code>
            <v-icon
              v-if="items.length !== 1"
              class="ml-2"
              color="accent darken-2"
              @click="printBarcode(item)"
            >
              $printItem
            </v-icon>
          </v-flex>
          <v-flex
            v-if="loading"
          >
            <v-progress-circular indeterminate />
          </v-flex>
          <v-flex>
            <v-btn
              v-if="items.length === 1"
              text
              color="accent darken-2"
              @click="printBarcode(items[0])"
            >
              <v-icon
                small
                class="mr-2"
              >
                $printItem
              </v-icon>
              {{ $t('products.instances.barcodes.print') }}
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn
              text
              class="mb-3"
              color="accent darken-2"
              @click="addBarcodeDialog = true"
            >
              {{ '+ ' + $t('products.instances.barcodes.create.titleSimple') }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-layout>
    </div>
  </div>
</template>

<script>
    import {ProductAPI as API} from "@/api/ProductAPI";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import ProductInstanceBarcodeCreateUpdateComponent
        from "@/app/products/instances/components/ProductInstanceBarcodeCreateUpdate.component";
    import BarcodePrintDialog from "@/app/components/BarcodePrintDialog.component";
    import {PrintType} from "@/enum/print_type";
    import {ProductBarcodePrintMixin} from "@/app/mixins/ProductBarcodePrintMixin";
    import {InstanceType} from "@/enum/instance_type";

    export default {
        name: "TaskItemDetailBarcodes",
        components: {ProductInstanceBarcodeCreateUpdateComponent, BarcodePrintDialog},
        mixins: [ACLMixin, ProductBarcodePrintMixin],
        props: {
            product: {
                type: Object,
                default: () => ({})
            },
            instance: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            items: [],
            loading: true,
            addBarcodeDialog: false,
            barcodeModal: false,
            modalItem: null,
            barcodeCount: 1,
            printType: PrintType.PDF
        }),
        createdOrActivated: function () {
            this.getData();
        },
        computed: {
            instanceId: function () {
                return this.instance.id;
            },
            isBaseInstance: function () {
                if (!this.instance.type) {
                    return true;
                }
                return Object.values(InstanceType).includes(this.instance.type.name);
            }
        },
        methods: {
            getData: function () {
                this.loading = true;
                const instanceId = this.instance.id;
                API.getAllInstanceBarcodes(this.product.id, instanceId)
                    .then(response => {
                        this.items = response.data;
                    }).catch(this.snack)
                    .finally(() => this.loading = false);

            },
            getDescription: function (barcode) {
                if (barcode.description === null)
                    return barcode.quantity + this.$options.filters.productMeasureLabel(this.instance.product);
                return barcode.quantity + ' ' + barcode.description;
            },
            barcodeUpdated: function () {
                this.addBarcodeDialog = false;
                this.getData();
            }
        }
    };
</script>

<style scoped>

</style>
