const snackbarModule = {
    namespaced: true,
    state: {
        text: '',
        params: [],
        timeout: null,
        undo: undefined,
        link: undefined,
        visible: false
    },
    mutations: {
        set (state, payload) {
            state.text = payload.text;
            state.params = payload.params;
            state.timeout = payload.timeout;
            state.link = payload.link;
            state.undo = payload.undo;
        },
        setVisible(state, payload) {
            state.visible = payload.visible;
        },
    },
    getters: {
        isVisible: (state) => state.visible
    }
};

export default snackbarModule;
