<template>
  <div>
    <v-layout wrap>
      <v-flex
        md8
        xs12
      >
        <TaskSimpleStateChangeCard
          v-if="isWorker && !isChief && assignedToCurrentUser"
          :api="api"
          action="finish"
          :task-info="taskInfo"
        />
        <slot name="approveOption">
          <TaskSimpleStateChangeCard
            v-if="isWorker && isChief && isInProgress && assignedToCurrentUser"
            :api="api"
            :finish-and-approve="true"
            :task-info="taskInfo"
          />
          <TaskSimpleStateChangeCard
            v-if="isChief && isWaitingForApproval"
            :api="api"
            action="approve"
            :task-info="taskInfo"
          />
        </slot>
      </v-flex>
      <v-flex
        v-if="assignedToCurrentUser && !isWaitingForApproval && !isType(taskTypes.STOCK_TAKING)"
        md4
        xs12
      >
        <TaskSimpleStateChangeCard
          :api="api"
          action="unassign"
          :task-info="taskInfo"
        />
      </v-flex>
      <v-flex
        v-if="isChief && (isCreated || isUnassigned || isWaitingForApproval) && !isType(taskTypes.LOCATION_TRANSFER)"
        md4
        xs12
      >
        <TaskSimpleStateChangeCard
          :api="api"
          :action="isWaitingForApproval ? 'reject' : 'cancel'"
          :task-info="taskInfo"
        />
      </v-flex>
    </v-layout>
    <v-card
      v-if="showMovements"
      class="ml-1 mt-1"
    >
      <v-card-title class="text-h5">
        {{ $t('tasks.movementsOverview.title') }}
      </v-card-title>
      <v-card-text class="mt-n10">
        <div
          v-if="isType(taskTypes.SUBSTOCK_TRANSFER)"
          class="mt-8 text-h6 mb-n10"
        >
          {{ $t('tasks.movementsOverview.sourceSubstock') + ': ' }}
          {{ taskInfo.details.source_subordinate_stock.name }}
        </div>
        <StockMovementsTable
          :sub-stock-id="subStockId"
          :headers="headers"
          :actions="actions"
          :api-filter="apiFilter"
          :is-in-task="true"
          :reload="reload"
          :table-filter-active="!!filteredInstance"
          @filterProduct="filterProduct"
          @removeFilter="removeFilter"
        />
        <div
          v-if="isType(taskTypes.SUBSTOCK_TRANSFER)"
          class="text-h6 mb-n10"
        >
          {{ $t('tasks.movementsOverview.destinationSubstock') + ': ' }}
          {{ taskInfo.details.destination_subordinate_stock.name }}
        </div>
        <StockMovementsTable
          v-if="isType(taskTypes.SUBSTOCK_TRANSFER)"
          :sub-stock-id="taskInfo.details.destination_subordinate_stock.id"
          :headers="headers"
          :actions="actions"
          :api-filter="apiFilter"
          :is-in-task="true"
          :reload="reload"
          :table-filter-active="!!filteredInstance"
          @filterProduct="filterProduct"
          @removeFilter="removeFilter"
        />
      </v-card-text>
    </v-card>
    <StockLoadingItemsPrices
      v-if="isType(taskTypes.STOCK_LOADING) && isChief"
      :task-info="taskInfo"
      class="ml-1 mt-1"
    />
  </div>
</template>

<script>
    import TaskSimpleStateChangeCard from "@/app/tasks/components/TaskSimpleStateChangeCard.component";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import {Roles} from "@/enum/roles";
    import StockMovementsTable from "@/app/overview/movements/components/StockMovementsTable.component";
    import {APIFilterOP} from "@/service/APIFilters";
    import {createHeaders} from "@/utils/table";
    import {TaskTypeMixin} from "@/app/mixins/TaskTypeMixin";
    import {taskTypes} from "@/enum/task_type";
    import {CreatedOrActivatedMixin} from "@/app/mixins/CreatedOrActivatedMixin";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import StockLoadingItemsPrices from "@/app/tasks/stockLoading/components/StockLoadingItemsPrices.component";

    export default {
        name: "TaskDefaultOverview",
        components: {TaskSimpleStateChangeCard, StockMovementsTable, StockLoadingItemsPrices},
        mixins: [ACLMixin, TaskStateMixin, TaskTypeMixin, CreatedOrActivatedMixin, TaskAssignMixin],
        props: {
            api: {
                type: Object,
                default: () => ({})
            },
            taskInfo: {
                type: Object,
                default: () => ({})
            },
            workerRole: {
                type: String,
                default: Roles.STOREKEEPER
            }
        },
        data: () => ({
            apiFilter: [],
            reload: 0,
            filteredInstance: null,
            taskTypes: taskTypes
        }),
        computed: {
            isWorker: function () {
                return this.$store.getters['oauth/hasScope'](this.workerRole);
            },
            headers: function () {
                const headers = {
                    created_by: {
                        sortable: false
                    },
                    created_at: {
                        sortable: false
                    },
                    product: {
                        sortable: false
                    },
                    amount: {
                        sortable: false,
                        align: 'right'
                    },
                    movement: {
                        sortable: false
                    }
                };
                return this.filteredInstance === null ? createHeaders(headers, 'stocks.stockMovements.table.')
                    : createHeaders(headers, 'stocks.stockMovements.table.', false);
            },
            actions: function () {
                const actions = [
                    {
                        action: this.filterProduct,
                        icon: '$filter',
                        subscriptIcon: '$product',
                        label: 'stocks.stockMovements.filterProduct',
                    }
                ];
                return this.filteredInstance === null ? actions : [];
            },
            showMovements: function () {
                return this.isAnyOfTypes([taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET, taskTypes.MOVE_PRODUCTS, taskTypes.SUBSTOCK_TRANSFER]);
            },
            subStockId: function () {
                if (this.isAnyOfTypes([taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET, taskTypes.MOVE_PRODUCTS])) {
                    return this.taskInfo.details.subordinate_stock.id;
                } else {
                    return this.taskInfo.details.source_subordinate_stock.id;
                }
            }
        },
        watch: {
            'taskInfo.tab': function () {
                this.reload++;
            }
        },
        createdOrActivated(lifeCycleHook) {
            if (lifeCycleHook === this.LifeCycleHook.CREATED) {
                this.addTaskFilter();
            }
            this.reload++;
        },
        methods: {
            addTaskFilter: function () {
                if (this.isType(taskTypes.STOCK_PICKING_SET) && this.taskInfo.details.tasks && this.taskInfo.details.tasks.length !== 0) {
                    this.apiFilter.push({
                        [APIFilterOP.IN]: {
                            'task.id': this.taskInfo.details.tasks.map(task => task.id)
                        }
                    });
                } else if (this.taskInfo.details.id) {
                    this.apiFilter.push({
                        [APIFilterOP.EQUALS]: {
                            'task.id': this.taskInfo.details.id
                        }
                    });
                }
            },
            filterProduct: function (item) {
                this.apiFilter.push({
                    [APIFilterOP.EQUALS]: {
                        'product_instance.id': item.product_instance.id
                    }
                });
                this.filteredInstance = item.product_instance.id;
                this.reload++;
            },
            removeFilter: function () {
                this.apiFilter = [];
                this.addTaskFilter();
                this.filteredInstance = null;
                this.reload++;
            }
        }
    };
</script>

<style scoped>

</style>
