import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
import {taskTypes} from "@/enum/task_type";

const BarcodeListenerMixin = {
    mixins: [TaskStateMixin, TaskAssignMixin],
    methods: {
        activateTab: function (data) {
            if (this.isInProgress && this.assignedToCurrentUser && this.type !== taskTypes.EXTERNAL_ORDER) {
                if (this.tab !== 1) {
                    this.tab = 1;
                }
            } else if (this.assignable) {
                this.assign()
                    .then(() => {
                        this.tab = 1;
                        window.onBarcodeRead(data);
                    });
            }
        }
    }
};

export {BarcodeListenerMixin};
