import {TaskTypeMixin} from "@/app/mixins/TaskTypeMixin";
import {taskTypes} from "@/enum/task_type";

/**
 * Requires:
 *  - this.itemsInInventory
 *  - this.activeLocationId
 *  - this.taskInfo.taskId
 *  - this.showMovingAllAlert
 *  - this.API
 */
const MoveAllMixin = {
    mixins: [TaskTypeMixin],
    methods: {
        moveAllFromInventory: function () {
            const promises = [];
            this.itemsInInventory.forEach(item => {
                if (this.isAnyOfTypes([taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET])
                    || (!item.allowedLocationIds || item.allowedLocationIds.includes(this.activeLocationId))) {
                    promises.push(new Promise((resolve, reject) => {
                        this.API.putToDestination(this.taskInfo.taskId, item.id, this.activeLocationId, item.quantity_in_user_inventory)
                            .then(resolve)
                            .catch(reject);
                    }));
                }
            });
            if (promises.length > 0) {
                this.showMovingAllAlert = true;
                Promise.all(promises)
                    .then(() => {
                        this.fetchItems()
                            .catch(this.snack)
                            .finally(() => this.showMovingAllAlert = false);
                    })
                    .catch(err => {
                        this.snack(err);
                        this.showMovingAllAlert = false;
                    });
            }
        }
    }
};

export {MoveAllMixin};
