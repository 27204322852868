<template>
  <TaskDefaultOverview
    :api="API"
    :task-info="taskInfo"
  >
    <template #approveOption>
      <ExternalOrderApproveCard
        v-if="isWaitingForApproval && assignedToCurrentUser"
        :task-id="taskInfo.details.id"
        :items="items"
        :default-data-picking="defaultDataPicking"
        class="mb-4"
      />
      <ExternalOrderCloseCard
        v-if="isChief"
        :task-id="taskInfo.details.id"
      />
    </template>
  </TaskDefaultOverview>
</template>

<script>
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import TaskDefaultOverview from "@/app/tasks/components/TaskDefaultOverview.component";
    import ExternalOrderApproveCard from "@/app/tasks/externalOrder/components/ExternalOrderApproveCard.component";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import {TaskExternalOrderAPI as API} from "@/api/TaskExternalOrderAPI";
    import ExternalOrderCloseCard from "@/app/tasks/externalOrder/components/ExternalOrderCloseCard.component";

    export default {
        name: "ExternalOrderOverview",
        components: {ExternalOrderCloseCard, TaskDefaultOverview, ExternalOrderApproveCard},
        mixins: [ACLMixin, TaskStateMixin, TaskAssignMixin],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            },
            items: {
                type: Array,
                default: () => []
            }
        },
        data: () => ({
            API: API
        }),
        computed: {
            defaultDataPicking: function () {
                return {
                    stock_id: this.taskInfo.details.subordinate_stock.stock_id,
                    subordinate_stock_id: this.taskInfo.details.subordinate_stock.id,
                    buyer_id: this.taskInfo.details.buyer_id,
                    buyer_delivery_address_id: this.taskInfo.details.buyer_delivery_address_id
                };
            }
        }
    };
</script>

<style scoped>

</style>
