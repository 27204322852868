import Vue from "vue";
import VueRouter from "vue-router";
import {navigationGuard} from "@/service/router/RouterUtils";

import NotFound from "@/app/NotFound.view";

import homepage from "@/service/router/homepage.router";
import stockStatus from "@/service/router/admin/stockStatus.router";
import stockMovements from "@/service/router/admin/stockMovements.router";
import time from "@/service/router/admin/time.router";
import shipping from "@/service/router/admin/shipping.router";
import carriers from "@/service/router/admin/carriers.router";
import buyers from "@/service/router/admin/buyers.router";
import manufacturers from "@/service/router/admin/manufacturers.router";
import products from "@/service/router/admin/products.router";
import stocks from "@/service/router/admin/stocks.router";
import suppliers from "@/service/router/admin/suppliers.router";
import users from "@/service/router/admin/users.router";
import generalTaskRouter from "@/service/router/tasks/generalTask.router";
import deliveryAccept from "@/service/router/tasks/deliveryAccept.router";
import stockLoading from "@/service/router/tasks/stockLoading.router";
import stockTaking from "@/service/router/tasks/stockTaking.router";
import stockPicking from "@/service/router/tasks/stockPicking.router";
import stockPickingSet from "@/service/router/tasks/stockPickingSet.router";
import preparePackage from "@/service/router/tasks/preparePackage.router";
import moveProducts from "@/service/router/tasks/moveProducts.router";
import locationTransfer from "@/service/router/tasks/locationTransfer.router";
import substockTransfer from "@/service/router/tasks/substockTransfer.router";
import externalOrder from "@/service/router/tasks/externalOrder.router";
import cache from "@/service/router/cache.router";
import auth from "@/service/router/auth.router";

Vue.use(VueRouter);

const routes = [
    ...homepage,
    ...stockStatus,
    ...stockMovements,
    ...time,
    ...shipping,
    ...carriers,
    ...buyers,
    ...manufacturers,
    ...products,
    ...stocks,
    ...suppliers,
    ...users,
    ...generalTaskRouter,
    ...deliveryAccept,
    ...stockLoading,
    ...stockTaking,
    ...stockPicking,
    ...stockPickingSet,
    ...preparePackage,
    ...moveProducts,
    ...locationTransfer,
    ...substockTransfer,
    ...externalOrder,
    ...cache,
    ...auth,
    ...[{
        path: '*',
        component: NotFound,
        meta: {
            title: 'base.notFound'
        }
    }]
];

const Router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior() {
        return {x: 0, y: 0};
    }
});

export {Router};

Router.beforeEach(navigationGuard);
