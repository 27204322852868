<template>
  <v-card
    class="ma-1"
  >
    <v-container>
      <v-icon class="mb-1">
        $closeItem
      </v-icon>
      <span class="text-subtitle-1 ml-2">
        {{ $t('tasks.externalOrder.close.label') }}
      </span>
      <v-layout wrap>
        <v-flex
          xs12
        >
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent
          >
            <v-divider class="my-3" />
            <FormFields
              :form="form"
              :render="formRender"
              lang-path="tasks.externalOrder.close."
              :md-sizing="4"
            />
          </v-form>
        </v-flex>
      </v-layout>
      <div>
        <v-btn
          :text="!valid"
          :loading="loading"
          type="submit"
          color="accent"
          @click="close()"
        >
          <v-icon
            class="mr-2"
          >
            $closeItem
          </v-icon>
          {{ $t('tasks.externalOrder.close.label') }}
        </v-btn>
      </div>
    </v-container>
  </v-card>
</template>

<script>
    import {actionIcons} from "@/enum/icons";
    import {clearFormErrors, setFormErrors} from "@/utils/form";
    import {
        ExternalOrderCloseForm,
        ExternalOrderCloseRender
    } from "@/app/tasks/externalOrder/definitions/externalOrderClose.form";
    import FormFields from "@/app/components/form/FormFields.component";
    import {TaskExternalOrderAPI} from "@/api/TaskExternalOrderAPI";
    import {taskNames, taskTypes} from "@/enum/task_type";

    export default {
        name: "ExternalOrderCloseCard",
        components: {FormFields},
        props: {
            taskId: {
                type: Number,
                default: undefined
            }
        },
        data: () => ({
            form: new ExternalOrderCloseForm,
            formRender: new ExternalOrderCloseRender,
            valid: true,
            loading: false,
            actionIcons: actionIcons
        }),
        mounted: function () {
            clearFormErrors(this);
        },
        methods: {
            close: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                this.form.hours = this.$store.getters['time/currentHours'](Date.now());
                TaskExternalOrderAPI.close(this.taskId, this.form)
                    .then(() => {
                        this.$store.dispatch('time/stop');
                        this.advancedSnack({
                            text: 'tasks.externalOrder.close.done',
                            params: [
                                this.taskId,
                                this.$t(taskNames[taskTypes.EXTERNAL_ORDER]),
                                this.$t('base.shipping.shipment.state.' + this.form.shipment_state)
                            ]
                        });
                        this.$router.push('/');
                    }).catch(err => {
                        setFormErrors.call(this, err);
                    }).finally(() => {
                        this.loading = false;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
