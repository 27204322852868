<template>
  <div>
    <TaskBarcodeReader
      v-if="isInProgress && assignedToCurrentUser"
      :scanner-mode="scannerMode"
      :task-info="taskInfo"
      :ready="ready"
      @accept-barcode="handleAcceptBarcode"
      @reject-barcode="handleRejectBarcode"
      @clear-input="resetActiveLocationId"
    />
    <v-layout
      v-if="ready"
      wrap
    >
      <v-alert
        v-if="items.length === 0"
        color="secondary"
        type="warning"
        outlined
        class="ml-2"
      >
        {{ $t('tasks.stockTaking.noItems') }}
      </v-alert>
      <v-flex
        v-for="card of cardTypes"
        :key="card.type"
        xs12
        :class="'md' + 12 / cardTypes.length "
      >
        <TaskItemsCard
          :active-location-id="activeLocationId"
          :api="API"
          :card-type="card.type"
          :is-single="cardTypes.length === 1"
          :items="card.items"
          items-update-emit="fetch-items"
          :task-info="taskInfo"
          task-lang-path="stockTaking."
          :item-groups="(restrictType === TaskStockTakingRestriction.MANUFACTURER || restrictType === TaskStockTakingRestriction.PRODUCT) ? itemGroups : undefined"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
    import {StockAPI} from "@/api/StockAPI";
    import {CodeType} from "@/enum/code_type";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import {TaskFetchItemsMixin} from "@/app/mixins/TaskFetchItemsMixin";
    import TaskItemsCard from "@/app/tasks/components/taskItemsCard/TaskItemsCard.component";
    import {TaskStockTakingAPI as API} from "@/api/TaskStockTakingAPI";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";
    import {TaskStockTakingRestriction} from "@/enum/task_stock_taking_restriction";
    import {ProductAPI} from "@/api/ProductAPI";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import TaskBarcodeReader from "@/app/tasks/components/TaskBarcodeReader.component";
    import {scannerModes} from "@/enum/scanner_mode";
    import {scrollTo} from "@/service/Vuetify";
    import {readerFeedback} from "@/utils/readerFeedback";
    import debounce from "lodash.debounce";
    import {ManufacturerAPI} from "@/api/ManufacturerAPI";

    export default {
        name: "StockTakingTaking",
        components: {TaskBarcodeReader, TaskItemsCard},
        mixins: [TaskStateMixin, TaskAssignMixin, TaskFetchItemsMixin, EventsListenerMixin],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            ready: false,
            items: [],
            activeLocationId: null,
            API: API,
            locationIdWhitelist: [],
            restrictItem: null,
            TaskStockTakingRestriction: TaskStockTakingRestriction
        }),
        computed: {
            events: function () {
                return {
                    'fetch-items': this.onFetchItems,
                    'update-quantity': this.updateQuantity,
                };
            },
            cardTypes: function () {
                return [{
                    type: TaskItemsCardType.PRESENT,
                    items: this.items
                }];
            },
            scannerMode: function () {
                if (this.activeLocationId !== null) {
                    return scannerModes.DESTINATION;
                }
                return scannerModes.IDLE;
            },
            restrictType: function () {
                return this.taskInfo.details.restrict_type;
            },
            restrictReference: function () {
                return this.taskInfo.details.restrict_reference_id;
            },
            debouncedUpdateQuantity: function () {
                return debounce(this.API.updateItem.bind(API), 500);
            },
            itemGroups: function () {
                return [
                    {
                        filter: (item) => item.part_of_inventory,
                        label: this.$t('tasks.stockTaking.items.partOfInventory.yes.' + this.restrictType, [this.restrictItem ? this.restrictItem.name : ''])
                    },
                    {
                        filter: (item) => !item.part_of_inventory,
                        label: this.$t('tasks.stockTaking.items.partOfInventory.no.' + this.restrictType)
                    }
                ];
            }
        },
        created: function () {
            const stockId = this.taskInfo.details.stock.id;
            if (this.restrictType === TaskStockTakingRestriction.STOCK_LOCATION) {
                this.locationIdWhitelist = this.taskInfo.details.stock_locations.map(location => location.id);
            }
            if (this.restrictType === TaskStockTakingRestriction.SUB_STOCK) {
                StockAPI.getAllSubstockAvailableLocationsAllPages(stockId, this.restrictReference)
                    .then(response => {
                        this.locationIdWhitelist = response.data.items.map(location => location.id);
                    }).catch(this.snack);
            }
            if (this.restrictType === TaskStockTakingRestriction.STOCK_OWNER) {
                StockAPI.getAllSubstockPages(stockId)
                    .then(response => {
                        const ssIDs = response.data.items
                            .filter(substock => substock.owner.id === this.restrictReference)
                            .map(substock => substock.id);
                        const whitelist = [];
                        const promises = [];
                        for (const substockID of ssIDs) {
                            promises.push(StockAPI.getAllSubstockAvailableLocationsAllPages(stockId, substockID));
                        }
                        Promise.all(promises)
                            .then(responses => {
                                responses.forEach(response => {
                                    whitelist.push(...response.data.items.map(location => location.id));
                                });
                                this.locationIdWhitelist = [...new Set(whitelist)];
                            });
                    }).catch(this.snack);
            }
        },
        createdOrActivated: function (lifeCycleHook) {
            if (this.restrictType === TaskStockTakingRestriction.PRODUCT) {
                this.loadProduct();
            } else if (this.restrictType === TaskStockTakingRestriction.MANUFACTURER) {
                this.loadManufacturer();
            }
            if (lifeCycleHook === this.LifeCycleHook.CREATED) {
                this.fetchItems({initial: true})
                    .then(() => {
                        this.ready = true;
                    }).catch(this.snack);
            } else {
                this.fetchItems().catch(this.snack);
            }
        },
        methods: {
            onFetchItems: function (callback) {
                this.fetchItems()
                    .then(callback)
                    .catch(this.snack);
            },
            loadProduct: function () {
                ProductAPI.get(this.restrictReference)
                    .then(response => {
                        this.restrictItem = response.data;
                    });
            },
            loadManufacturer: function () {
                ManufacturerAPI.get(this.restrictReference)
                    .then(response => {
                        this.restrictItem = response.data;
                    });
            },
            updateQuantity: function (payload) {
                const item = this.items.find(item => item.id === payload.itemId);
                if (item) {
                    item.real_amount = payload.quantity;
                    this.debouncedUpdateQuantity(this.taskInfo.taskId, payload.itemId, payload.quantity);
                    // TODO fetch items?
                }
            },
            handleAcceptBarcode: function (barcodeInfo, quantity) {
                if (barcodeInfo.type === CodeType.PRODUCT_INSTANCE) {
                    if (this.scannerMode === scannerModes.IDLE) {
                        this.readingFail('tasks.stockTaking.scanLocation');
                    } else {
                        this.addItem(barcodeInfo.object_id, barcodeInfo.quantity * quantity);
                    }
                } else if (barcodeInfo.type === CodeType.STOCK_LOCATION) {
                    this.changeActiveLocation(barcodeInfo);
                    this.$nextTick(() => {
                        scrollTo('taskItemsCard-' + TaskItemsCardType.PRESENT);
                    });
                } else {
                    this.readingFail('base.api.barcodes.unknown');
                }
            },
            handleRejectBarcode: function () {
                if (this.scannerMode === scannerModes.IDLE) {
                    this.readingFail('tasks.stockTaking.scanLocation');
                } else {
                    this.readingFail();
                }
            },
            addItem: function (instanceId, quantity) {
                const item = this.items.find(item => (item.product_instance_id === instanceId && item.stock_location_id === this.activeLocationId));
                let promise;
                if (item) {
                    promise = API.updateItem(this.taskInfo.taskId, item.id, item.real_amount + quantity);
                } else {
                    promise = API.createItem(this.taskInfo.taskId, this.activeLocationId, instanceId, quantity);
                }
                promise.then(() => {
                    readerFeedback.success();
                    this.fetchItems({
                        debounceQuantities: true,
                        onlyInstanceId: instanceId
                    }).then(this.readingDoneNoVibrate).catch(this.snack);
                }).catch(this.readingFail);
            },
            resetActiveLocationId: function () {
                this.activeLocationId = null;
            },
            changeActiveLocation: function (barcodeLocation) {
                const locationId = barcodeLocation.object_id;
                if (this.locationIdWhitelist.length > 0 && !this.locationIdWhitelist.includes(locationId)) {
                    this.readingFail('tasks.stockTaking.restrictions.wrongLocation');
                    return;
                }
                this.activeLocationId = locationId;
                this.readingDone();
            }
        }
    };
    // TODO option to remove location
</script>

<style scoped>

</style>
