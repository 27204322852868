<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-layout
              column
              align-center
            >
              <v-flex>
                <span
                  v-show="showReturnInput"
                >
                  <v-text-field
                    ref="returnInput"
                    v-model="returnAmount"
                    outlined
                    hide-details
                    type="number"
                    min="1"
                    step="1"
                    class="mb-1 centered-input"
                    @input="returnQuantityInputChanged"
                    @blur="toggleShowReturnInput"
                  />
                </span>
              </v-flex>
              <v-flex
                v-show="quantityCurrent(item, locationId) > 1"
              >
                <v-btn-toggle>
                  <v-btn
                    v-for="option of changeByOptions"
                    :key="option"
                    :disabled="returnAmount + option < 1 || returnAmount + option > quantityCurrent(item, locationId)"
                    outlined
                    @click="changeReturnAmount(option)"
                  >
                    {{ (option > 0 ? '+' : '') + option }}
                  </v-btn>
                  <v-btn
                    @click="toggleShowReturnInput(item.id)"
                  >
                    <v-icon>
                      $itemQuantityChange
                    </v-icon>
                  </v-btn>
                </v-btn-toggle>
              </v-flex>
              <v-flex
                v-if="item.source_locations && item.source_locations.length > 1"
              >
                <v-chip-group
                  v-model="returnLocationId"
                  mandatory
                  column
                  active-class="primary--text"
                >
                  <v-chip
                    v-for="sourceLoc of item.source_locations"
                    :key="sourceLoc.stock_location_id"
                    :value="sourceLoc.stock_location_id"
                  >
                    {{ LocationCache[sourceLoc.stock_location_id] | locationLabel }}
                  </v-chip>
                </v-chip-group>
              </v-flex>
              <v-flex>
                <v-btn
                  :disabled="showReturnInput"
                  color="accent"
                  class="mt-3 multiLineButton"
                  @click="back"
                >
                  <v-icon
                    class="mr-2"
                  >
                    $itemReturn
                  </v-icon>
                  <span>
                    {{ returnLabelFull }}
                  </span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-btn
      color="accent"
      outlined
      @click="dialog = true"
    >
      {{ returnButtonLabel }}
      <v-icon
        class="ml-2"
      >
        $itemReturnConfirmation
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
    import {ReactiveLocationCacheMixin} from "@/app/mixins/ReactiveLocationCacheMixin";
    import {locationLabel} from "@/utils/string";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";
    import {changeByOptions} from '@/app/tasks/definitions/changeByOptions';

    export default {
        name: "TaskItemDetailReturn",
        mixins: [ReactiveLocationCacheMixin],
        props: {
            item: {
                type: Object,
                default: () => ({})
            },
            locationId: {
                type: Number,
                default: null
            },
            cardType: {
                type: Number,
                default: 0
            },
            quantityCurrent: {
                type: Function,
                default: () => () => 0
            }
        },
        data: () => ({
            dialog: false,
            returnLocationId: null,
            returnAmount: 1,
            showReturnInput: false,
            changeByOptions: changeByOptions
        }),
        computed: {
            returnButtonLabel: function () {
                return this.$t('tasks.itemsCard.returnTo.' + (this.cardType === TaskItemsCardType.IN_INVENTORY ? 'location' : 'inventory'));
            },
            returnLocationLabel: function () {
                return locationLabel(this.LocationCache[this.returnLocationId]);
            },
            returnLabelAmount: function () {
                return this.$t('tasks.itemsCard.returnAmount', [
                    this.returnAmount,
                    this.$options.filters.productMeasureLabel(this.item.instance && this.item.instance.product)
                ]);
            },
            returnLabelWhere: function () {
                switch (this.cardType) {
                case TaskItemsCardType.IN_INVENTORY:
                    return this.$t('tasks.itemsCard.returnWhere', [this.returnLocationLabel]);
                case TaskItemsCardType.MOVED:
                    return this.$t('tasks.itemsCard.returnToInventory');
                case TaskItemsCardType.PRESENT:
                    return this.$t('tasks.itemsCard.remove');
                default:
                    return '';
                }
            },
            returnLabelFull: function () {
                return this.returnLabelAmount + ' ' + this.returnLabelWhere;
            }
        },
        watch: {
            dialog: function (newValue) {
                if (newValue) {
                    this.returnAmount = this.quantityCurrent(this.item, this.locationId);
                }
            }
        },
        created: function () {
            this.returnAmount = this.quantityCurrent(this.item, this.locationId);
            this.returnLocationId = this.defaultReturnLocationId();
        },
        methods: {
            changeReturnAmount: function (changeBy) {
                if (this.showReturnInput) {
                    this.toggleShowReturnInput();
                }
                this.returnAmount += changeBy;
            },
            toggleShowReturnInput: function () {
                this.showReturnInput = !this.showReturnInput;
                if (this.showReturnInput) {
                    this.$nextTick(() => {
                        const input = this.$refs['returnInput'];
                        input.focus();
                        input.$refs.input.select();
                    });
                }
            },
            returnQuantityInputChanged: function () {
                this.returnAmount = Number.parseInt(this.returnAmount, 10);
                if (this.returnAmount === 0 || isNaN(this.returnAmount)) {
                    this.returnAmount = 1;
                }
                if (this.returnAmount < 1) {
                    this.returnAmount = Math.abs(this.returnAmount);
                }
                if (this.returnAmount > this.quantityCurrent(this.item, this.locationId)) {
                    this.returnAmount = this.quantityCurrent(this.item, this.locationId);
                }
            },
            defaultReturnLocationId: function () {
                // TODO CRITICAL test in all three tasks
                const sourceLocation = this.item.source_locations?.[0];
                return sourceLocation !== undefined ? sourceLocation.stock_location_id : null;
            },
            back: function () {
                this.dialog = false;
                let retLocId = this.locationId;
                if (this.cardType === TaskItemsCardType.IN_INVENTORY) {
                    retLocId = this.returnLocationId;
                }
                this.$emit('back', this.returnAmount, retLocId, this.onBackDone);
                this.returnLocationId = this.defaultReturnLocationId();
            },
            onBackDone: function () {
                this.returnLocationId = this.defaultReturnLocationId();
            }
        }
    };
    // TODO show where it was picked from and limit return amount
</script>

<style scoped>

</style>
