import {TableFilter} from "@/enum/table_filter";
import i18n from "@/service/lang/i18n";
import Vue from "vue";

const externalOrderTable = {
    quantity: {
        filterType: TableFilter.NUMBER
    },
    'product.name': {
        itemLabel: item => {
            let label = `<${i18n.t('base.loading')}...`;
            if (item.instance) {
                label = Vue.options.filters.instanceLabel(item.instance, item.product);
            } else if (item.product) {
                label = Vue.options.filters.productLabel(item.product);
                if (item.product_instance_type_id) {
                    label += ` (${item.product_instance_type_name || '...'})`;
                }
            }
            return label;
        },
        filterType: TableFilter.TEXT
    },
    // selectedInstance: {}
};

export {externalOrderTable};
