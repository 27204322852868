import { render, staticRenderFns } from "./TaskItemsCardItem.component.vue?vue&type=template&id=ac1a3464&"
import script from "./TaskItemsCardItem.component.vue?vue&type=script&lang=js&"
export * from "./TaskItemsCardItem.component.vue?vue&type=script&lang=js&"
import style0 from "./TaskItemsCardItem.component.vue?vue&type=style&index=0&id=ac1a3464&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VChip } from 'vuetify/lib/components/VChip';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBottomSheet,VChip,VFadeTransition,VIcon,VImg,VLazy,VProgressCircular,VSheet})
