<template>
  <div>
    <x-toolbar />
    <v-card>
      <v-container>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="tasks.stockLoading."
          >
            <template #strict_mode-item="{ item }">
              <TaskCreateStrictModeItem
                :item="item"
                lang-path="tasks.stockLoading.strictHint."
              />
            </template>
          </FormFields>
          <v-flex
            xs12
          >
            <TaskChooseItems
              :chosen-items.sync="chosenItems"
              :available-items="availableItems"
              title-path="tasks.stockLoading.itemsToLoad"
              :loading="loading"
              :loading-items="loadingAvailableItems"
              choose-instance
              :choose-just-instance="false"
            />
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <TaskAttachmentUpload
              v-model="validAttachments"
              :files-to-attach="form.attachments"
            />
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-btn
              :text="!validAll"
              :loading="loading"
              type="submit"
              color="accent"
              @click="submit()"
            >
              <v-icon
                class="mr-2"
              >
                $taskStockLoading
              </v-icon>
              {{ $t('tasks.stockLoading.create') }}
            </v-btn>
          </v-flex>
        </v-form>
      </v-container>
    </v-card>
    <div class="my-2" />
    <SupplierCreateUpdate
      v-if="showCreateSupplier"
      id="supplierForm"
      ref="supplierForm"
      :is-edit="false"
      is-component
    />
  </div>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import TaskAttachmentUpload from "@/app/tasks/components/TaskAttachmentUpload.component";
    import {
        StockLoadingCreateForm,
        StockLoadingCreateRender
    } from "@/app/tasks/stockLoading/definitions/stockLoadingCreate.form";
    import {formMountedProcedure, setFormErrors} from "@/utils/form";
    import {TaskStockLoadingAPI} from "@/api/TaskStockLoadingAPI";
    import {StockAPI} from "@/api/StockAPI";
    import SupplierCreateUpdate from "@/app/suppliers/SupplierCreateUpdate.view";
    import {scrollTo} from "@/service/Vuetify";
    import TaskChooseItems from "@/app/tasks/components/TaskChooseItems.component";
    import {ProductAPI} from "@/api/ProductAPI";
    import formRules from "@/utils/formRules";
    import {taskLinks, taskTypes} from "@/enum/task_type";
    import {getIdFromLocation} from "@/utils/url";
    import TaskCreateStrictModeItem from "@/app/tasks/components/TaskCreateStrictModeItem.component";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";

    export default {
        name: "StockLoadingCreate",
        components: {TaskCreateStrictModeItem, TaskAttachmentUpload, FormFields, SupplierCreateUpdate, TaskChooseItems},
        mixins: [EventsListenerMixin],
        data: () => ({
            form: new StockLoadingCreateForm,
            formRender: new StockLoadingCreateRender,
            valid: true,
            validAttachments: true,
            loading: false,
            showCreateSupplier: false,
            chosenItems: [{product_id: null, product_instance_id: null}],
            availableItems: [],
            loadingAvailableItems: false,
            rules: formRules
        }),
        computed: {
            events: function () {
                return {
                    'create-supplier': this.onCreateSupplier,
                    'create-supplier-created': this.onCreateSupplierCreated,
                    'create-supplier-cancelled': () => this.showCreateSupplier = false
                };
            },
            validAll: function () {
                return this.valid && this.validAttachments;
            }
        },
        watch: {
            'form.stock_id': function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    this.stockIdChanged();
                }
            },
        },
        createdOrActivated: function () {
            this.fetchProducts();
        },
        mounted: function () {
            formMountedProcedure(this);
        },
        methods: {
            fetchProducts: function () {
                this.loadingAvailableItems = true;
                ProductAPI.getAllPages().then(response => {
                    this.availableItems = response.data.items.map(product => {
                        product.value = product.id;
                        return product;
                    });
                }).catch(this.snack)
                    .finally(() => this.loadingAvailableItems = false);
            },
            onCreateSupplier: function () {
                this.showCreateSupplier = true;
                if (this.$refs.fields !== undefined) {
                    this.$refs.fields.$refs.supplier_id[0].blur();
                }
                this.$nextTick(() => {
                    scrollTo('supplierForm');
                });
            },
            onCreateSupplierCreated: function (supplierId) {
                this.showCreateSupplier = false;
                this.$set(this.formRender.supplier_id, 'loading', true);
                this.formRender.supplier_id.autocomplete.callFn()
                    .then(response => {
                        this.formRender.supplier_id.autocomplete.items = this.formRender.supplier_id.autocomplete.thenFn(response);
                        this.form.supplier_id = Number.parseInt(supplierId, 10);
                    }).finally(() => this.$set(this.formRender.supplier_id, 'loading', false));
            },
            stockIdChanged: function () {
                this.$set(this.formRender.subordinate_stock_id, 'loading', true);
                StockAPI.getAllSubstockPages(this.form.stock_id)
                    .then(response => {
                        this.formRender.subordinate_stock_id.autocomplete.items = response.data.items.map(el => ({
                            text: el.name,
                            value: el.id
                        }));
                    }).finally(() => {
                        this.$set(this.formRender.subordinate_stock_id, 'loading', false);
                    });
            },
            submit: function () {
                if (!this.validAll) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                const data = {...this.form};
                data.items = this.chosenItems
                    .filter(item => {
                        delete item.errors;
                        delete item.product_id;
                        return item.product_instance_id !== null;
                    });
                TaskStockLoadingAPI.create(data)
                    .then(response => {
                        this.snack('tasks.stockLoading.createDone');
                        this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                        this.$router.push(taskLinks[taskTypes.STOCK_LOADING] + '/' + getIdFromLocation(response));
                    })
                    .catch(setFormErrors.bind(this))
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
