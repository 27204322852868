import i18n from "@/service/lang/i18n";
import {unfold} from "@/utils/object";
import moment from "moment";
import {ISO_8601_DATE_TIME_FORMAT} from "@/utils/datetime";
import {DATETIME_DISPLAY_FORMAT} from "@/utils/datetime";

const APIFilters = {

    /**
     * @param sortObject {Object}
     * @return {string}
     */
    makeSort: sortObject => Object.keys(sortObject).map(key => `${key}:${sortObject[key].toLowerCase()}`).join(','),

    /**
     * @param filterObject
     * @return {string}
     */
    makeFilter: function (filterObject) {
        if (Array.isArray(filterObject)) { // implicit AND
            return filterObject.map(nested => makeFilterComponent(nested)).filter(c => c).join(';');
        } else {
            return makeFilterComponent(filterObject);
        }
    },

    /**
     * Returns filter API string as: 'key=value and key2=value2 and ...')
     * @param filterObject
     * @return {string}
     */
    makeSimpleFilter: function (filterObject) {
        return Object.keys(filterObject)
            .map(key => APIFilterOP.EQUALS + ':' + key + ':' + escapeValue(filterObject[key]))
            .join(',');
    },

    /**
     * @param key
     * @return {Object}
     */
    getType: key => {
        for (const type of Object.values(operatorTypes)) {
            if (type.list.includes(key)) {
                return type;
            }
        }
        window.console.warn('Invalid filter syntax, expected object: `{operator: value}');
    },

    /**
     * @param APIFilterOP any value from enum
     * @param APIFilterDataType any value from enum
     * @returns boolean
     */
    isFilterOPAllowedForType: function (APIFilterOP, APIFilterDataType) {
        return APIFilterDataTypeAllowedOperations[APIFilterDataType].includes(APIFilterOP);
    },

    /**
     * @param langPath {string}
     * @param filterObject {Object}
     * @param renderValues {Object}
     * @return {string}
     */
    makeHumanReadable: function (langPath, filterObject, renderValues) {
        if (Array.isArray(filterObject)) { // implicit AND
            return filterObject.map(nested => makeHumanReadableComponent(langPath, nested, renderValues)).join(' ' + trFilter(APIFilterOP.AND) + ' ');
        } else {
            return makeHumanReadableComponent(langPath, filterObject, renderValues)
                .trim()
                .replace(/(^\(|\)$)/g, '');
        }
    },

    makeHumanReadableSort: (langPath, sortObject) => (!sortObject || !Object.keys(sortObject).length) ? '-' : Object.keys(sortObject).map(
        key => i18n.t(langPath + key + '.name') + ' ' + i18n.t('base.sort.' + sortObject[key])
    ).join(', ' + i18n.t('base.filterConfig.sortThen') + ' '),
};

const APIFilterOP = {
    AND: 'and',
    OR: 'or',
    IS_NULL: 'isnull',
    IS_NOT_NULL: 'isnotnull',
    ARRAY_CONTAINS: 'contains',
    ARRAY_NOT_CONTAINS: 'notcontains',
    ARRAY_EMPTY: 'empty',
    ARRAY_NOT_EMPTY: 'notempty',
    LIKE: 'like',
    NOT_LIKE: 'notlike',
    EQUALS: 'eq',
    NOT_EQUALS: 'neq',
    GREATER_THAN: 'gt',
    LOWER_THAN: 'lt',
    GREATER_THAN_OR_EQUAL: 'gte',
    LOWER_THAN_OR_EQUAL: 'lte',
    BETWEEN: 'between',
    IN: 'in',
    NOT_IN: 'notin',
    FULL_TEXT: 'fulltext'
};

const APIFilterDataType = {
    TEXT: 'text',
    BOOLEAN: 'bool',
    DATE: 'datetime',
    NUMBER: 'number', // float or integer
    ARRAY_TEXT: 'arrayText',
    ARRAY_NUMBER: 'arrayNumber' // array of floats or integers
};

const APIFilterValidator = {
    validate: (subject, rules) => {
        for (const rule of rules) {
            const message = rule(subject);
            if (message !== true) {
                window.console.warn(message);
            }
        }
    },
    isObject: value => (typeof value === 'object' && value !== null && !Array.isArray(value)) || 'Invalid filter syntax, expected object: `{operator: value}',
    isArray: value => Array.isArray(value) || 'Invalid filter syntax, expected array: `[{operator: value}, {operator2: value2}, ...]',
    isString: value => Object.prototype.toString.call(value) === "[object String]" || 'Invalid filter syntax, expected string: `property_name`',
    isBool: value => (typeof value === 'boolean') || 'Invalid filter syntax, expected boolean: `property_name`',
    isSingleKey: value => Object.keys(value).length === 1 || 'Invalid filter syntax, expected exactly one object key: `{operator: value}`!',
    isArrayInFirstKey: value => Array.isArray(Object.values(value)[0]) || 'Invalid filter syntax, expected array as a value of the first key: `{operator: [...values]}`!',
    arrayInFirstKeyHasTwoValues: value => Object.values(value)[0].length === 2 || 'Invalid filter syntax, expected exactly two operator values: `{operator: [value1, value2]}`!'
};

/**
 * @recursive
 */
const makeFilterComponent = filterObject => {
    const key = Object.keys(filterObject)[0];
    const type = APIFilters.getType(key);
    APIFilterValidator.validate(filterObject[key], type.validation);
    const component = type.builder(key, filterObject[key]);
    return component;
};

/**
 * @recursive
 */
const makeHumanReadableComponent = (langPath, filterObject, renderValues) => {
    const key = Object.keys(filterObject)[0];
    const type = APIFilters.getType(key);
    APIFilterValidator.validate(filterObject[key], type.validation);
    return type.render(langPath, key, filterObject[key], renderValues);
};

const trFilter = (filterName, params) => i18n.t('base.filter.' + filterName, params);

const trParams = (langPath, params, values) => params.map(param => {
    const fullParam = langPath + '.' + param;
    if (unfold(values, fullParam) !== undefined) {
        return unfold(values, fullParam);
    } else {
        if (i18n.te(fullParam)) {
            return i18n.t(fullParam);
        } else if (moment(param, ISO_8601_DATE_TIME_FORMAT, true).isValid()) {
            return moment(param).format(DATETIME_DISPLAY_FORMAT);
        } else {
            return param;
        }
    }
});

const escapeValue = value => '"' + String.prototype.replace.call(value, /"/g, '\\"') + '"';

const operatorTypes = {

    groupOperators: {
        type: 'group',
        list: [
            APIFilterOP.AND,
            APIFilterOP.OR
        ],
        validation: [
            APIFilterValidator.isArray
        ],
        builder: (key, value) => key + ':(' + value.map(nested => makeFilterComponent(nested)).filter(c => c).join(';') + ')',
        render: (langPath, key, value, renderValues) => {
            const parts = value.map(nested => makeHumanReadableComponent(langPath, nested, renderValues));
            const result = parts.join(' ' + trFilter(key) + ' ');
            return parts.length > 1 ? ` (${result}) ` : result;
        },
        configComponent: 'FilterConfigGroupOp'
    },

    fulltextOperators: {
        type: 'fulltext',
        list: [
            APIFilterOP.FULL_TEXT
        ],
        validation: [
            APIFilterValidator.isString
        ],
        builder: (key, value) => key + ':' + value,
        render: (langPath, key, value) => trFilter(key, [i18n.t(langPath + value + '.name')]),
        configComponent: undefined // Not supported
    },

    unaryOperators: {
        type: 'unary',
        list: [
            APIFilterOP.IS_NULL,
            APIFilterOP.IS_NOT_NULL,
            APIFilterOP.ARRAY_EMPTY,
            APIFilterOP.ARRAY_NOT_EMPTY,
        ],
        validation: [
            APIFilterValidator.isString
        ],
        builder: (key, value) => key + ':' + value,
        render: (langPath, key, value) => trFilter(key, [i18n.t(langPath + value + '.name')]),
        configComponent: 'FilterConfigUnaryOp'
    },

    binaryOperators: {
        type: 'binary',
        list: [
            APIFilterOP.LIKE,
            APIFilterOP.NOT_LIKE,
            APIFilterOP.EQUALS,
            APIFilterOP.NOT_EQUALS,
            APIFilterOP.GREATER_THAN,
            APIFilterOP.LOWER_THAN,
            APIFilterOP.GREATER_THAN_OR_EQUAL,
            APIFilterOP.LOWER_THAN_OR_EQUAL,
            APIFilterOP.ARRAY_CONTAINS,
            APIFilterOP.ARRAY_NOT_CONTAINS
        ],
        validation: [
            APIFilterValidator.isObject,
            APIFilterValidator.isSingleKey
        ],
        builder: (key, value) => {
            const property = Object.keys(value)[0];
            return key + ':' + property + ':' + escapeValue(value[property]);
        },
        render: (langPath, key, value, renderValues) => {
            const property = Object.keys(value)[0];
            return trFilter(key, trParams(langPath + property, [
                'name',
                value[property]
            ], renderValues));
        },
        configComponent: 'FilterConfigBinaryOp'
    },

    ternaryOperators: {
        type: 'ternary',
        list: [
            APIFilterOP.BETWEEN
        ],
        validation: [
            APIFilterValidator.isObject,
            APIFilterValidator.isSingleKey,
            APIFilterValidator.isArrayInFirstKey,
            APIFilterValidator.arrayInFirstKeyHasTwoValues
        ],
        builder: (key, value) => {
            const property = Object.keys(value)[0];
            return key + ':' + property + ':' + escapeValue(value[property][0]) + ':' + escapeValue(value[property][1]);
        },
        render: (langPath, key, value, renderValues) => {
            const property = Object.keys(value)[0];
            return trFilter(key, trParams(langPath + property, [
                'name',
                value[property][0],
                value[property][1]
            ], renderValues));
        },
        configComponent: 'FilterConfigTernaryOp'
    },

    arrayOperators: {
        type: 'array',
        list: [
            APIFilterOP.IN,
            APIFilterOP.NOT_IN
        ],
        validation: [
            APIFilterValidator.isObject,
            APIFilterValidator.isSingleKey,
            APIFilterValidator.isArrayInFirstKey
        ],
        builder: (key, value) => {
            const property = Object.keys(value)[0];
            return key + ':' + property + ':(' + value[property].map(val => escapeValue(val.value || val)).join(',') + ')';
        },
        render: (langPath, key, value, renderValues) => {
            const property = Object.keys(value)[0];
            return trFilter(key, [
                ...trParams(langPath + property, ['name'], renderValues),
                trParams(langPath + property, value[property], renderValues).join(', ')
            ]);

        },
        configComponent: 'FilterConfigArrayOp'
    }
};

const APIFilterDataTypeAllowedOperations = {
    [APIFilterDataType.TEXT]: [
        ...operatorTypes.groupOperators.list,
        APIFilterOP.EQUALS,
        APIFilterOP.NOT_EQUALS,
        APIFilterOP.IN,
        APIFilterOP.NOT_IN,
        APIFilterOP.IS_NULL,
        APIFilterOP.IS_NOT_NULL,
        APIFilterOP.LIKE,
        APIFilterOP.NOT_LIKE,
        APIFilterOP.FULL_TEXT
    ],
    [APIFilterDataType.DATE]: [
        ...operatorTypes.groupOperators.list,
        APIFilterOP.BETWEEN,
        APIFilterOP.EQUALS,
        APIFilterOP.NOT_EQUALS,
        APIFilterOP.GREATER_THAN,
        APIFilterOP.GREATER_THAN_OR_EQUAL,
        APIFilterOP.LOWER_THAN,
        APIFilterOP.LOWER_THAN_OR_EQUAL,
        APIFilterOP.IS_NULL,
        APIFilterOP.IS_NOT_NULL,
        APIFilterOP.FULL_TEXT
    ],
    [APIFilterDataType.NUMBER]: [
        ...operatorTypes.groupOperators.list,
        APIFilterOP.BETWEEN,
        APIFilterOP.EQUALS,
        APIFilterOP.NOT_EQUALS,
        APIFilterOP.IN,
        APIFilterOP.NOT_IN,
        APIFilterOP.GREATER_THAN,
        APIFilterOP.GREATER_THAN_OR_EQUAL,
        APIFilterOP.LOWER_THAN,
        APIFilterOP.LOWER_THAN_OR_EQUAL,
        APIFilterOP.IS_NULL,
        APIFilterOP.IS_NOT_NULL,
        APIFilterOP.FULL_TEXT
    ],
    [APIFilterDataType.BOOLEAN]: [
        ...operatorTypes.groupOperators.list,
        APIFilterOP.EQUALS,
        APIFilterOP.NOT_EQUALS,
        APIFilterOP.IS_NULL,
        APIFilterOP.IS_NOT_NULL
    ],
    [APIFilterDataType.ARRAY_TEXT]: [
        ...operatorTypes.groupOperators.list,
        APIFilterOP.ARRAY_CONTAINS,
        APIFilterOP.ARRAY_NOT_CONTAINS,
        APIFilterOP.ARRAY_EMPTY,
        APIFilterOP.ARRAY_NOT_EMPTY,
        APIFilterOP.IS_NULL,
        APIFilterOP.IS_NOT_NULL,
        APIFilterOP.LIKE,
        APIFilterOP.NOT_LIKE
    ],
    [APIFilterDataType.ARRAY_NUMBER]: [
        ...operatorTypes.groupOperators.list,
        APIFilterOP.ARRAY_CONTAINS,
        APIFilterOP.ARRAY_NOT_CONTAINS,
        APIFilterOP.ARRAY_EMPTY,
        APIFilterOP.ARRAY_NOT_EMPTY,
        APIFilterOP.IS_NULL,
        APIFilterOP.IS_NOT_NULL
    ]
};

export {APIFilters, APIFilterOP, APIFilterDataType, APIFilterDataTypeAllowedOperations};
