<template>
  <div>
    <x-toolbar />
    <v-card>
      <v-container>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="tasks.substockTransfer."
          />
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <ProductInstancesChips
              :items="currentSubstockItems"
              :loading="loadingSubstockItems"
              can-load-locations
            />
          </v-flex>
          <v-flex
            xs12
          >
            <TaskChooseItems
              :chosen-items.sync="chosenItems"
              :available-items="currentSubstockItems"
              title-path="tasks.substockTransfer.items.productsToMove"
              :loading="loading"
              :loading-items="loadingSubstockItems"
              items-from-stock-status
            >
              <template #item="{ item }">
                <v-layout>
                  <v-flex>
                    {{ item.instance | instanceLabel }}
                  </v-flex>
                  <v-flex class="text-right">
                    <v-tooltip left>
                      <template #activator="{ on }">
                        <span
                          v-on="on"
                        >
                          <span
                            v-if="item.blocked > 0 && $vuetify.breakpoint.smAndUp"
                            class="text--disabled"
                          >
                            ({{ item.quantity }} - {{ item.blocked }}) =
                          </span>
                          {{ item.quantity - item.blocked }}
                        </span>
                      </template>
                      {{ item.quantity }} {{ $t('tasks.itemPick.inStock') }}<br>
                      {{ item.blocked }} {{ $t('tasks.itemPick.inProgress') }}<br>
                      {{ item.quantity - item.blocked }} {{ $t('tasks.itemPick.available') }}
                    </v-tooltip>
                  </v-flex>
                </v-layout>
              </template>
            </TaskChooseItems>
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <TaskAttachmentUpload
              v-model="validAttachments"
              :files-to-attach="form.attachments"
            />
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-btn
              :text="!validAll"
              :loading="loading"
              type="submit"
              color="accent"
              @click="submit()"
            >
              <v-icon
                class="mr-2"
              >
                $taskSubstockTransfer
              </v-icon>
              {{ $t('tasks.substockTransfer.create') }}
            </v-btn>
          </v-flex>
        </v-form>
      </v-container>
    </v-card>
    <div class="my-2">
      <StockSubstockCreateUpdate
        v-if="showCreateSubstock"
        id="substockForm"
        ref="substockForm"
        :is-edit="false"
        is-component
        :parent-stock-id="form.stock_id"
      />
    </div>
  </div>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {
        SubstockTransferCreateForm,
        SubstockTransferCreateRender
    } from "@/app/tasks/substockTransfer/definitions/substockTransferCreate.form";
    import {StockAPI} from "@/api/StockAPI";
    import {formMountedProcedure, setFormErrors} from "@/utils/form";
    import {TaskSubstockTransferAPI} from "@/api/TaskSubstockTransferAPI";
    import formRules from "@/utils/formRules";
    import {has} from "@/utils/object";
    import TaskAttachmentUpload from "@/app/tasks/components/TaskAttachmentUpload.component";
    import ProductInstancesChips from "@/app/products/instances/components/ProductInstancesChips.component";
    import {TaskFetchItemsMixin} from "@/app/mixins/TaskFetchItemsMixin";
    import StockSubstockCreateUpdate from "@/app/stocks/substocks/StockSubstockCreateUpdate.view";
    import {scrollTo} from "@/service/Vuetify";
    import TaskChooseItems from "@/app/tasks/components/TaskChooseItems.component";
    import {taskLinks, taskTypes} from "@/enum/task_type";
    import {getIdFromLocation} from "@/utils/url";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";

    export default {
        name: "SubstockTransferCreate",
        components: {StockSubstockCreateUpdate, ProductInstancesChips, TaskAttachmentUpload, FormFields, TaskChooseItems},
        mixins: [TaskFetchItemsMixin, EventsListenerMixin],
        data: () => ({
            form: new SubstockTransferCreateForm,
            formRender: new SubstockTransferCreateRender,
            valid: true,
            validAttachments: true,
            loading: false,
            chosenItems: [{product_id: null, product_instance_id: null}],
            formRules: formRules,
            substockItems: {},
            loadingSubstockItems: false,
            showCreateSubstock: false
        }),
        computed: {
            events: function () {
                return {
                    'create-substock': this.onCreateSubstock,
                    'create-substock-created': this.onCreateSubstockCreated,
                    'create-substock-cancelled': this.onCreateSubstockCancelled
                };
            },
            currentSubstockItems: function () {
                if (has(this.substockItems, this.form.source_subordinate_stock_id)) {
                    return this.substockItems[this.form.source_subordinate_stock_id];
                } else {
                    return [];
                }
            },
            validAll: function () {
                return this.valid && this.validAttachments;
            }
        },
        watch: {
            'form.stock_id': function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    this.loadSubStocks();
                }
            },
            'form.source_subordinate_stock_id': function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    this.loadSubstockItems();
                }
            },
        },
        mounted: function () {
            formMountedProcedure(this);
        },
        methods: {
            loadSubStocks: function () {
                this.$set(this.formRender.source_subordinate_stock_id, 'loading', true);
                this.$set(this.formRender.destination_subordinate_stock_id, 'loading', true);
                StockAPI.getAllSubstockPages(this.form.stock_id)
                    .then(response => {
                        const substocksItems = response.data.items.map(el => ({
                            text: el.name,
                            value: el.id
                        }));
                        this.formRender.source_subordinate_stock_id.autocomplete.items = substocksItems;
                        this.formRender.destination_subordinate_stock_id.autocomplete.items = substocksItems;
                    }).finally(() => {
                        this.$set(this.formRender.source_subordinate_stock_id, 'loading', false);
                        this.$set(this.formRender.destination_subordinate_stock_id, 'loading', false);
                    });
            },
            loadSubstockItems: function () {
                const ssId = this.form.source_subordinate_stock_id;
                if (!has(this.substockItems, ssId)) {
                    this.$set(this.substockItems, ssId, []);
                }
                if (this.substockItems[ssId].length === 0) {
                    this.fetchSubstockItems(ssId);
                }
            },
            onCreateSubstock: function () {
                if (this.form.stock_id !== null && this.form.stock_id !== undefined) {
                    this.showCreateSubstock = true;
                    if (this.$refs.fields !== undefined) {
                        this.$refs.fields.$refs.destination_subordinate_stock_id[0].blur();
                    }
                    this.$nextTick(() => {
                        scrollTo('substockForm');
                    });
                } else {
                    this.snack('base.shipping.table.subordinate_stock.chooseStockFirst');
                }
            },
            onCreateSubstockCreated: function (subStockId) {
                this.showCreateSubstock = false;
                this.loadSubStocks();
                this.form.destination_subordinate_stock_id = Number.parseInt(subStockId, 10);
            },
            onCreateSubstockCancelled: function () {
                this.showCreateSubstock = false;
            },
            submit: function () {
                if (!this.validAll) {
                    this.$refs.form.validate();
                    return;
                }
                if (this.form.source_subordinate_stock_id === this.form.destination_subordinate_stock_id) {
                    this.snack('tasks.substockTransfer.unableToMoveToSame');
                    return;
                }
                this.loading = true;
                const data = {...this.form};
                data.items = this.chosenItems
                    .filter(item => {
                        delete item.errors;
                        delete item.product_id;
                        return item.product_instance_id !== null;
                    });
                TaskSubstockTransferAPI.create(data)
                    .then(response => {
                        this.snack('tasks.substockTransfer.createDone');
                        this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                        this.$router.push(taskLinks[taskTypes.SUBSTOCK_TRANSFER] + '/' + getIdFromLocation(response));
                    }).catch(err => {
                        // error is not handled, need to get errors manually
                        if (err.response.status === 409) {
                            const problems = err.response.data.blocking_items || [];
                            this.chosenItems.filter(item => problems.find(problem => problem.instance_id === item.product_instance_id)).forEach(item => {
                                this.$set(item, 'errors', [this.$t('tasks.substockTransfer.notEnoughItems')]);
                            });
                        } else {
                            setFormErrors.call(this, err.response.data.errors);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
