<template>
  <div>
    <v-bottom-sheet
      v-model="showSheet"
      scrollable
      eager
    >
      <template #activator="{ on }">
        <v-chip
          label
          class="ml-1 mb-1 itemChip"
          :color="chipColor"
          :outlined="!chipColor"
          v-on="on"
        >
          <v-fade-transition mode="out-in">
            <span
              :key="quantity"
              class="mr-1"
            >
              {{ quantity }}
              <span
                class="caption"
                :class="!itemOnWrongLocation ? 'text--disabled' : ''"
              >
                {{ quantityLabel }}
              </span>
            </span>
          </v-fade-transition>
          <template v-if="isProductLoaded">
            <v-img
              v-if="$store.getters['userConfig/showProductImagesInTasks'] && singleImage"
              :src="singleImage.url"
              :lazy-src="publicPath + 'assets/product_image_placeholder.jpg'"
              contain
              class="mx-1"
            />
            <span class="ellipsis">
              {{ item.instance | instanceLabel }}
            </span>
          </template>
          <template v-else>
            <v-progress-circular
              indeterminate
              size="14"
              width="2"
              class="mx-2"
            />
          </template>
          <v-icon
            v-show="itemAlreadyPlacedIcon"
            small
            class="ml-1"
          >
            $alreadyPlaced
          </v-icon>
        </v-chip>
      </template>
      <v-sheet>
        <v-lazy>
          <TaskItemDetails
            v-if="isProductLoaded"
            v-bind="$props"
            :prices="prices"
            :item-on-wrong-location="itemOnWrongLocation"
            :active-location-id="activeLocationId"
            :active-location-side="activeLocationSide"
            @back="onClickBack"
            @remove="$emit('remove', item, locationId)"
          />
        </v-lazy>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
    import {StockAPI} from "@/api/StockAPI";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {ReactiveLocationCacheMixin} from "@/app/mixins/ReactiveLocationCacheMixin";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import {EventBus} from "@/service/EventBus";
    import {has} from "@/utils/object";
    import TaskItemDetails from "@/app/tasks/components/taskItemsCard/TaskItemDetails.component";
    import {TaskTypeMixin} from "@/app/mixins/TaskTypeMixin";
    import {taskTypes} from "@/enum/task_type";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import {TaskItemsStrictMode} from "@/enum/task_items_strict_mode";
    import {Product} from "@/utils/product";

    export default {
        name: "TaskItemsCardItem",
        components: {TaskItemDetails},
        mixins: [TaskStateMixin, TaskAssignMixin, ACLMixin, TaskTypeMixin, ReactiveLocationCacheMixin, EventsListenerMixin],
        props: {
            item: {
                type: Object,
                default: () => ({})
            },
            cardType: {
                type: Number,
                default: 0
            },
            quantityCurrent: {
                type: Function,
                default: () => () => 0
            },
            locationId: {
                type: Number,
                default: null
            },
            prices: {
                type: Object,
                default: () => ({})
            },
            stepBackFn: {
                type: Function,
                default: () => () => {
                }
            },
            removeFn: {
                type: Function,
                default: () => () => {
                }
            },
            taskInfo: {
                type: Object,
                default: () => ({})
            },
            inventoryEmpty: {
                type: Boolean,
                default: true
            },
            activeLocationId: {
                type: Number,
                default: null
            },
            activeLocationSide: {
                type: String,
                default: null
            }
        },
        data: () => ({
            showSheet: false,
            TaskItemsCardType: TaskItemsCardType,
            publicPath: process.env.BASE_URL,
        }),
        computed: {
            events: function () {
                return {
                    'check-items-on-locations': this.checkItemsOnLocation,
                    'close-bottom-sheet': () => this.showSheet = false
                };
            },
            isProductLoaded: function () {
                return has(this.item, 'instance') && has(this.item.instance, 'product');
            },
            singleImage: function () {
                if (this.isProductLoaded) {
                    return Product.getSingleImage(this.item.instance.product);
                } else {
                    return undefined;
                }
            },
            quantity: function () {
                this.item.quantity_in_user_inventory && this.item.processed_quantity && this.item.real_amount && this.item.quantity;
                return this.cardType === TaskItemsCardType.TO_MOVE
                    ? Math.min(this.item.quantity_to_move - this.item.quantity_in_user_inventory, this.quantityCurrent(this.item, this.locationId))
                    : this.quantityCurrent(this.item, this.locationId);
            },
            quantityLabel: function () {
                if (this.cardType === TaskItemsCardType.ASSIGNMENT) {
                    return this.$options.filters.productMeasureLabel(this.item.instance.product);
                }
                if ((this.item.quantity_to_move !== undefined || this.item.expected_amount !== undefined)
                    && this.cardType !== TaskItemsCardType.TO_MOVE && this.isChief
                    && (
                        (this.isType(taskTypes.STOCK_LOADING) && this.taskInfo.details.strict_mode !== TaskItemsStrictMode.FREE)
                        || (this.isType(taskTypes.MOVE_PRODUCTS) && this.taskInfo.details.transfer_mode !== TaskItemsStrictMode.FREE)
                        || !this.isAnyOfTypes([taskTypes.STOCK_LOADING, taskTypes.MOVE_PRODUCTS])
                    )) {
                    return this.$t('base.of') + ' ' + (this.item.quantity_to_move || this.item.expected_amount);
                } else {
                    return this.$options.filters.productMeasureLabel(this.item.instance ? this.item.instance.product : null);
                }
            },
            itemOnWrongLocation: function () {
                if (this.cardType === TaskItemsCardType.IN_INVENTORY) {
                    return !!(this.item.allowedLocationIds && this.item.allowedLocationIds.length === 0);
                } else if (this.cardType === TaskItemsCardType.MOVED) {
                    return !!(this.item.allowedLocationIds && !this.item.allowedLocationIds.includes(this.locationId));
                } else {
                    return false;
                }
            },
            itemAlreadyPlacedIcon: function () {
                return (this.cardType === TaskItemsCardType.TO_MOVE || this.cardType === TaskItemsCardType.IN_INVENTORY)
                    && this.item.alreadyPlacedAt && Object.keys(this.item.alreadyPlacedAt).length > 0;
            },
            chipColor: function () {
                return this.itemOnWrongLocation ? 'error lighten-1' : undefined;
            }
        },
        watch: {
            showSheet: function (newValue) {
                if (this.cardType === TaskItemsCardType.MOVED) {
                    EventBus.$emit('bottom-sheet-visible', newValue);
                }
                if ((this.cardType === TaskItemsCardType.TO_MOVE
                    || this.cardType === TaskItemsCardType.IN_INVENTORY
                    || this.cardType === TaskItemsCardType.ASSIGNMENT
                    || this.cardType === TaskItemsCardType.MOVED)
                    && this.item.allowedLocationIds === undefined) {
                    // fetch allowed locations when chip is clicked
                    EventBus.$emit('fetch-allowed-locations', this.item);
                }
                if (this.isAnyOfTypes([taskTypes.STOCK_LOADING, taskTypes.MOVE_PRODUCTS, taskTypes.SUBSTOCK_TRANSFER])) {
                    EventBus.$emit('fetch-already-placed-at', this.item);
                }
            }
        },
        beforeDestroy: function () {
            // item is removed, so there is no bottom sheet anymore
            if (this.showSheet) {
                EventBus.$emit('bottom-sheet-visible', false);
            }
        },
        createdOrActivated: function () { // TODO refactor
            let stockId = null;
            if (this.taskInfo.details.subordinate_stock !== undefined) {
                stockId = this.taskInfo.details.subordinate_stock.stock_id;
            } else if (this.taskInfo.details.source_subordinate_stock !== undefined) {
                stockId = this.taskInfo.details.source_subordinate_stock.stock_id;
            } else {
                stockId = this.taskInfo.details.stock ? this.taskInfo.details.stock.id : null;
            }

            if (this.item.location_id !== undefined && stockId !== null) {
                this.cacheLocationLazy(StockAPI.getLocation.bind(StockAPI, stockId, this.item.location_id), this.item.location_id);
            }

            if (this.item.source_locations !== undefined) {
                this.item.source_locations.forEach(location => {
                    const location_id = location.location_id || location.stock_location_id;
                    this.cacheLocationLazy(StockAPI.getLocation.bind(StockAPI, stockId, location_id), location_id);
                });
            }
        },
        methods: {
            onClickBack: function (item, amount, locationId, callback) {
                this.showSheet = false;
                this.$emit('back', item, amount, locationId, callback);
            },
            checkItemsOnLocation: function (callback) {
                if (this.itemOnWrongLocation) {
                    callback();
                }
            }
        }
    };
</script>

<style lang="sass">
.itemChip
    .ellipsis
        overflow: hidden
        text-overflow: ellipsis

    .caption
        word-spacing: -0.1em

    .v-image
        display: inline-block
        max-height: 1.5em
        max-width: 1.5em
</style>
