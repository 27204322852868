import { render, staticRenderFns } from "./PreparePackageOrderItems.component.vue?vue&type=template&id=e8ac3686&"
import script from "./PreparePackageOrderItems.component.vue?vue&type=script&lang=js&"
export * from "./PreparePackageOrderItems.component.vue?vue&type=script&lang=js&"
import style0 from "./PreparePackageOrderItems.component.vue?vue&type=style&index=0&id=e8ac3686&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCard,VIcon,VList,VListItem,VSelect})
