import { render, staticRenderFns } from "./MoveProductsShow.view.vue?vue&type=template&id=78ddd1c4&scoped=true&"
import script from "./MoveProductsShow.view.vue?vue&type=script&lang=js&"
export * from "./MoveProductsShow.view.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78ddd1c4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
installComponents(component, {VProgressLinear,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider})
