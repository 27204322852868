import { render, staticRenderFns } from "./PreparePackageOrderItem.component.vue?vue&type=template&id=2838cab2&"
import script from "./PreparePackageOrderItem.component.vue?vue&type=script&lang=js&"
export * from "./PreparePackageOrderItem.component.vue?vue&type=script&lang=js&"
import style0 from "./PreparePackageOrderItem.component.vue?vue&type=style&index=0&id=2838cab2&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VDialog,VImg,VListItem,VListItemAvatar,VListItemContent,VProgressCircular})
