<template>
  <div>
    <TaskBarcodeDialog
      v-if="locationToCheck !== null"
      :show-dialog.sync="confirmDialog"
      :title="locationToCheck.is_mobile ? $t('tasks.itemsCard.scannedMobileLocation') : $t('tasks.itemsCard.scannedExpeditionLocation')"
      :code="locationToCheck.barcodeInfo.code"
      @cancel="cancelScanLocation"
      @confirm="reallyScanLocation"
    />
    <v-textarea
      auto-grow
      :append-icon="[scannerModes.IDLE,
                     scannerModes.IDLE_SCAN_PACKING,
                     scannerModes.IDLE_ORDER_ITEM,
                     scannerModes.IDLE_LOCATION_OR_ITEM].includes(scannerMode) ? '' : '$removeItem'"
      class="mt-2 readerInput-task"
      :class="scannerInfoIcon.class"
      :error="unknownCode"
      :error-messages="readerInputErrors"
      :hide-details="readerQueue.length !== 0 || readerLastScanned !== null"
      :label="scannerInfoText"
      :loading="barcodeLoading"
      :persistent-hint="!!readerLastScanned"
      :prepend-icon="scannerInfoIcon.icon"
      :rows="1"
      solo
      :value="readerInput"
      @input="readerInputReset"
      @keyup.enter.native="evt => onBarcodeRead(evt.target.value)"
      @keyup.up="readerInput = readerLastScanned"
      @click:append="onReaderClear"
    />
    <div
      v-if="readerQueue.length"
      class="d-flex v-messages ml-11 my-2 text--secondary"
    >
      <div class="widthFitContent v-messages__message">
        {{ $t('tasks.queue') }}:
      </div>
      <div class="d-flex flex-column flex-wrap ml-1">
        <div
          v-for="(item, index) of readerQueue"
          :key="index"
          class="v-messages__message"
        >
          <strong>{{ item.quantity }}x</strong>
          <TaskCodeLabel
            :code="item.code"
            :show-x="false"
          />
        </div>
      </div>
    </div>
    <div
      v-else-if="readerLastScanned"
      class="d-flex v-messages ml-11 my-2 text--secondary"
    >
      <div class="widthFitContent v-messages__message">
        {{ $t('tasks.lastScanned') }}
        <TaskCodeLabel
          :code="readerLastScanned"
        />
      </div>
    </div>
    <v-expand-transition>
      <div
        v-show="unknownCode"
      >
        <div
          v-for="action in unknownCodeActions"
          :key="action.label"
        >
          <v-text-field
            solo
            hide-details
            readonly
            class="fieldAsButton"
            :prepend-icon="action.icon"
            :label="$t('tasks.card.create.' + action.label)"
            @click="action.action"
          />
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
    import {BarcodeAPI} from "@/api/BarcodeAPI";
    import {ReactiveBarcodeCacheMixin} from "@/app/mixins/ReactiveBarcodeCacheMixin";
    import {ReactiveLocationCacheMixin} from "@/app/mixins/ReactiveLocationCacheMixin";
    import TaskCodeLabel from "@/app/tasks/components/TaskCodeLabel.component";
    import {scannerModes} from "@/enum/scanner_mode";
    import {taskLangPaths} from "@/enum/task_type";
    import {locationLabel} from "@/utils/string";
    import {CodeType} from "@/enum/code_type";
    import {readerFeedback} from "@/utils/readerFeedback";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import TaskBarcodeDialog from "@/app/tasks/components/TaskBarcodeDialog.component";

    export default {
        name: "TaskBarcodeReader",
        components: {TaskCodeLabel, TaskBarcodeDialog},
        mixins: [TaskStateMixin, TaskAssignMixin, EventsListenerMixin, ReactiveLocationCacheMixin, ReactiveBarcodeCacheMixin],
        props: {
            scannerMode: {
                type: Number,
                default: scannerModes.IDLE
            },
            taskInfo: {
                type: Object,
                default: () => ({})
            },
            ready: {
                type: Boolean,
                default: false
            },
            showScanConfirmation: {
                type: Function,
                default: () => false
            }
        },
        data: () => ({
            barcodeLoading: false,
            readerInput: null,
            readerInputErrors: [],
            readerLastScanned: null,
            unknownCode: false,
            scannerModes: scannerModes,
            locationToCheck: null
        }),
        computed: {
            events: function () {
                return {
                    'onBarcodeRead': this.onBarcodeRead,
                    'reading-done': this.readingDone,
                    'reading-fail': this.readingFail
                };
            },
            unknownCodeActions: function () {
                return [ // TODO
                ];
            },
            scannerInfoText: function () {
                const taskTypeLangPath = taskLangPaths[this.taskInfo.details.type];
                switch (this.scannerMode) {
                case scannerModes.IDLE:
                    return this.$te(taskTypeLangPath + 'scanLocation')
                        ? this.$t(taskTypeLangPath + 'scanLocation')
                        : this.$t('tasks.itemsCard.scanLocation');
                case scannerModes.IDLE_SCAN_PACKING:
                    return this.$t('tasks.itemsCard.scanPackingLocation', [locationLabel(this.taskInfo.details.stock_location)]);
                case scannerModes.IDLE_ORDER_ITEM:
                    return this.$t('tasks.itemsCard.scanOrderItem');
                case scannerModes.IDLE_LOCATION_OR_ITEM:
                    return this.$t('tasks.itemsCard.scanLocationOrItem');
                case scannerModes.SOURCE:
                    return this.$t('tasks.itemsCard.scanningFrom');
                case scannerModes.DESTINATION:
                    return this.$te(taskTypeLangPath + 'scanningTo')
                        ? this.$t(taskTypeLangPath + 'scanningTo')
                        : this.$t('tasks.itemsCard.scanningTo');
                case scannerModes.ORDER:
                    return this.$t('tasks.itemsCard.scanningOrder', [this.$route.query.orderId]);
                default:
                    return '';
                }
            },
            scannerInfoIcon: function () {
                switch (this.scannerMode) {
                case scannerModes.IDLE:
                    return {
                        icon: '$scannerIdle',
                        class: 'iconScan'
                    };
                case scannerModes.IDLE_SCAN_PACKING:
                    return {
                        icon: '$scannerIdle',
                        class: 'iconScan'
                    };
                case scannerModes.IDLE_ORDER_ITEM:
                    return {
                        icon: '$scannerIdle',
                        class: 'iconScan'
                    };
                case scannerModes.IDLE_LOCATION_OR_ITEM:
                    return {
                        icon: '$scannerIdle',
                        class: 'iconScan'
                    };
                case scannerModes.SOURCE:
                    return {
                        icon: '$scannerSource',
                        class: 'iconPick'
                    };
                case scannerModes.DESTINATION:
                    return {
                        icon: '$scannerDestination',
                        class: 'iconPut'
                    };
                case scannerModes.ORDER:
                    return {
                        icon: '$scannerOrder',
                        class: 'iconScan'
                    };
                default:
                    return {
                        icon: '',
                        class: ''
                    };
                }
            },
            readerQueue: function () {
                return this.$store.getters['barcodeReaderQueue/list'];
            },
            confirmDialog: {
                get: function () {
                    return this.locationToCheck !== null && (this.locationToCheck.is_expedition || this.locationToCheck.is_mobile);
                },
                set: function () {
                    this.cancelScanLocation();
                }
            }
        },
        watch: {
            barcodeLoading: function (value) {
                if (!value && !this.$store.getters['barcodeReaderQueue/empty']) {
                    const front = this.$store.getters['barcodeReaderQueue/front'];
                    this.$store.dispatch('barcodeReaderQueue/pop');
                    this.acceptBarcode(front);
                }
            },
            ready: function (value) {
                if (value && !this.$store.getters['barcodeReaderQueue/empty']) {
                    const front = this.$store.getters['barcodeReaderQueue/front'];
                    this.$store.dispatch('barcodeReaderQueue/pop');
                    this.onBarcodeRead(front.code, front.quantity);
                }
            }
        },
        deactivated: function () {
            this.$store.dispatch('barcodeReaderQueue/clear');
        },
        destroyed: function () {
            this.$store.dispatch('barcodeReaderQueue/clear');
        },
        methods: {
            onBarcodeRead: function (code, quantity = 1) {
                this.readerInputReset();
                code.split(/[\s,;]/)
                    .map(code => code.trim())
                    .filter(code => !!code)
                    .forEach(code => {
                        this.fetchBarcodeToCache(code);
                        if (this.isInProgress && this.assignedToCurrentUser) {
                            if (this.barcodeLoading) {
                                this.$store.dispatch('barcodeReaderQueue/push', code);
                            } else {
                                this.acceptBarcode({code, quantity});
                            }
                        }
                    });
            },
            readerInputReset: function (codeToSet = null) {
                this.readerInput = codeToSet;
                this.unknownCode = false;
                this.readerInputErrors = [];
            },
            onReaderClear: function () {
                this.readerInputReset();
                this.$emit('clear-input');
            },
            acceptBarcode: function ({code, quantity}) {
                if (this.confirmDialog) {
                    if (code === this.locationToCheck.barcodeInfo.code) {
                        this.reallyScanLocation();
                        return;
                    } else {
                        this.cancelScanLocation();
                    }
                }
                this.barcodeLoading = true;
                BarcodeAPI.decode(code)
                    .then(response => {
                        const barcodeInfo = response.data;
                        if (barcodeInfo.type === CodeType.STOCK_LOCATION) {
                            if (barcodeInfo.object_info.is_expedition && this.showScanConfirmation(barcodeInfo.object_id)) {
                                readerFeedback.warning();
                                barcodeInfo.code = code;
                                this.locationToCheck = ({barcodeInfo: barcodeInfo, is_expedition: true});
                                this.$emit('clear-input');
                                this.barcodeLoading = false;
                                return;
                            }
                            if (barcodeInfo.object_info.is_mobile
                                && this.$store.getters['userConfig/pickFromMobileLocation'] === false
                                && this.showScanConfirmation(barcodeInfo.object_id)) {
                                readerFeedback.warning();
                                barcodeInfo.code = code;
                                this.locationToCheck = ({barcodeInfo: barcodeInfo, is_mobile: true});
                                this.$emit('clear-input');
                                this.barcodeLoading = false;
                                return;
                            }
                        }
                        this.readerLastScanned = code;
                        barcodeInfo.code = code;
                        this.$emit('accept-barcode', barcodeInfo, quantity);
                    }).catch(err => {
                        this.unknownCode = true;
                        this.readerLastScanned = null;
                        this.readerInputErrors = [this.$t(err)];
                        this.$emit('reject-barcode');
                    });
            },
            readingDone: function (doVibrate = true) {
                this.barcodeLoading = false;
                doVibrate && readerFeedback.success();
            },
            readingFail: function (errorMessage = '') {
                errorMessage !== '' && this.snack(errorMessage);
                this.barcodeLoading = false;
                readerFeedback.error();
            },
            reallyScanLocation: function () {
                this.readerLastScanned = this.locationToCheck.barcodeInfo.code;
                this.$emit('accept-barcode', this.locationToCheck.barcodeInfo, this.locationToCheck.barcodeInfo.quantity);
                this.locationToCheck = null;
            },
            cancelScanLocation: function () {
                this.locationToCheck = null;
                this.barcodeLoading = false;
            }
        }
    };
</script>

<style lang="sass">
.readerInput-task .v-text-field__slot
  .v-label
    overflow: visible
    white-space: normal
    height: auto
    line-height: normal
    position: relative !important
    top: 0.3em !important
    margin-bottom: 0.5em !important

  textarea
    width: 0

.readerInput-task.v-text-field__details
  margin-bottom: 0

.iconScan i.v-icon
  animation-duration: .5s
  animation-name: bounce
  animation-iteration-count: infinite
  animation-direction: alternate

  @keyframes bounce
    from
      margin-bottom: 0
    to
      margin-bottom: 15px

.iconPick i.v-icon
  transform: rotate(-90deg)
  color: var(--v-error-lighten1) !important

.iconPut i.v-icon
  transform: rotate(90deg)
  color: var(--v-primary-base) !important
</style>
