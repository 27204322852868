import moment from "moment";

/**
 * Classic Object.assign implementation. Required to support Android WebView
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign#Browser_compatibility
 * In addition, this supports copying of nested objects with preservation of initial values of the target missing in the source
 * @param target {Object}
 * @param source {Object}
 * @param deep {Boolean}
 * @returns {Object}
 */
function assign(target, source, deep = false) {
    for (const [key, value] of Object.entries(source)) {
        if (has(source, key)) {
            if (deep && typeof target[key] === 'object' && target[key] !== null && typeof value === 'object' && value !== null) {
                target[key] = assign(target[key], value, true);
            } else {
                target[key] = value;
            }
        }
    }
    return target;
}

/**
 * Wrapper around Object.assign which also assigns certain identifiers like <object_id> from <object.id> and datetime formats
 * @param target {Object}
 * @param source {Object}
 * @returns {Object}
 */
function populate(target, source) {

    assign(target, source);

    // Another custom assignments
    ['manufacturer', 'type', 'supplier', 'chief', 'owner'].forEach(propertyName => {
        if (has(source, propertyName) && source[propertyName] !== null && has(target, propertyName + '_id')) {
            target[propertyName + '_id'] = source[propertyName].id;
        }
    });

    if (has(source, 'manufactured_date') && has(target, 'manufactured_time')) {
        if (source.manufactured_date !== null) {
            target.manufactured_date = moment(source.manufactured_date).format(moment.HTML5_FMT.DATE);
            target.manufactured_time = moment(source.manufactured_date).format(moment.HTML5_FMT.TIME_SECONDS);
        } else {
            target.manufactured_date = target.manufactured_time = null;
        }
    }
    if (has(source, 'expire_date') && has(target, 'expire_time')) {
        if (source.expire_date !== null) {
            target.expire_date = moment(source.expire_date).format(moment.HTML5_FMT.DATE);
            target.expire_time = moment(source.expire_date).format(moment.HTML5_FMT.TIME_SECONDS);
        } else {
            target.expire_date = target.expire_time = null;
        }
    }
    for (const priceProperty of ['suggested_buy_price', 'suggested_sell_price']) {
        if (has(source, priceProperty + '_without_vat')) {
            target[priceProperty] = source[priceProperty + '_without_vat'];
        }
    }
    if (has(source, 'price_vat')) {
        target.price_vat = Number.parseFloat(source.price_vat);
    }
    if (has(source, 'type') && source['type'] !== null && has(target, 'product_instance_type_id')) {
        target['product_instance_type_id'] = source['type'].instance_type_id;
    }
    return target;
}

/**
 * @param data {Object}
 * @param omitPath {Boolean} If true, creates attributes like 'obj1.nested.property'. If false, creates just 'property'
 * @returns {Object}
 */
function flatten(data, omitPath = false) {
    const result = {};

    function recurse(cur, prop) {
        if (Object(cur) !== cur) {
            result[prop] = cur;
        } else if (Array.isArray(cur)) {
            const l = cur.length;
            for (let i = 0; i < l; i++) {
                recurse(cur[i], prop + '[' + i + ']');
            }
            if (l === 0) {
                result[prop] = [];
            }
        } else {
            let isEmpty = true;
            for (const p in cur) {
                isEmpty = false;
                recurse(cur[p], (prop && !omitPath) ? prop + '.' + p : p);
            }
            if (isEmpty && prop) {
                result[prop] = {};
            }
        }
    }

    recurse(data, '');
    return result;
}

/**
 * Merge all passed objects in one while rewriting same properties with those specified latter
 * @param objects {...Object}
 * @return {Object}
 */
function merge(...objects) {
    return objects.reduce((acc, curr) => assign(acc, curr), {});
}

/**
 * Checks whether object has own property
 * @param object {Object}
 * @param property {string}
 * @return {boolean}
 */
function has(object = {}, property = '') {
    return Object.prototype.hasOwnProperty.call(object, property);
}

/**
 * Returns property nested in object structure specified by propertyString
 * @param object {Object}
 * @param propertyString {string}
 * @returns {*}
 */
function unfold(object, propertyString) {
    const path = propertyString.split(".");
    let result = object;
    for (const step of path) {
        if (result[step] === undefined) return undefined;
        result = result[step];
    }
    return result;
}

/**
 * Sets a property deep in the object, creating the path if needed
 * @param object
 * @param propertyString
 * @param value
 */
function setDeep(object, propertyString, value) {
    const path = propertyString.split(".");
    const last = path.splice(path.length - 1, 1)[0];
    let source = object;
    for (const step of path) {
        if (source[step] === undefined) {
            source[step] = {};
        }
        source = source[step];
    }
    source[last] = value;
}

/**
 * @src https://stackoverflow.com/a/11900218
 * @param object {Object}
 * @return {number}
 */
function roughSizeOf(object) {
    const objectList = [];
    const stack = [object];
    let bytes = 0;

    while (stack.length) {
        const value = stack.pop();

        if (typeof value === 'boolean') {
            bytes += 4;
        } else if (typeof value === 'string') {
            bytes += value.length * 2;
        } else if (typeof value === 'number') {
            bytes += 8;
        } else if (typeof value === 'object' && objectList.indexOf(value) === -1) {
            objectList.push(value);

            for (const i in value) {
                stack.push(value[i]);
            }
        }
    }
    return bytes;
}

export {assign, populate, flatten, merge, has, unfold, setDeep, roughSizeOf};
