import App from "@/App.vue";
import i18n from "@/service/lang/i18n";
import {setLang} from "@/service/lang/utils";
import loadExtendComponents from "@/service/Autoload";
import {IndexedDB} from "@/service/cache/IndexedDB";
import {Env} from "@/service/Environment";
import {EventBus} from "@/service/EventBus";
import {useGoogleTagManager} from "@/service/GoogleTagManager";
import useMoment from "@/service/Moment";
import {CreatedOrActivatedPlugin} from "@/service/plugins/CreatedOrActivated.plugin";
import {SnackbarPlugin} from "@/service/plugins/Snackbar.plugin";
import {Router} from "@/service/router/Router";
import {checkTimeOnReload} from "@/service/router/RouterUtils";
import {Sentry} from "@/service/Sentry";
import {Store} from "@/service/store/Store";
import {useTipTap} from "@/service/TipTapEditor";
import {Vuetify} from "@/service/Vuetify";
import {registerAxiosInterceptor} from "@/utils/http";
import loadFilters from "@/utils/filters";
import registerGlobalListeners from "@/utils/globalListeners";
import Vue from "vue";
import VueMasonry from 'vue-masonry-css';
import VueGridLayout from 'vue-grid-layout';

Sentry.init();
IndexedDB.clearOld(); // TODO move to a WebWorker
useMoment();
loadFilters();
loadExtendComponents();
registerAxiosInterceptor();
registerGlobalListeners();
useTipTap();
Vue.use(VueMasonry);
Vue.use(CreatedOrActivatedPlugin);
Vue.use(SnackbarPlugin);
if (Env.isProduction()) {
    useGoogleTagManager();
}

// eslint-disable-next-line
Vue.component('grid-layout', VueGridLayout.GridLayout);
// eslint-disable-next-line
Vue.component('grid-item', VueGridLayout.GridItem);

new Vue({
    i18n,
    router: Router,
    store: Store,
    vuetify: Vuetify,
    beforeCreate: function () {
        this.$store.commit('initialiseStore');
        this.$vuetify.theme.dark = this.$store.getters['userConfig/darkMode'];
        setLang(this);
        checkTimeOnReload(this);
    },
    created: function () {
        EventBus.$on('setDarkMode', () => {
            this.$vuetify.theme.dark = this.$store.getters['userConfig/darkMode'];
        });
        EventBus.$on('setLang', () => {
            setLang(this);
        });
    },
    render: h => h(App)
}).$mount('#app');
