import {StockId, TaskPriority} from "@/app/tasks/definitions/taskCommon.render.form";
import {TaskPriority as TaskPriorityEnum} from "@/enum/task_priority";
import {TaskShippingType} from "@/enum/task_shipping_type";
import i18n from "@/service/lang/i18n";

function StockPickingSetCreateForm() {
    return {
        priority: TaskPriorityEnum.NORMAL,
        stock_id: null,
        subordinate_stock_id: null,
        shipping_type: TaskShippingType.PERSONAL_COLLECTION,
        destination_stock_location_id: null,
        items: []
    };
}

function StockPickingSetCreateRender() {
    return {
        priority: new TaskPriority,
        stock_id: new StockId,
        subordinate_stock_id: {
            icon: '$substock',
            required: true,
            autocomplete: {
                items: []
            }
        },
        shipping_type: {
            icon: '$taskStockPickingShippingType',
            required: true,
            select: Object.values(TaskShippingType).map(type => ({
                text: i18n.t('tasks.stockPicking.type.' + type),
                value: type
            }))
        },
    };
}

export {StockPickingSetCreateForm, StockPickingSetCreateRender};
