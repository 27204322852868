import sha256 from "js-sha256";

const Crypto = {

    generateCodeVerifier() {
        const CHARSET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_.~';
        const length = 43;

        let result = '';
        if (window.crypto && window.crypto.getRandomValues) {
            const buffer = new Uint32Array(length);

            window.crypto.getRandomValues(buffer);
            for (let i = 0; i < length; i++) {
                result += CHARSET[buffer[i] % CHARSET.length];
            }
            return result;
        }

        for (let i = 0; i < length; i++) {
            result += CHARSET[Math.floor(Math.random() * CHARSET.length)];
        }
        return result;
    },

    createCodeChallenge(codeVerifier) {
        return btoa(String.fromCharCode(...new Uint8Array(sha256.arrayBuffer(codeVerifier))))
            .replace(/=/g, "")
            .replace(/\+/g, "-")
            .replace(/\//g, "_");
    }
};

export {Crypto};
