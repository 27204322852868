import isElectronPlatform from "is-electron";

const Env = {

    env: process.env.NODE_ENV,

    isProduction() {
        return this.env === 'production';
    },

    isTest() {
        return this.env === 'test';
    },

    isDevelopment() {
        return this.env === 'development';
    },

    isElectron() {
        return isElectronPlatform();
    },
};

export {Env};
