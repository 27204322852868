import {TaskPriority as TaskPriorityEnum} from "@/enum/task_priority";
import {StockId, TaskDescription, TaskPriority, TaskStrictMode} from "@/app/tasks/definitions/taskCommon.render.form";
import {EventBus} from "@/service/EventBus";
import {TaskItemsStrictMode} from "@/enum/task_items_strict_mode";

function MoveProductsCreateForm() {
    return {
        priority: TaskPriorityEnum.NORMAL,
        description: null,
        stock_id: null,
        subordinate_stock_id: null,
        source_location_id: -1,
        destination_location_id: -1,
        transfer_mode: TaskItemsStrictMode.FREE,
        attachments: [],
        items: []
    };
}

function MoveProductsCreateRender() {
    return {
        priority: new TaskPriority,
        stock_id: new StockId,
        subordinate_stock_id: {
            icon: '$substock',
            required: true,
            autocomplete: {
                items: []
            }
        },
        source_location_id: {
            icon: '$taskSource',
            required: true,
            autocomplete: {
                items: []
            }
        },
        destination_location_id: {
            icon: '$taskDestination',
            required: true,
            autocomplete: {
                items: []
            },
            createNew: {
                icon: '$addLocation',
                label: 'stocks.locations.create.label',
                action: () => {
                    EventBus.$emit('create-location');
                }
            }
        },
        transfer_mode: new TaskStrictMode,
        description: new TaskDescription
    };
}

export {MoveProductsCreateForm, MoveProductsCreateRender};
