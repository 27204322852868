<template>
  <div>
    <template v-if="details && ready">
      <v-layout wrap>
        <v-flex
          md4
          xs12
        >
          <v-card
            class="ma-1"
          >
            <v-container>
              <div>
                <span class="text-caption">{{ $t('tasks.state.name') + ': ' }}</span>
                {{ details.state | taskState }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.priority.name') + ': ' }}</span>
                {{ details.priority | taskPriority }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.assigned_user') + ': ' }}</span>
                {{ details.assigned_user | fullName }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.time_spent') + ': ' }}</span>
                {{ details.time_entries | timeEntriesSumHumanize }}
              </div>
              <div v-if="isSet">
                <v-divider
                  class="my-2"
                />
                <div>
                  <span class="text-caption">{{ $t('tasks.stockPickingSet.stockPickingTasks') + ': ' }}</span>
                  {{ details.tasks.length }}
                </div>
                <div v-if="details.used_strategy">
                  <span class="text-caption">{{ $t('tasks.stockPickingSet.usedStrategy') + ': ' }}</span>
                  {{ $t('tasks.stockPickingSet.strategies.' + details.used_strategy) }}
                </div>
              </div>
              <div v-if="details.parent_task_id">
                <v-divider
                  class="my-2"
                />
                <div>
                  <span class="text-caption">{{ $t('tasks.stockPicking.partOfSet') + ': ' }}</span>
                  <router-link :to="{ name: 'stockPickingSetShow', params: { taskId: details.parent_task_id }}">
                    {{ '#' + details.parent_task_id }}
                  </router-link>
                </div>
              </div>
              <v-divider
                class="my-2"
              />
              <div>
                <span class="text-caption">{{
                  $t('tasks.stockPicking.subordinate_stock_id') + ': '
                }}</span>
                {{ details.subordinate_stock.name }}
              </div>
              <div v-if="details.buyer">
                <span class="text-caption">{{ $t('tasks.stockPicking.buyer_id') + ': ' }}</span>
                {{ details.buyer ? details.buyer.name : '' }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.stockPicking.type.label') + ': ' }}</span>
                {{ $t('tasks.stockPicking.type.' + details.shipping_type) }}
              </div>
              <div v-if="details.target_location_id">
                <span class="text-caption">{{ $t('tasks.stockPicking.destination_location_id') + ': ' }}</span>
                {{ LocationCache[details.target_location_id] | locationLabel }}
              </div>
              <div v-show="details.description">
                <span class="text-caption">{{ $t('tasks.description') + ': ' }}</span>
                {{ details.description }}
              </div>
              <div v-if="details.external_order_id">
                <span class="text-caption">{{ $t('tasks.stockPicking.external_order_id') + ': ' }}</span>
                <router-link :to="taskLinks[taskTypes.EXTERNAL_ORDER] + '/' + details.external_order_id">
                  #{{ details.external_order_id }}
                </router-link>
              </div>
              <v-divider
                class="my-2"
              />
              <EntityHistory :changes="details" />
            </v-container>
          </v-card>
        </v-flex>
        <v-flex
          v-show="details.notes.length > 0"
          md4
          xs12
        >
          <Notes :notes="details.notes" />
        </v-flex>
        <v-flex
          md4
          xs12
        >
          <TaskAttachments
            :attachments="details.attachments"
          />
        </v-flex>
        <v-flex
          v-if="details.tasks"
          md4
          xs12
        >
          <TaskListList
            :tasks="details.tasks"
          />
          <v-btn
            v-if="isChief"
            type="submit"
            color="accent darken-2"
            class="mt-2"
            @click="deleteSet()"
          >
            <v-icon
              class="mr-2"
            >
              $deleteTableItem
            </v-icon>
            {{ $t('tasks.stockPickingSet.delete.title') }}
          </v-btn>
        </v-flex>
      </v-layout>
      <TaskItemsCard
        :api="api"
        :card-type="TaskItemsCardType.ASSIGNMENT"
        :is-single="false"
        :items="items"
        :task-info="taskInfo"
        task-lang-path="stockPicking."
      />
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>

<script>
    import {ReactiveLocationCacheMixin} from "@/app/mixins/ReactiveLocationCacheMixin";
    import Notes from "@/app/tasks/components/TaskNotes.component";
    import TaskAttachments from "@/app/tasks/components/TaskAttachments.component";
    import EntityHistory from "@/app/components/EntityHistory.component";
    import {taskTypes} from "@/enum/task_type";
    import TaskListList from "@/app/tasks/components/taskList/TaskListList.component";
    import TaskItemsCard from "@/app/tasks/components/taskItemsCard/TaskItemsCard.component";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";
    import {TaskFetchItemsMixin} from "@/app/mixins/TaskFetchItemsMixin";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {taskLinks} from "@/enum/task_type";

    export default {
        name: "StockPickingAssignment",
        components: {EntityHistory, TaskAttachments, Notes, TaskListList, TaskItemsCard},
        mixins: [TaskFetchItemsMixin, ReactiveLocationCacheMixin, ACLMixin],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            },
            api: {
                type: Object,
                require: true,
                default: () => ({}),
            }
        },
        data: () => ({
            ready: false,
            items: [],
            TaskItemsCardType: TaskItemsCardType,
            taskLinks: taskLinks,
            taskTypes: taskTypes
        }),
        computed: {
            API: function () {
                return this.api;
            },
            details: function () {
                return this.taskInfo.details;
            },
            isSet: function () {
                return this.details.type === taskTypes.STOCK_PICKING_SET;
            }
        },
        createdOrActivated: function (lifeCycleHook) {
            if (lifeCycleHook === this.LifeCycleHook.CREATED) {
                this.fetchItems({initial: true}).catch(this.snack).then(() => this.ready = true);
            } else {
                this.fetchItems().catch(this.snack);
            }
        },
        methods: {
            deleteSet: function () {
                this.api.delete(this.details.id)
                    .then(() => {
                        this.$router.push('/');
                    }).catch(this.snack);
            }
        }
    };
</script>

<style scoped>

</style>
